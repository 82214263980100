import { Contatti } from './../../modelli/shared/contatti';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { authServerUrlContatti } from 'src/app/modelli/app.contstant';

@Injectable({
  providedIn: 'root'
})
export class ContattiDataService {

  constructor(private http:HttpClient) { }

  inviaRichiestaContatto(contatto:Contatti){
    return this.http.post<ApiMsg>(authServerUrlContatti + "/invia/", contatto);
  }
}
