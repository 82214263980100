import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CrudUtentiIndirizziComponent } from 'src/app/component/crud-utenti-indirizzi/crud-utenti-indirizzi.component';
import { DialogComponent } from 'src/app/component/dialog/dialog.component';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { DialogClass } from 'src/app/modelli/shared/Classi/Componenti/dialogClass';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { UtentiIndirizzo } from 'src/app/modelli/shared/utentiIndirizzo';
import { AuthJWTService } from 'src/app/services/auth-jwt.service';
import { UtentiIndirizziDataService } from 'src/app/services/data/utenti-indirizzi-data.service';
import { FunzioniLayout } from 'src/app/modelli/shared/Funzioni/funzioniLayout';

@Component({
  selector: 'app-elenco-indirizzi-utente',
  templateUrl: './elenco-indirizzi-utente.component.html',
  styleUrls: ['./elenco-indirizzi-utente.component.css']
})
export class ElencoIndirizziUtenteComponent implements OnInit {
  @ViewChild('vc', {read: ViewContainerRef}) vcr: ViewContainerRef;

  indirizziFatturazione: UtentiIndirizzo[];
  indirizziSpedizione: UtentiIndirizzo[];

  constructor(public AuthJWT: AuthJWTService, private indirizziService: UtentiIndirizziDataService, private dialog: MatDialog, private snackBar: MatSnackBar,private cfr:ComponentFactoryResolver,private funzioniLayout: FunzioniLayout) { }

  ngOnInit(): void {
    this.getIndirizzi();
  }

  getIndirizzi() {
    let username: string = this.AuthJWT.loggedUser();
    this.indirizziService.cercaByUsername(username).subscribe(
      response => {
        let indirizzi: UtentiIndirizzo[] = response;
        this.indirizziFatturazione = indirizzi.filter(t => t.tipo == 'F')
        this.indirizziSpedizione = indirizzi.filter(t => t.tipo == 'S')
      }, error => {
      }, () => {
      }
    )
  }

  eliminaIndirizzo(indirizzo: UtentiIndirizzo) {

    let contenuto = "Si è sicuri di eliminare l'indirizzo: " + indirizzo.nominativo + " - " + indirizzo.indirizzo + " " + indirizzo.cap + " " + indirizzo.comune + "?"
    let dialogData: DialogClass = new DialogClass("Elimina indirizzo", contenuto, true, true, true)
    let dialogRef = this.dialog.open(DialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(
      response => {
        if (response == "Si") {
          this.indirizziService.eliminaIndirizzo(indirizzo.id).subscribe(
            response => {
              this.openSnackbar("indirizzo eliminato.");
              this.getIndirizzi();
            }
          )
        }
      }
    )
  }

  openSnackbar(titolo: string) {
    let snackBarData: SnackBarClass = new SnackBarClass(titolo, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }

  apriIndirizzo(tipo:string){
    let componentFactory =  this.cfr.resolveComponentFactory(CrudUtentiIndirizziComponent);
    this.vcr.clear();
    this.vcr.clear();
    let component = this.vcr.createComponent(componentFactory);
    component.instance.tipoIndirizzo=tipo;
    //Rimango in ascolto della variabile finito del figlio, quando questa diventa true distruggo il figlio
    component.instance.finito.subscribe(
      response=>{
        if(response==true){
          this.vcr.clear();
          this.getIndirizzi();
        }
      }
    )
  }

  gotoTop() {
    this.funzioniLayout.gotoTopPage();
  }

}
