import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-sito-carosello',
  templateUrl: './sito-carosello.component.html',
  styleUrls: ['./sito-carosello.component.css']
})
export class SitoCaroselloComponent implements OnInit {
  @Input ('oggetti') oggetti: string[];
  constructor() { }

  ngOnInit(): void {
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplay:true,
    autoplaySpeed:700,
    navText: ['', ''],
    //In base alla larghezza vengono decisi quanti riquadri visualizzare
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1

      }
    },
    nav: true
  }

}
