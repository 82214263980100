import { OrdiniTestataPagamento } from './../../modelli/shared/ordiniTestataPagamento';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagamentoDataService } from 'src/app/services/data/pagamento-data.service';
import { OrdiniTestata } from 'src/app/modelli/shared/ordiniTestata';

@Component({
  selector: 'app-conclusione-pagamento',
  templateUrl: './conclusione-pagamento.component.html',
  styleUrls: ['./conclusione-pagamento.component.css']
})
export class ConclusionePagamentoComponent implements OnInit {
  esito:number=0; //0=non valorizzato. 1=esito Ok, 2=esitoKO

  constructor(private route: ActivatedRoute, private pagamentoService: PagamentoDataService) {
    this.route.queryParams.subscribe(
      response => {

        //Se l'esito è ok allora salvo il record pagamento e concludo l'ordine, altrimenti visualizzo messaggio non a buon fine
        if (response['esito'] == 'OK') {
          let risultato: OrdiniTestataPagamento = new OrdiniTestataPagamento(0, 0, response['importo'], response['divisa'], response['codTrans'], response['brand'], response['mac'], response['esito'], response['data'], response['orario'], response['codiceEsito'], response['codAut'], response['pan'],
            response['scadenza_pan'], response['nazionalita'], response['languageId'], response['tipoTransazione'], response['messaggio'], response['regione'], response['descrizione'], response['tipoProdotto'], response['nome'], response['cognome'], response['mail'], response['hash'], response['infoc'], response['infob'],
            response['codiceConvenzione'], response['Note1'], response['Note2'], response['Note3'], response['TCONTAB'], null);

          //Valorizzo delle proprietà che potrebbe non tornare da Nexi e quindi essere nulle nell'oggetto risultato, le valorizzo in ''
          if (risultato.hash == null) {
            risultato.hash = '';
          }
          if (risultato.infob == null) {
            risultato.infob = '';
          }
          if (risultato.infoc == null) {
            risultato.infoc = '';
          }

          this.pagamentoService.EsitoPagamentoOrdine(risultato).subscribe(
            response => {
              this.esito=1
            }
          )
        }else{
          this.esito=2
        }
      }
    )
  }

  ngOnInit(): void {


  }

}
