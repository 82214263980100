import { FunzioniLayout } from './../../modelli/shared/Funzioni/funzioniLayout';
import { AppCarrelloService } from 'src/app/services/app/app-carrello.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { mittenteMail, nominativoMittenteMail } from './../../modelli/app.contstant';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { AuthJWTService } from './../../services/auth-jwt.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtentiDataService } from 'src/app/services/data/utenti-data.service';
import { EmailDataService } from 'src/app/services/data/email-data.service';
import { Mail } from 'src/app/modelli/shared/mail';
import { LoadingService } from 'src/app/services/app/loading.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  f:FormGroup;
  fRecuperaPassword:FormGroup;

  autenticato: boolean = true;
  errorMsg:string = "";
  confermaMsg: string = "";
  messaggeMailInviata:string="";    
  isConfermare: boolean = false;
  apiMsg: ApiMsg;
  isRecuperaPassword:boolean=false;
  hidePassword = true;

  constructor(private router: Router, private AuthJWT: AuthJWTService, private utentiService: UtentiDataService, private emailDataService: EmailDataService, private appCarrello:AppCarrelloService,
    private appLoading: LoadingService,private snackBar: MatSnackBar,private funzioniLayout:FunzioniLayout) { }

  ngOnInit(): void {
    this.f = new FormGroup({
      'username': new FormControl('',[Validators.required,Validators.email]),
      'password': new FormControl('',[Validators.required])
    })
    this.fRecuperaPassword=new FormGroup({
      'email':new FormControl('',[Validators.required,Validators.email])
    })
  }

  get username(){
    return this.f.get('username');
  }

  get password(){
    return this.f.get('password');
  }

  get email(){
    return this.fRecuperaPassword.get('email');
  }

  gestAuth() {
    //Imposto la username come tutta minuscola
    let tmpUsername:string=this.username.value;
    this.username.setValue(tmpUsername.toLowerCase());
    this.appLoading.avvia();
    this.resetMessaggi();
    this.AuthJWT.autenticaService(this.username.value, this.password.value)
      .subscribe(data => {
        this.autenticato = true;
        this.router.navigate(['/spesa-online'])   
        this.appCarrello.associaCarrelloUtente().subscribe(
          response=>{
            this.appLoading.stoppa();
          }
        )
      }, error => {
        this.autenticato = false;
        this.errorMsg = error.error.messaggio;
        //Se l'errore è 405 allora gli aggiungo al testo il link per rimandare la conferma
        if (error.status == 405) {
          this.isConfermare = true;
          this.utentiService.confirmcodeUtente(this.username.value).subscribe(
            response => {
              this.apiMsg = response;
              this.messaggeMailInviata = this.apiMsg.messaggio;
              this.appLoading.stoppa();                                    
            },
            error => {
              this.apiMsg = error.error;
              this.errorMsg = this.apiMsg.messaggio;
              this.appLoading.stoppa();
            }
          )
        } 
        this.appLoading.stoppa();
      })      
  }

  recuperoPassword(){
    this.appLoading.avvia();
    this.resetMessaggi();
    //Creo il codice di recupero password
    this.utentiService.creaCodiceRecuperoPassword(this.email.value).subscribe(
      response=>{
        let codiceReset:string=response;        
        
        let mail: Mail = new Mail(nominativoMittenteMail, mittenteMail, this.email.value, "Reset password", "Per eseguire il reset della password cliccare sul seguente <a href='" + location.origin + "/recuperaPassword/" + codiceReset + "'>link</a>", "Email di reset password inviata con successo.");
        
        this.emailDataService.InviaMail(mail).subscribe(
          response => {
            this.apiMsg = response;
            this.messaggeMailInviata = this.apiMsg.messaggio;
            this.appLoading.stoppa();
            let snackBarData: SnackBarClass = new SnackBarClass("Email di reset password inviata con successo.",false)
            let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent,{data:snackBarData,duration:2000});   
            this.isRecuperaPassword=false;   
          },
          error => {
            this.apiMsg = error.error;
            this.errorMsg = this.apiMsg.messaggio;
            this.appLoading.stoppa();
          }
        )
      }
    )
  }

  resetMessaggi(){
    this.errorMsg = "";
    this.messaggeMailInviata="";  
    this.confermaMsg = "";
  }
}
