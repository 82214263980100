<div class="container">
    <div class="col-lg-12 bg-light text-center mt-5 ">
        <mat-icon class="font-alto-icone bold mt-5">local_shipping</mat-icon>
        <p class="text-success font-alto pt-3 pb-5">
            FAQ SPEDIZIONI
        </p>
    </div>
    <div>
        <ul class="lead interlinea">
            <li>Costo spedizione: 9,90 €</li>
            <li>Spedizione gratuita per ordini da 60€ in su</li>
            <li>Tempistiche di consegna: 48 ore circa (escluso festivi)</li>
            <li>Riceverai una mail di tracking dal corriere a spedizione avviata</li>
        </ul>
        <p class="lead  ">
            Corriere:
        </p>
        <div class=" col-lg-5">
            <img class="fluid w-50"
                src="https://www.tnt.com/dam/tnt_express_media/tnt-local-pages/it_it/imgs/logotntfedexwhite3.jpg">
        </div>
    </div>