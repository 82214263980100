import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtentiDataService } from './../../services/data/utenti-data.service';
import { Component, OnInit } from '@angular/core';
import { Utenti } from 'src/app/modelli/shared/utenti';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { UtenteValidators } from 'src/app/modelli/shared/validators/utenti.validators';
import { recaptchaKey } from 'src/app/modelli/app.contstant';

@Component({
  selector: 'app-registrati',
  templateUrl: './registrati.component.html',
  styleUrls: ['./registrati.component.css']
})
export class RegistratiComponent implements OnInit {
  f:FormGroup;
  utente:Utenti;
  apiMsg:ApiMsg;
  Conferma:string;
  Errore:string;
  keyR:string=recaptchaKey;
  hidePassword1 = true;
  hidePassword2 = true;

  constructor(private utentiService: UtentiDataService,private utenteValidator:UtenteValidators) { }

  ngOnInit(): void {
    this.utente = new Utenti(0,"","","","","",true,"",false,"",false,false)
    this.f=new FormGroup({
      'username' : new FormControl('',[Validators.required,Validators.minLength(5),Validators.maxLength(200),Validators.email],this.utenteValidator.utentePresente()),      
      'password' : new FormControl('',[Validators.required,Validators.minLength(8),Validators.maxLength(100)]),
      'ripetiPassword': new FormControl(''),
      'nome': new FormControl('',[Validators.required]),
      'cognome': new FormControl('',[Validators.required]),
      'accettazionePrivacy':new FormControl(false,[Validators.requiredTrue]),
      'accettazioneNewsletter':new FormControl(false),
      'recaptcha': new FormControl(null, Validators.required)
    });
  }

  get username(){
    return this.f.get('username');
  }

  get password(){
    return this.f.get('password');
  }

  get ripetiPassword(){
    return this.f.get('ripetiPassword');
  }

  get nome(){
    return this.f.get('nome');
  }

  get cognome(){
    return this.f.get('cognome');
  }

  get accettazionePrivacy(){
    return this.f.get('accettazionePrivacy');
  }

  get accettazioneNewsletter(){
    return this.f.get('accettazioneNewsletter');
  }

  salva(Dati:FormGroup){
    this.Conferma="";
    this.Errore="";
    this.utente=Dati.value;
    //Imposto la mail come tutta minuscola se no può creare problemi successivamente
    this.utente.username=this.utente.username.toLowerCase();

    //Valorizzo le proprietà mancati di utente
    this.utente.email=this.utente.username;
    
    this.utentiService.registraUtente(this.utente).subscribe(
      response=>{
        this.apiMsg=response;
        this.Conferma=this.apiMsg.messaggio;
        this.inviaMailConferma();
      },
      error=>{
        this.apiMsg=error.error;
        this.Errore=this.apiMsg.messaggio;
      }

    )
  }  

  inviaMailConferma(){    
    this.utentiService.confirmcodeUtente(this.utente.username).subscribe(
      response=>{
        this.apiMsg=response;
        this.Conferma=this.apiMsg.messaggio;
      },
      error=>{
        this.apiMsg=error.error;
        this.Errore=this.apiMsg.messaggio;
      }
    )

    
  }

}
