import { MagazzinoMovimento } from 'src/app/modelli/shared/magazzinoMovimento';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { FiltroMagazzinoMovimento } from 'src/app/modelli/shared/Classi/Filtri/filtroMagazzinoMovimento';
import { PatchMethod } from 'src/app/modelli/shared/patchMethod';
import { ArticoliDataService } from 'src/app/services/data/articoli-data.service';
import { MagazzinoMovimentoDataService } from 'src/app/services/data/magazzino-movimento-data.service';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogClass } from 'src/app/modelli/shared/Classi/Componenti/dialogClass';
import { DialogComponent } from 'src/app/component/dialog/dialog.component';
import { LoadingService } from 'src/app/services/app/loading.service';

export class Causale {
  constructor(
    public codice: string,
    public titolo: string
  ) { }
}

@Component({
  selector: 'app-crud-movimento-magazzino',
  templateUrl: './crud-movimento-magazzino.component.html',
  styleUrls: ['./crud-movimento-magazzino.component.css']
})
export class CrudMovimentoMagazzinoComponent implements OnInit {

  @Input('idMovimento') id: number;
  @Input('codArticolo') codArticolo:string;
  @Output() finito = new EventEmitter<boolean>();

  f: FormGroup;
  movimento: MagazzinoMovimento;

  causali: Causale[];
  fCausali;

  articoli: Articoli[];
  fArticoli;

  apiMsg: ApiMsg;

  constructor(private movimentoCaricaService: MagazzinoMovimentoDataService, private movimentoCRUDService: MagazzinoMovimentoDataService, private articoliService: ArticoliDataService,
    private snackBar: MatSnackBar, private dialog: MatDialog,private appLoading:LoadingService) { }

  ngOnInit(): void {
    this.popolaDettaglio();
  }

  popolaDettaglio() {
    this.movimento = new MagazzinoMovimento(this.id, "", 0, null, null, "", "", "", 0, null);
    this.f = new FormGroup({
      'codArt': new FormControl('', [Validators.required]),
      'qta': new FormControl(0, [Validators.required, Validators.min(1)]),
      'causale': new FormControl('', [Validators.required]),
      'nota': new FormControl
    })
    this.PopolaCombo();
    if (this.id > 0) {
      this.f.controls['codArt'].disable();
      this.f.controls['causale'].disable();
      let tmpDal:Date=new Date('2010/01/01');
      let tmpAl:Date=new Date('2050/12/31');
      let filtro = new FiltroMagazzinoMovimento(this.id, "",tmpDal,tmpAl,"");

      this.movimentoCaricaService.listaFiltrata(filtro).subscribe(
        response => {
          this.movimentoCaricaService = null;
          this.movimento = response[0];
          this.f.controls['codArt'].setValue(this.movimento.codArt);
          this.f.controls['qta'].setValue(this.movimento.qta);
          this.f.controls['causale'].setValue(this.movimento.causale);
          this.f.controls['nota'].setValue(this.movimento.nota);

          if (this.movimento.causale == "V") { //Se il movimento arriva da una vendita dico di fare attenzione perchè poi i dati potrebbero non essere riallineati con l'ordine
            let contenuto = "ATTENZIONE: Modificando il quantitativo potrebbero esserci discrepanze con il dettaglio dell'ordine."
            let dialogData: DialogClass = new DialogClass("Visualizzazione dati", contenuto, true, true, false)
            let dialogRef = this.dialog.open(DialogComponent, { data: dialogData });
            dialogRef.afterClosed().subscribe(
              response => {
              }
            )
          }
        }
      )
    };
    if (this.codArticolo !=""){      
      this.f.controls['codArt'].setValue(this.codArticolo);
    }
  }

  get codArt() {
    return this.f.get('codArt');
  }

  get qta() {
    return this.f.get('qta');
  }

  get causale() {
    return this.f.get('causale');
  }

  get nota() {
    return this.f.get('nota')
  }

  PopolaCombo() {
    this.appLoading.avvia();
    //Popolo la tendina causali
    this.causali = [];
    this.causali.push(new Causale("C", "Carico"))
    this.causali.push(new Causale("S", "Scarico"))
    this.causali.push(new Causale("V", "Vendita"))
    this.fCausali = this.causali.slice();

    //Popolo la tendina articoli
    this.articoli = [];
    this.articoliService.elencoArticoliCompleto().subscribe(
      response => {
        this.articoli = response;
        this.fArticoli = this.articoli.slice();
        this.appLoading.stoppa();
      }
    )
  }

  salvaMovimento(dati: FormGroup) {

    if (this.movimento.id > 0) {
      //Creo l'array di campi che passo poi per la patch
      let paramQta = new PatchMethod(this.qta.value, '/Qta', 'replace');
      let paramNota = new PatchMethod(this.nota.value, '/Nota', 'replace');

      this.movimentoCRUDService.modifica(this.movimento.id, [paramQta, paramNota]).subscribe(
        response => {
          this.apiMsg = response;
          this.visualizzaSnackBar();
        }, error => {
          this.apiMsg = error;
        }, () => {
          this.annulla();
        }
      )

    } else {
      this.movimento = dati.value;
      this.movimentoCRUDService.inserisci(this.movimento).subscribe(
        response => {
          this.apiMsg = response;
          this.visualizzaSnackBar();
        }, error => {
          this.apiMsg = error;
        }, () => {
          this.annulla();
        }
      )
    }
  }

  visualizzaSnackBar() {
    let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }

  //Con questa funzione chiudo il componente
  annulla() {
    this.finito.emit(true);
  }

}