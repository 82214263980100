import { StatoOrdine } from './statoOrdine';
import { MetodoPagamento } from './metodoPagamento';
import { UtentiIndirizzo } from './utentiIndirizzo';
import { OrdiniDettaglio } from './ordiniDettaglio';
import { OrdiniTestataPagamento } from './ordiniTestataPagamento';

export class OrdiniTestata{
    constructor(
        public id:number,
        public userName:string,
        public timeStampIns:Date,
        public noteOrdine:string,
        public data:Date,
        public importoArticoli:number,
        public importoSpeseSpedizione:number,
        public importoTotale:number,
        public idSpedizione:number,
        public idFatturazione:number,
        public idMetodoPagamento:number,
        public idStatoOrdine:number,
        public Pagato:boolean,
        public ordineDettagli:OrdiniDettaglio[],
        public indirizzoSpedizione:UtentiIndirizzo,
        public indirizzoFatturazione:UtentiIndirizzo,
        public metodoPagamento:MetodoPagamento,
        public statoOrdine:StatoOrdine,
        public ordineTestataPagamento:OrdiniTestataPagamento
    ){}
}