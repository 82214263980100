import { Subscription } from 'rxjs';
import { Articoli } from './../../modelli/shared/articoli';
import { AppArrayArticoliService } from './../../services/app/app-array-articoli.service';
import { FiltroArticoli } from './../../modelli/shared/Classi/Filtri/filtroArticoli';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prova-array-articoli',
  templateUrl: './prova-array-articoli.component.html',
  styleUrls: ['./prova-array-articoli.component.css']
})
export class ProvaArrayArticoliComponent implements OnInit {
  articoli:Articoli[];
  subArrayArticoli:Subscription;


  constructor(private appArrayArticoli:AppArrayArticoliService) {
    this.subArrayArticoli = this.appArrayArticoli.rilevaArrayArticoli().subscribe(
      tmpArticoli=>{
        this.articoli=tmpArticoli;
      }
    )
   }

  ngOnInit(): void {
  }

  filtraArticoli(){
    let filtro:FiltroArticoli = new FiltroArticoli(30,0,null,0,0,0,"",false);
    this.appArrayArticoli.impostaArrayArticoli(filtro);
  }

}
