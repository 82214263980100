import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { OrdiniDettaglio } from './../../modelli/shared/ordiniDettaglio';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backServerUrlOrdineDettaglio } from 'src/app/modelli/app.contstant';
import { PatchMethod } from 'src/app/modelli/shared/patchMethod';

@Injectable({
  providedIn: 'root'
})
export class OrdinidettaglioDataService {

  constructor(private http:HttpClient) { }

  cercaByID(id:number){
    return this.http.get<OrdiniDettaglio>(backServerUrlOrdineDettaglio + "/cerca/id/" + id)
  }

  inserisci(dettaglio:OrdiniDettaglio){
    return this.http.post<ApiMsg>(backServerUrlOrdineDettaglio + "/inserisci",dettaglio);
  }

  modifica(id:number,campi:PatchMethod[]){
    return this.http.patch<ApiMsg>(backServerUrlOrdineDettaglio + "/modifica/" + id,campi);
  }

  elimina(id:number){
    return this.http.delete<ApiMsg>(backServerUrlOrdineDettaglio + "/elimina/" + id);
  }

}
