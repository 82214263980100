import { ApiMsgArray } from './../../modelli/shared/apiMsgArray';


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtentiDataService } from 'src/app/services/data/utenti-data.service';
import { Utenti } from 'src/app/modelli/shared/utenti';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';

@Component({
  selector: 'app-recupera-password',
  templateUrl: './recupera-password.component.html',
  styleUrls: ['./recupera-password.component.css']
})
export class RecuperaPasswordComponent implements OnInit {
  codiceReset:string="";
  constructor(private route:ActivatedRoute,private utentiDataService:UtentiDataService,private router: Router,private snackBar: MatSnackBar) { }
  utente:Utenti;
  apiMsg:ApiMsg;
  apiMsgArray:ApiMsgArray;

  ngOnInit(): void {
    this.codiceReset=this.route.snapshot.params['codiceReset'];
    let split =this.codiceReset.split("+");    
    this.utente = new Utenti(0,"","","","","",true,"",false,"",false,false)        
  }  

  salva(){
    this.apiMsgArray = new ApiMsgArray([this.codiceReset,this.utente.password])            
    this.utentiDataService.confirmResetPassword(this.apiMsgArray).subscribe(
      response=>{
        this.apiMsg=response          
        let snackBarData: SnackBarClass = new SnackBarClass("Password modificata correttamente",false)
        let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent,{data:snackBarData,duration:2000}); 
        this.router.navigate(['/login'])     
      },error=>{
        this.apiMsg=error.error;
        let snackBarData: SnackBarClass = new SnackBarClass("Errore nella modifica della password, " + this.apiMsg.messaggio,true)
        let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent,{data:snackBarData}); 
      }
    )
  }

}
