export class EmailNewsletter {
    constructor(
        public id:number,
        public nome:string,
        public cognome:string,
        public email:string,
        public privacyAccettata:boolean,
        public removeCode:string
    ) { }
  }
