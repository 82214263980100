import { UrlSito, UrlImmaginiRepository } from './../../modelli/app.contstant';
import { FunzioniLayout } from './../../modelli/shared/Funzioni/funzioniLayout';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { ArticoliDataService } from './../../services/data/articoli-data.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FiltroArticoli } from 'src/app/modelli/shared/Classi/Filtri/filtroArticoli';
import { CarrelliDettaglio } from 'src/app/modelli/shared/carrelliDettaglio';
import { FunzioniLocalStorage } from 'src/app/modelli/shared/Funzioni/funzioniLocalStorage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppCarrelloService } from 'src/app/services/app/app-carrello.service';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { SEOService } from 'src/app/services/app/seo.service';

@Component({
  selector: 'app-articolo-spesa',
  templateUrl: './articolo-spesa.component.html',
  styleUrls: ['./articolo-spesa.component.css']
})
export class ArticoloSpesaComponent implements OnInit {

  tmpDicituraUrl: string;
  articolo: Articoli;
  articoli:Articoli[];

  quantita: number=1;

  constructor(private route: ActivatedRoute, private articoliService: ArticoliDataService,private FunzioniLS:FunzioniLocalStorage,
    private snackBar: MatSnackBar,private appCarrello:AppCarrelloService,private funzioniLayout:FunzioniLayout,private seoService:SEOService) {
    //Qua creo un osservabile che osserva i param dell'url, se questo cambia lancia la funzione all'interno
    this.route.paramMap.subscribe(params => {
      funzioniLayout.gotoTopPage();
      this.popolaArticoli();
    });
  }

  ngOnInit(): void {    
  }

  popolaArticoli(){

    this.articolo = new Articoli("", "", "", 0, "", 0, 0,0, "",false,null,null,[],null,null,null,null,null,false);
    this.tmpDicituraUrl = this.route.snapshot.params['articolo'];
    this.articoliService.cercaArticoloByDicituraUrl(this.tmpDicituraUrl).subscribe(
      response => {
        this.articolo = response;               

        //Valorizzo i meta tag della pagina        
        this.seoService.updateTitle(this.articolo.titolo);
        this.seoService.updateDescription(this.articolo.descrizione);
        this.seoService.updateOgUrl(UrlSito + this.route.snapshot['_routerState'].url);
        this.seoService.updateOgImage(UrlImmaginiRepository + "/" + this.articolo.foto[0].nomeFile);        
        this.seoService.updateOgTitle(this.articolo.titolo);
        this.seoService.updateOgDescription(this.articolo.descrizione)
        
        
        let filtro:FiltroArticoli=new FiltroArticoli(this.articolo.idCategoria,0,null,0,0,0,"",false);
        
        //Mando gli articoli da visualizzare nel carosello
        this.articoliService.cercaArticoliConFiltri(filtro).subscribe(
          response=>{
            this.articoli=response;
            
            
          }
        )
      }
    )        
  }

  aggiungiAlCarrello(articolo: Articoli) {    
    let dettaglio:CarrelliDettaglio = new CarrelliDettaglio(0,this.FunzioniLS.getIdCart(),articolo.codArt,this.quantita,null);
    this.appCarrello.aggiungiRigaCarrello(dettaglio);        
    this.openSnackbar(articolo.titolo,this.quantita);
  }

  openSnackbar(titoloArticolo:string,qta:number){    
    let snackBarData: SnackBarClass = new SnackBarClass(titoloArticolo + " per " + qta + " aggiunto al carrello!",false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent,{data:snackBarData,duration:2000});
  }

  aggiungi1() {
    this.quantita += 1;
  }

  rimuovi1() {
    if (this.quantita > 1) {
      this.quantita -= 1;
    }
  }

}
