import { MagazzinoDisponibilitaDataService } from 'src/app/services/data/magazzino-disponibilita-data.service';
import { SnackBarClass } from './../../modelli/shared/Classi/Componenti/snackbarClass';
import { UArticoliCaratteristiche } from './../../modelli/shared/UArticoliCaratteristiche';
import { ArrayListIdDescr } from './../../modelli/shared/Classi/arrayListIdDescr';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ArticoliCategorieDataService } from './../../services/data/articoli-categorie-data.service';
import { FornitoriDataService } from './../../services/data/fornitori-data.service';
import { Fornitori } from './../../modelli/shared/fornitori';
import { ArticoliDataService } from './../../services/data/articoli-data.service';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { ArticoliCaratteristiche } from 'src/app/modelli/shared/articoliCaratteristiche';
import { ArticoliCaratteristicheDataService } from 'src/app/services/data/articoli-caratteristiche-data.service';
import { UarticoliCaratteristicheDataService } from 'src/app/services/data/uarticoli-caratteristiche-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { LoadingService } from 'src/app/services/app/loading.service';
import { CrudFornitoreComponent } from 'src/app/componentAdmin/crud-fornitore/crud-fornitore.component';


@Component({
  selector: 'app-articolo',
  templateUrl: './articolo.component.html',
  styleUrls: ['./articolo.component.css']
})
export class ArticoloComponent implements OnInit {
  @ViewChild('vc', {read: ViewContainerRef}) vcr: ViewContainerRef;

  f: FormGroup;
  tmpcodArt: string = "";
  articolo: Articoli;

  apiMsg: ApiMsg;
  IsModifica: boolean = false;

  fornitori: Fornitori[];
  produttori:Fornitori[];
  categorie: ArrayListIdDescr[];
  caratteristiche: ArticoliCaratteristiche[];

  fCategorie;
  fFornitori;
  fProduttori;
  fcaratteristiche;

  toppings: string[];
  
  constructor(private route: ActivatedRoute, private articoliService: ArticoliDataService, private fornitoriService: FornitoriDataService, private categorieService: ArticoliCategorieDataService,
    private router: Router, private caratteristicheService: ArticoliCaratteristicheDataService, private ucaratteristicheService: UarticoliCaratteristicheDataService, private snackBar: MatSnackBar,
    private appLoading: LoadingService,private cfr:ComponentFactoryResolver,private magazzinoDisponibilitaSevice:MagazzinoDisponibilitaDataService) {
    this.route.paramMap.subscribe(params => {
      this.popolaArticolo();
    });
  }

  ngOnInit(): void {

  }

  popolaArticolo() {
    this.tmpcodArt = this.route.snapshot.params['codart'];
    this.articolo = new Articoli("", "", "", 0, "", 0, 0,0, "",false,null,null,null,null,null,null,null,null,false);
    this.f = new FormGroup({
      'codArt': new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
      'titolo': new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(200)]),
      'descrizione': new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(1000)]),
      'prezzo': new FormControl(0, [Validators.required,Validators.min(0.001)]),
      'ingredienti': new FormControl(''),
      'idFornitore': new FormControl(0,[Validators.min(1)]),
      'idProduttore': new FormControl(0,[Validators.min(1)]),
      'idCategoria': new FormControl(0,[Validators.min(1)]),
      'ddlCaratteristiche': new FormControl(''),
      'noVisualizzaOnline': new FormControl(false),
      'soloRitiro': new FormControl(false),
    })
    this.popolaCombo();
    if (this.tmpcodArt != "-1") {
      this.IsModifica = true;
      this.f.controls['codArt'].disable();
      this.articoliService.cercaArticoloByCodart(this.tmpcodArt).subscribe(
        response => {
          this.articolo = response;
          this.f.controls['codArt'].setValue(this.articolo.codArt);
          this.f.controls['titolo'].setValue(this.articolo.titolo);
          this.f.controls['descrizione'].setValue(this.articolo.descrizione);
          this.f.controls['prezzo'].setValue(this.articolo.prezzo);
          this.f.controls['ingredienti'].setValue(this.articolo.ingredienti);
          this.f.controls['idFornitore'].setValue(this.articolo.idFornitore);
          this.f.controls['idProduttore'].setValue(this.articolo.idProduttore);
          this.f.controls['idCategoria'].setValue(this.articolo.idCategoria);
          this.f.controls['noVisualizzaOnline'].setValue(this.articolo.noVisualizzaOnline);
          this.f.controls['soloRitiro'].setValue(this.articolo.soloRitiro);
          this.popolaCaratteristiche();
        },
        error => {
          this.chiudi();
        }
      )
    } else {
      this.IsModifica = false;
      this.articolo.codArt = "";
      this.articolo.prezzo = 0;
      this.popolaCaratteristiche();
    }
        
  }

  get codArt() {
    return this.f.get('codArt');
  }
  get titolo() {
    return this.f.get('titolo');
  }
  get descrizione() {
    return this.f.get('descrizione');
  }
  get prezzo() {
    return this.f.get('prezzo');
  }
  get ingredienti() {
    return this.f.get('ingredienti');
  }
  get idFornitore() {
    return this.f.get('idFornitore');
  }
  get idProduttore() {
    return this.f.get('idProduttore');
  }
  get idCategoria() {
    return this.f.get('idCategoria');
  }

  get ddlCaratteristiche() {
    return this.f.get('ddlCaratteristiche');
  }

  get noVisualizzaOnline(){
    return this.f.get('noVisualizzaOnline')
  }

  get soloRitiro(){
    return this.f.get('noVisualizzaOnline')
  }

  popolaCaratteristiche() {
    this.caratteristicheService.elencoCaratteristiche().subscribe(
      response => {
        this.caratteristiche = [];
        this.caratteristiche = response;
        this.fcaratteristiche = null;
        this.fcaratteristiche = this.caratteristiche.slice();
        if (this.IsModifica) {
          //Agiungo le spunte alla select chiamando l'api
          this.toppings = [];
          this.ucaratteristicheService.cercaUCaratteristicheByCodArt(this.articolo.codArt).subscribe(
            response => {
              let artCaratteristiche: UArticoliCaratteristiche[];
              artCaratteristiche = response;

              for (let car of artCaratteristiche) {
                let tmpcar = this.fcaratteristiche.find(x => x.id == car.idCaratteristica)
                this.toppings.push(tmpcar.titolo)
              }
              this.f.controls['ddlCaratteristiche'].setValue(this.toppings);
            }
          )
        } else {
        }
      }, error => {
        this.chiudi();
      }
    )
  }

  popolaCombo() {
    this.fornitoriService.elencoFornitoriCompleto().subscribe(
      response => {
        this.fornitori = response;
        this.fFornitori = this.fornitori.slice();
        this.produttori=response;
        this.fProduttori=this.produttori.slice();
      }
    );
    this.categorieService.elencoUltime().subscribe(
      response => {
        this.categorie = response;
        this.fCategorie = this.categorie.slice();
      }
    )
  }

  salva(dati: FormGroup) {
    this.articolo = dati.value;
    if (this.IsModifica) { //Modifico
      this.articolo.codArt = this.tmpcodArt;
      this.articoliService.modificaArticolo(this.articolo).subscribe(
        response => {
          this.apiMsg = response;
          // this.confermaMsg = this.apiMsg.messaggio;
          this.visualizzaSnackBar();
          this.rivalorizzaArticolo(this.articolo.codArt)
        },
        error => {
          this.apiMsg = error.error;
          this.visualizzaSnackBar();
        }
      )
    }
    else { //Inserisco
      this.articoliService.inserisciArticolo(this.articolo).subscribe(
        response => {
          this.apiMsg = response;
          this.visualizzaSnackBar();
          //Inserisco anche i record delle caratteristiche
          let prova = this.f.get('ddlCaratteristiche').value
          for (let car of prova) {
            let idCaratteristica = this.caratteristiche.find(x => x.titolo == car);
            let newCar: UArticoliCaratteristiche = new UArticoliCaratteristiche(0, this.articolo.codArt, idCaratteristica.id, null, null)
            this.ucaratteristicheService.inserisciUCaratteristica(newCar).subscribe(
              response => {

              }
            )
          }
          //Inserisco la disponibilità a 0
          this.magazzinoDisponibilitaSevice.riallineaDisponibilitaCodArt(this.articolo.codArt).subscribe(
            response=>{
              
            }
          )
          this.rivalorizzaArticolo(this.articolo.codArt)
        },
        error => {
          this.apiMsg = error.error;
          this.visualizzaSnackBar();
        }
      )
    }
  }

  visualizzaSnackBar(){
    let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }

  clickSel(valore: boolean, oggetto: any) {
    
    if (this.IsModifica) { //Se sono in modifica
      this.appLoading.avvia();
      this.toppings = this.f.controls['ddlCaratteristiche'].value as string[];
      //Recupero l'id della UACaratteristica          
      let idCaratteristica = this.caratteristiche.find(x => x.titolo == oggetto.titolo);
      if (!valore) {       //Rimuovo la caratteristica      
        let idUCaratteristica = this.articolo.caratteristiche.find(u => u.idCaratteristica == idCaratteristica.id)
        
        this.ucaratteristicheService.eliminaCaratteristica(idUCaratteristica.id).subscribe(
          response => {
            this.rivalorizzaArticolo(this.articolo.codArt);                        
          }, error => {
            this.appLoading.stoppa();
          }, () => {
            this.appLoading.stoppa();
          }
        )
      } else { //Inserisco la caratteristica
        let newUCar: UArticoliCaratteristiche = new UArticoliCaratteristiche(0, this.articolo.codArt, idCaratteristica.id, null, null)
        this.ucaratteristicheService.inserisciUCaratteristica(newUCar).subscribe(
          response => {
            this.rivalorizzaArticolo(this.articolo.codArt);
            this.appLoading.stoppa();
          }
        )
      }
    } else {
    }
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  rivalorizzaArticolo(codArt: string) {
    this.router.navigate(['amm/articolo', this.articolo.codArt])    
  }

  chiudi() {
    this.router.navigate(['amm/articoli']);
  }

  duplicaArticolo(){
    this.IsModifica=false;
    this.f.controls['codArt'].enable();
  }  

  apriFornitore(){
    // this.router.navigate(['amm/fornitore',id]);
    let componentFactory =  this.cfr.resolveComponentFactory(CrudFornitoreComponent);
    this.vcr.clear();
    this.vcr.clear();
    let component = this.vcr.createComponent(componentFactory);
    component.instance.id=0;
    //Rimango in ascolto della variabile finito del figlio, quando questa diventa true distruggo il figlio
    component.instance.finito.subscribe(
      response=>{
        if(response==true){
          this.vcr.clear();
          this.popolaCombo();
        }
      }
    )
  }

}
