<section class="container">
    <h1 class="px-3 pb-2 pt-3 mt-3">Storico ordini</h1>
    <div class="row">
        <div *ngFor="let ordine of ordini" style="margin-top: 20px;padding: 10px;" class="border border  col-lg-12 m-2">
            <div class="m-3">
                <h2>
                    ORDINE N. {{ ordine.id }} <br>
                    <span class="sottile">Stato ordine:</span> {{ ordine.statoOrdine.descrizione }}<br>
                    <mat-icon class="mt-2 mr-2 mb-3">calendar_today</mat-icon> <span class="sottile mt-2 mb-3">{{
                        ordine.timeStampIns | date:"dd/MM/yyyy" }} {{ ordine.noteOrdine }}</span> <br>
                    Totale
                    <span class="sottile"> {{ ordine.importoTotale | currency: 'EUR':'symbol' }}</span>
                </h2>
                <hr>
                <div class="row">
                    <div *ngFor="let dettaglio of ordine.ordineDettagli" class="col-lg-3 m-2">
                        {{ dettaglio.descrizioneArticolo }}
                    </div>
                </div>
                <button *ngIf="ordine.pagato == false && ordine.idStatoOrdine<5" type="button" class="btn btn-success m-3 float-left"
                    (click)="pagaOrdine(ordine.id)">Paga</button>

                <button *ngIf="ordine.pagato" type="button"
                    class="btn btn-light text-white btn-sm viola float-right m-3 ">PAGATO</button>
            </div>
        </div>
    </div>
</section>