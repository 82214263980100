import { ArticoliCaratteristiche } from 'src/app/modelli/shared/articoliCaratteristiche';
export class FiltroArticoli{
    constructor(
        public idCategoria:number,
        public idCategoriaArticoliFigli:number,
        public caratteristiche:ArticoliCaratteristiche[],
        public idProduttore:number,
        public prezzoMin:number,
        public prezzoMax:number,
        public keySearch:string,
        public soloDisponibili:boolean,
        public paginaVisualizzazione:number=1
    ){}
}

//la proprietà ordinamento è usata solo in frontend e non viene gestita nel beckend