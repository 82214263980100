import { OrdiniTestata } from 'src/app/modelli/shared/ordiniTestata';
export class UtentiIndirizzo{
    constructor(
        public id:number,
        public userName:string,
        public indirizzo:string,
        public cap:string,
        public comune:string,
        public provincia:string,
        public stato:string,
        public tipo:string,
        public eliminato:boolean,
        public telefono:string,
        public fatturaPIVA:string,
        public fatturaCodFisc:string,
        public fatturaCodiceDestinatario:string,
        public fatturaPec:string,
        public defaultSelezionato:boolean,
        public nominativo:string,
        public ulterioriInformazioni:string,
        public ordiniTestataSpedizione:OrdiniTestata[],
        public ordiniTestataFatturazione:OrdiniTestata[]
    ){}
}