import { backServerUrlArticoliFoto } from './../../modelli/app.contstant';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { ArticoliFoto } from './../../modelli/shared/articoliFoto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArticoliFotoDataService {

  constructor(private http:HttpClient) { }

  cercaByCodart(codart:string){
    return this.http.get<ArticoliFoto[]>(backServerUrlArticoliFoto + "/cerca/codart/" + codart)
  }

  inserisciFoto(articoloFoto: ArticoliFoto){
    //Faccio questo per trasformare la classe ArticoliFoto in un formData che la mia api accetta, questo perchè all'interno ho un file
    const formData = new FormData();
    for (const prop in articoloFoto) {
      if (!articoloFoto.hasOwnProperty(prop)) { continue; }
      formData.append(prop , articoloFoto[prop]);
    }

    return this.http.post<ApiMsg>(backServerUrlArticoliFoto + "/inserisci",formData);
  }  

  eliminaFoto(id:number){
    return this.http.delete<ApiMsg>(backServerUrlArticoliFoto + "/elimina/" + id);
  }

}
