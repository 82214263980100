import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { UtentiDataService } from 'src/app/services/data/utenti-data.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-conferma-mail',
  templateUrl: './conferma-mail.component.html',
  styleUrls: ['./conferma-mail.component.css']
})
export class ConfermaMailComponent implements OnInit {
  codiceConferma:string;
  apiMsg:ApiMsg
  msgConferma:string="";

  constructor(private route:ActivatedRoute,private utentiDataService:UtentiDataService) { }

  ngOnInit(): void {
    this.codiceConferma = this.route.snapshot.params['codiceConferma'];
    this.utentiDataService.confirmAccount(this.codiceConferma).subscribe(
      response =>{
        this.apiMsg=response;
        this.msgConferma=this.apiMsg.messaggio;
      }
    )

  }

}
