import { MatSnackBar } from '@angular/material/snack-bar';
import { FornitoriDataService } from './../../services/data/fornitori-data.service';
import { ApiMsg } from './../../modelli/shared/apiMsg';
import { Fornitori } from './../../modelli/shared/fornitori';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';

@Component({
  selector: 'app-crud-fornitore',
  templateUrl: './crud-fornitore.component.html',
  styleUrls: ['./crud-fornitore.component.css']
})
export class CrudFornitoreComponent implements OnInit {

  @Input('idFornitore') id: number;
  @Output() finito = new EventEmitter<boolean>();

  f: FormGroup;
  fornitore:Fornitori;

  apiMsg:ApiMsg;

  constructor(private fornitoriService:FornitoriDataService, private snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.popolaDati();
  }

  popolaDati(){
    this.fornitore=new Fornitori(0,"");
    this.f = new FormGroup({
      'ragioneSociale': new FormControl('',[Validators.required,Validators.maxLength(200)])
    })
    if (this.id>0){
      this.fornitoriService.cercaFornitoreByID(this.id).subscribe(
        response=>{
          this.fornitore=response;
          this.f.controls['ragioneSociale'].setValue(this.fornitore.ragioneSociale);
        },
        error=>{
          this.apiMsg=error.error;
          this.visualizzaSnackBar();
        }
      )
    }else{

    }
  }

  get ragioneSociale(){
    return this.f.get('ragioneSociale');
  }

  salvaFornitore(dati:FormGroup){
    this.fornitore=dati.value;
    if (this.id>0){
      this.fornitore.id=this.id;
      this.fornitoriService.modificaFornitore(this.fornitore).subscribe(
        response=>{
          this.apiMsg=response;
          this.visualizzaSnackBar();
        },error=>{
          this.apiMsg=error;
        },()=>{
          this.annulla();
        }
      )
    }else{
      this.fornitoriService.inserisciFornitore(this.fornitore).subscribe(
        response => {
          this.apiMsg = response;
          this.visualizzaSnackBar();
        }, error => {
          this.apiMsg = error;
        }, () => {
          this.annulla();
        }
      )
    }
  }

  visualizzaSnackBar(){
    let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }  

  //Con questa funzione chiudo il componente
  annulla() {
    this.finito.emit(true);
  }

}
