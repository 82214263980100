<section class="container">
    <p>
        <ng-template #vc></ng-template>
    </p>
    <div class="row">
        <div class="col-lg-12">
            <h1>Indirizzi di spedizione<button mat-raised-button type="button" class="btn btn-success btn-sm mx-3" (click)="apriIndirizzo('S')" (click)="gotoTop()">Nuovo</button></h1>
            <div class="row">
                <div class="col-lg-4 border border-success rounded mx-3 p-3 text-uppercase" *ngFor="let spedizione of indirizziSpedizione" style="padding: 10px;" class="border border-success rounded">
                    <b>{{ spedizione.nominativo }}</b><br>
                    {{ spedizione.indirizzo }} - {{ spedizione.cap }} {{ spedizione.comune }} ({{
                    spedizione.provincia }}) {{ spedizione.stato }}
                    <br><br>
                    <b>Telefono:</b> {{ spedizione.telefono }}
                    <br>                    
                    <button mat-raised-button type="button" class="btn btn-danger pull-right btn-sm m-2" (click)="eliminaIndirizzo(spedizione)">Elimina</button>
                </div>
            </div>
        </div>
        <div class="col-lg-12" style="margin-top: 30px;">
            <h1>Indirizzi di fatturazione <button mat-raised-button type="button" class="btn btn-success mx-3 btn-sm" (click)="apriIndirizzo('F')" (click)="gotoTop()">Nuovo</button></h1>
            <div class="row">
                <div class="col-lg-4 border border-success rounded m-3 p-3 text-uppercase" *ngFor="let fatturazione of indirizziFatturazione" style="padding: 10px;" class="border border-success rounded">
                    <b>{{ fatturazione.nominativo }}</b><br>
                    {{ fatturazione.indirizzo }} - {{ fatturazione.cap }} {{ fatturazione.comune }} ({{
                    fatturazione.provincia }}) {{ fatturazione.stato }}
                    <br><br>
                    <b>Telefono:</b> {{ fatturazione.telefono }}
                    <br><br>
                    <b>Partita Iva:</b> {{ fatturazione.fatturaPIVA }} <br><b>CF:</b> {{ fatturazione.fatturaCodFisc }}
                    <br><br>
                    <b>Pec:</b> {{ fatturazione.fatturaPec }} <br> <b>Codice Sdi:</b> {{ fatturazione.fatturaCodiceDestinatario
                    }}
                    <br>
                    <button mat-raised-button type="button" class="btn btn-danger pull-right btn-sm m-2" (click)="eliminaIndirizzo(fatturazione)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
    
</section>