import { PatchMethod } from 'src/app/modelli/shared/patchMethod';
import { Utenti } from 'src/app/modelli/shared/utenti';
import { Component, OnInit } from '@angular/core';
import { AuthJWTService } from 'src/app/services/auth-jwt.service';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { PagamentoDataService } from 'src/app/services/data/pagamento-data.service';
import { FormControl, FormGroup } from '@angular/forms';
import { UtentiDataService } from 'src/app/services/data/utenti-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-area-utente',
  templateUrl: './area-utente.component.html',
  styleUrls: ['./area-utente.component.css']
})
export class AreaUtenteComponent implements OnInit {
  
  username: string;
  apiMsg: ApiMsg;
  fDati:FormGroup;
  utente:Utenti
  confermaSalvataggio:string;
  erroreSalvataggio:string;

  constructor(public AuthJWT: AuthJWTService,private utentiService:UtentiDataService,private snackBar: MatSnackBar, private route: Router) { }

  ngOnInit(): void {
    this.getDati();
  }

  getDati(){
    this.username = this.AuthJWT.loggedUser();
    this.utente= new Utenti(0,this.username,'','','','',false,'',false,'',false,false);
    this.utentiService.cercaUtente(this.username).subscribe(
      response=>{
        this.utente=response;        
        this.fDati = new FormGroup({
          'accettazioneNewsletter':new FormControl(this.utente.accettazioneNewsletter)
        })
      }
    )        

    
  }

  get accettazioneNewsletter(){
    return this.fDati.get('accettazioneNewsletter');
  }

  salvaDati(dati:FormGroup){
    this.confermaSalvataggio="";
    this.erroreSalvataggio="";
    this.utente=dati.value;
    let paramAccettazioneNewsletter = new PatchMethod(this.accettazioneNewsletter.value,'/AccettazioneNewsletter','replace')
    this.utentiService.modificaUtente(this.username,[paramAccettazioneNewsletter]).subscribe(
      response=>{
        this.apiMsg=response;
        this.confermaSalvataggio=this.apiMsg.messaggio;
        this.visualizzaSnackBar(this.confermaSalvataggio);
      },error=>{
        this.apiMsg=error.console.error;
        this.erroreSalvataggio=this.apiMsg.messaggio;
        this.visualizzaSnackBar(this.erroreSalvataggio);
      }
    )
  }

  

  visualizzaSnackBar(messaggio:string){
    let snackBarData: SnackBarClass = new SnackBarClass(messaggio, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }

  vaiElencoOrdini(){
    this.route.navigate(['utente/ordini']);
  }

  vaiUtenteIndirizzi(){
    this.route.navigate(['utente/indirizzi']);
  }

}
