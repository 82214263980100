<div class="">
    <div class="row">
        <div class="col-lg-12">
            <form *ngIf="fFiltro" [formGroup]="fFiltro" (ngSubmit)="filtra()">
                <mat-form-field appearance="fill" style="width: 300px;">
                    <mat-label>Inserisci un range di date</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" #dateRangeStart formControlName="dataDal">
                        <input matEndDate placeholder="End date" #dateRangeEnd formControlName="dataAl">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 300px;">
                    <mat-label>Stato ordine</mat-label>
                    <mat-select formControlName="idStatoOrdine">
                        <mat-option value=0>Tutti</mat-option>
                        <mat-option [value]="stato.id" *ngFor="let stato of statiOrdine">
                            {{stato.descrizione}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 300px;">
                    <mat-label>Stato pagati</mat-label>
                    <mat-select formControlName="statoPagati">
                        <mat-option value=0>Tutti</mat-option>
                        <mat-option value=1>Pagati</mat-option>
                        <mat-option value=-1>Non pagati</mat-option>
                    </mat-select>
                </mat-form-field>
                <button class="btn-success" type="submit">Filtra</button>
            </form>
        </div>
        <div class="col-lg-12">
            <mat-form-field>
                <input (keyup)="applicaFiltro($event.target.value)" matInput placeholder="Filtro">
            </mat-form-field>
            <div class="mat-elevation-z20">
                <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                    <ng-container matColumnDef="numero">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> NUMERO </mat-header-cell>
                        <mat-cell *matCellDef="let row" [class.example-expanded-row]="expandedRow === row"
                            (click)="expandedRow = expandedRow === row ? null : row">
                            {{row.id}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="data">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> DATA </mat-header-cell>
                        <mat-cell *matCellDef="let row" [class.example-expanded-row]="expandedRow === row"
                            (click)="expandedRow = expandedRow === row ? null : row">
                            {{row.data | date: 'dd/MM/yyyy'}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="statoOrdine.descrizione">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> STATO </mat-header-cell>
                        <mat-cell *matCellDef="let row" [class.example-expanded-row]="expandedRow === row"
                            (click)="expandedRow = expandedRow === row ? null : row">
                            {{ row.statoOrdine.descrizione }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="importo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> IMPORTO TOTALE </mat-header-cell>
                        <mat-cell *matCellDef="let row" [class.example-expanded-row]="expandedRow === row"
                            (click)="expandedRow = expandedRow === row ? null : row">
                            {{row.importoTotale | currency: 'EUR':'symbol'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="username">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> USERNAME </mat-header-cell>
                        <mat-cell *matCellDef="let row" [class.example-expanded-row]="expandedRow === row"
                            (click)="expandedRow = expandedRow === row ? null : row">
                            {{row.userName}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="metodoPagamento.descrizione">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> PAGAMENTO </mat-header-cell>
                        <mat-cell *matCellDef="let row" [class.example-expanded-row]="expandedRow === row"
                            (click)="expandedRow = expandedRow === row ? null : row">
                            {{row.idMetodoPagamento>0 ? row.metodoPagamento.descrizione : '' }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="pagato">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> PAGATO </mat-header-cell>
                        <mat-cell *matCellDef="let row" [class.example-expanded-row]="expandedRow === row"
                            (click)="expandedRow = expandedRow === row ? null : row">
                            {{row.pagato}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ordineTestataPagamento.codTrans">
                        <mat-header-cell *matHeaderCellDef> COD TRANS </mat-header-cell>
                        <mat-cell *matCellDef="let row" [class.example-expanded-row]="expandedRow === row"
                            (click)="expandedRow = expandedRow === row ? null : row">
                            {{row.ordineTestataPagamento != null ? row.ordineTestataPagamento.codTrans : '' }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="modifica">
                        <mat-header-cell *matHeaderCellDef>MODIFICA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="apriOrdine(row.id)"
                                style="color: green;">build</i></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="elimina">
                        <mat-header-cell *matHeaderCellDef>ELIMINA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="eliminaOrdine(row.id)"
                                style="color: red;">delete</i></mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
                            <div class="example-element-detail"
                                [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
                                <button mat-flat-button color="warn"
                                    (click)="cambiaStatoOrdine(row.idStatoOrdine-1,row.id)">
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> Arretra stato
                                </button>
                                <button mat-flat-button color="primary"
                                    (click)="cambiaStatoOrdine(row.idStatoOrdine+1,row.id)">
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i> Avanza stato
                                </button>
                                <div class="example-element-description">
                                    <p> Nota: <b>{{ row.noteOrdine }}</b> </p>
                                    <p *ngIf="row.idSpedizione>0"> Indirizzo di spedizione: <b>{{
                                            row.indirizzoSpedizione.indirizzo }} {{ row.indirizzoSpedizione.cap }} {{
                                            row.indirizzoSpedizione.comune }} ({{ row.indirizzoSpedizione.provincia }})
                                            {{ row.indirizzoSpedizione.stato }} - Tel: {{
                                            row.indirizzoSpedizione.telefono }}</b> </p>
                                    <p *ngIf="row.idFatturazione>0"> Indirizzo di Fatturazione: <b>{{
                                            row.indirizzoFatturazione.indirizzo }} {{ row.indirizzoFatturazione.cap }}
                                            {{ row.indirizzoFatturazione.comune }} ({{
                                            row.indirizzoFatturazione.provincia }}) {{ row.indirizzoFatturazione.stato
                                            }} - P.IVA: {{ row.indirizzoFatturazione.fatturaPIVA }} Cod.Fisc.: {{
                                            row.indirizzoFatturazione.fatturaCodFisc }} Cod.Dest. {{
                                            row.indirizzoFatturazione.fatturaCodiceDestinatario }} Pec: {{
                                            row.indirizzoFatturazione.fatturaPec }}</b></p>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">N.</th>
                                                <th scope="col">Codice</th>
                                                <th scope="col">Descrizione</th>
                                                <th scope="col">Quantita</th>
                                                <th scope="col">Prezzo</th>
                                                <th scope="col">Totale</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let dettaglio of row.ordineDettagli">
                                                <th scope="row">{{ dettaglio.id }}</th>
                                                <td>{{ dettaglio.codArt }}</td>
                                                <td>{{ dettaglio.descrizioneArticolo }}</td>
                                                <td>{{ dettaglio.quantita }} </td>
                                                <td>{{ dettaglio.prezzoSingolo | currency: 'EUR':'symbol' }}</td>
                                                <td>{{ dettaglio.prezzoTotale | currency: 'EUR':'symbol' }}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td>Totale articoli</td>
                                                <td></td>
                                                <td></td>
                                                <td>{{row.importoArticoli | currency: 'EUR':'symbol'}}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td>Spese di spedizione</td>
                                                <td></td>
                                                <td></td>
                                                <td>{{row.importoSpeseSpedizione | currency: 'EUR':'symbol'}}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td>Totale ordine</td>
                                                <td></td>
                                                <td></td>
                                                <th>{{row.importoTotale | currency: 'EUR':'symbol'}}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                    </tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>
        <div class="col-lg-12 text-right">
            <button class="btn btn-success" [useExistingCss]="true" printSectionId="printInventario"
                ngxPrint>Stampa</button>
        </div>                
        <div id="printInventario" style="display: none;">
            <h1>Stampa ordini</h1>
            <div class="row">
                <div *ngFor="let ordine of dataSource.filteredData" style="margin-bottom: 50px;display: block; page-break-before: auto;" class="col-lg-12">
                    <div class="col-lg-12">
                        Ordine n. <b>{{ ordine.id }}</b> del <b>{{ ordine.data | date: 'dd/MM/yyyy' }}</b> dell'utente <b>{{ ordine.userName }}</b> <br>
                        <span *ngIf="ordine.indirizzoSpedizione != null">Indirizzo di spedizione: 
                            <b>{{ ordine.indirizzoSpedizione.indirizzo }} {{ ordine.indirizzoSpedizione.cap }}
                            {{ ordine.indirizzoSpedizione.comune }} ({{ ordine.indirizzoSpedizione.provincia }})
                            {{ ordine.indirizzoSpedizione.stato }}</b> - Tel: <b>{{ ordine.indirizzoSpedizione.telefono }}</b>
                        </span><br>
                        <span *ngIf="ordine.indirizzoFatturazione != null">Dati di fatturazione: <b>{{ordine.indirizzoFatturazione.nominativo}}</b>
                            Indirizzo <b>{{ordine.indirizzoFatturazione.indirizzo }} {{ ordine.indirizzoFatturazione.cap }}
                            {{ ordine.indirizzoFatturazione.comune }} ({{ ordine.indirizzoFatturazione.provincia }})
                            {{ ordine.indirizzoFatturazione.stato }}</b> - P.IVA <b>{{ ordine.indirizzoFatturazione.fatturaPIVA
                            }}</b>
                            CF <b>{{ ordine.indirizzoFatturazione.fatturaCodFisc }}</b> Pec <b>{{
                            ordine.indirizzoFatturazione.fatturaPec }}</b>
                            Cod.Des. <b>{{ ordine.indirizzoFatturazione.fatturaCodiceDestinatario }}</b>
                        </span>
                        Stato: <b>{{ordine.statoOrdine.descrizione}}</b>
                    </div>
                    <div class="col-lg-12">
                        <table class="table">
                            <thead>
                                <tr style="border-bottom: 2px solid #000;">
                                    <th scope="col" style="text-align: left!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">N.</th>
                                    <th scope="col" style="text-align: left!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">Codice</th>
                                    <th scope="col" style="text-align: left!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">Descrizione</th>
                                    <th scope="col" style="text-align: right!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">Quantita</th>
                                    <th scope="col" style="text-align: right!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">Prezzo</th>
                                    <th scope="col" style="text-align: right!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">Totale</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dettaglio of ordine.ordineDettagli">
                                    <td>{{ dettaglio.id }}</td>
                                    <td>{{ dettaglio.codArt }}</td>
                                    <td>{{ dettaglio.descrizioneArticolo }}</td>
                                    <td style="text-align: right">{{ dettaglio.quantita }} </td>
                                    <td style="text-align: right">{{ dettaglio.prezzoSingolo | currency: 'EUR':'symbol' }}</td>
                                    <td style="text-align: right">{{ dettaglio.prezzoTotale | currency: 'EUR':'symbol' }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Totale articoli</td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right">{{ordine.importoArticoli | currency: 'EUR':'symbol'}}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Spese di spedizione</td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right">{{ordine.importoSpeseSpedizione | currency: 'EUR':'symbol'}}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Totale ordine</td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right">{{ordine.importoTotale | currency: 'EUR':'symbol'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>




    </div>
</div>