<section class="container">
    <div class="row">
        <div class="col-lg-12">
            <h3 class="page-title">Risultati Ricerca: <small>Trovati {{numeroUtenti}} Utenti</small></h3>
        </div>
        <div class="col-lg-12 alert alert-success" *ngIf="messaggio">
            {{messaggio}}
        </div>
        <div class="col-lg-12">
            <mat-form-field>
                <input (keyup)="applicaFiltro($event.target.value)" matInput placeholder="Filtro">
            </mat-form-field>
            <div class="mat-elevation-z20">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> EMAIL </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.email}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="nominativo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> NOMINATIVO </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.cognome}} {{row.nome}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="abilitato">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ABILITATO </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.abilitato}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="modifica">
                        <mat-header-cell *matHeaderCellDef>MODIFICA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="apriUtente(row.username)"
                                style="color: green;">build</i></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="elimina">
                        <mat-header-cell *matHeaderCellDef>Elimina</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons"
                                (click)="eliminaUtente(row.username)" style="color: red;">delete</i></mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                    </tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>
    </div>
</section>