import { Fornitori } from './fornitori';
import { MagazzinoMovimento } from './magazzinoMovimento';
import { ArticoliFoto } from './articoliFoto';
import { ArticoliCategorie } from './articoliCategorie';
import { UArticoliCaratteristiche } from './UArticoliCaratteristiche';
import { MagazzinoDisponibilita } from './magazzinoDisponibilita';
export class Articoli {
    constructor(
        public codArt:string,
        public titolo:string,
        public descrizione:string,
        public prezzo:number,
        public ingredienti:string,
        public idFornitore:number,
        public idProduttore:number,
        public idCategoria:number,
        public dicituraUrl:string,
        public noVisualizzaOnline:boolean,
        public categoria:ArticoliCategorie,
        public caratteristiche:UArticoliCaratteristiche[],
        public foto:ArticoliFoto[],
        public disponibilita:MagazzinoDisponibilita,
        public movimentiMagazzino:MagazzinoMovimento[],
        public fornitore:Fornitori,
        public produttore:Fornitori,
        public timeStamp_Ins:Date,
        public soloRitiro:boolean
    ) { }
  }
