import { ConclusioneOrdineComponent } from './page/conclusione-ordine/conclusione-ordine.component';
import { RimozioneNewsletterComponent } from './page/rimozione-newsletter/rimozione-newsletter.component';
import { ElencoOrdiniUtenteComponent } from './page/elenco-ordini-utente/elenco-ordini-utente.component';
import { ConclusionePagamentoComponent } from './page/conclusione-pagamento/conclusione-pagamento.component';
import { ProvaArrayArticoliComponent } from './zProve/prova-array-articoli/prova-array-articoli.component';
import { OrdineComponent } from './pagesAdmin/ordine/ordine.component';
import { OrdiniComponent } from './pagesAdmin/ordini/ordini.component';
import { MagazzinoDisponibilitaComponent } from './pagesAdmin/magazzino-disponibilita/magazzino-disponibilita.component';
import { MagazzinoMovimentiComponent } from './pagesAdmin/magazzino-movimenti/magazzino-movimenti.component';
import { ArticoliCaratteristicheComponent } from './pagesAdmin/articoli-caratteristiche/articoli-caratteristiche.component';
import { AreaUtenteComponent } from './page/area-utente/area-utente.component';
import { CheckoutComponent } from './page/checkout/checkout.component';
import { CarrelloComponent } from './page/carrello/carrello.component';
import { ArticoloSpesaComponent } from './page/articolo-spesa/articolo-spesa.component';
import { SpesaArticoliComponent } from './page/spesa-articoli/spesa-articoli.component';
import { UtenteComponent } from './pagesAdmin/utente/utente.component';
import { CategoriaComponent } from './pagesAdmin/categoria/categoria.component';
import { ArticoloComponent } from './pagesAdmin/articolo/articolo.component';
import { CategorieComponent } from './pagesAdmin/categorie/categorie.component';
import { FornitoriComponent } from './pagesAdmin/fornitori/fornitori.component';
import { ArticoliComponent } from './pagesAdmin/articoli/articoli.component';
import { RecuperaPasswordComponent } from './page/recupera-password/recupera-password.component';
import { ConfermaMailComponent } from './page/conferma-mail/conferma-mail.component';
import { ElencoUtentiComponent } from './pagesAdmin/utenti/utenti.component';
import { DashboardComponent } from './pagesAdmin/dashboard/dashboard.component';
import { RegistratiComponent } from './page/registrati/registrati.component';
import { LoginComponent } from './page/login/login.component';
import { ContattaciComponent } from './page/contattaci/contattaci.component';
import { ChiSiamoComponent } from './page/chi-siamo/chi-siamo.component';
import { Errore404Component } from './page/errore404/errore404.component';
import { HomepageComponent } from './page/homepage/homepage.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteguardService } from './services/routeguard.service';
import { Ruoli } from './modelli/ruoli';
import { ArticoliCaratteristicaComponent } from './pagesAdmin/articoli-caratteristica/articoli-caratteristica.component';
import { ElencoIndirizziUtenteComponent } from './page/elenco-indirizzi-utente/elenco-indirizzi-utente.component';
import { TerminiECondizioniComponent } from './page/termini-e-condizioni/termini-e-condizioni.component';
import { CondizioniDiVenditaComponent } from './page/condizioni-di-vendita/condizioni-di-vendita.component';
import { BlogENewsComponent } from './page/blog-e-news/blog-e-news.component';
import { RisoCarnaroliVillareggioComponent } from './page/blog/riso-carnaroli-villareggio/riso-carnaroli-villareggio.component';
import { MieleComponent } from './page/blog/miele/miele.component';
import { SportDeiColombiComponent } from './page/blog/sport-dei-colombi/sport-dei-colombi.component';
import { NewlsettersComponent } from './pagesAdmin/newlsetters/newlsetters.component';
import { BirraAmberaleLaBruciataComponent } from './page/blog/birra-amberale-la-bruciata/birra-amberale-la-bruciata.component';
import { FaqSpedizioneComponent } from './page/faq-spedizione/faq-spedizione.component';
import { MarzoInOrtoComponent } from './page/blog/marzo-in-orto/marzo-in-orto.component';
import { OrtoInCasaComponent } from './page/blog/orto-in-casa/orto-in-casa.component';


const routes: Routes = [
  {path:'', component:HomepageComponent, data:{title:'Molino Lucconi Home', description:'Molino Lucconi, artigiani mugnai dal 1908', ogUrl:'https://www.molinolucconi.it/', ogImage:'/assets/img/molino-lucconi-shop-online-2.png'}},
  {path:'chi-siamo',component:ChiSiamoComponent, data:{title:'Molino Lucconi | Chi siamo', description:'Scopri la nostra storia', ogUrl:'https://www.molinolucconi.it/chi-siamo', ogImage:'assets/img/molino-lucconi-homepage.jpg'}},
  {path:'contattaci',component:ContattaciComponent,data:{title:'Molino Lucconi | Contatti', description:'Contattaci', ogUrl:'https://www.molinolucconi.it/contattaci', ogImage:'assets/img/molino-lucconi-homepage.jpg'}},
  {path: 'condizioni-di-vendita', component:CondizioniDiVenditaComponent, data:{title:'Molino Lucconi | Condizioni di vendita', description:'Molino Lucconi, condizioni di vendita per ordini dello shop online.', ogUrl:'https://www.molinolucconi.it/condizioni-di-vendita', ogImage:'/assets/img/molino-lucconi-homepage.jpg'}},
  {path: 'termini-e-condizioni', component:TerminiECondizioniComponent, data:{title:'Molino Lucconi | Termini e condizioni', description:'Molino Lucconi, Termini e condizioni per ordini e utilizzo dello shop online.', ogUrl:'https://www.molinolucconi.it/termini-e-condizioni', ogImage:'/assets/img/molino-lucconi-homepage.jpg'}},
  {path: 'blog-e-news', component:BlogENewsComponent, data:{title:'Molino Lucconi | Blog e News', description:'Rimani aggiornato su tutte le novità del Molino Lucconi e tutti gli approfondimenti su prodotti, attrezzi, materie prime e shop online!', ogUrl:'https://www.molinolucconi.it/blog-e-news', ogImage:'/assets/img/molino-lucconi-homepage.jpg'}},
  {path: 'faq-spedizione', component:FaqSpedizioneComponent, data:{title:'Molino Lucconi | FAQ Spedizioni', description:'FAQ Spedizioni per gli ordini dello shop online di Molino Lucconi', ogUrl:'https://www.molinolucconi.it/faq-spedizione', ogImage:'/assets/img/molino-lucconi-homepage.jpg'}},

  {path:'riso-carnaroli-villareggio', component:RisoCarnaroliVillareggioComponent, data:{title:'Molino Lucconi | Riso Carnaroli Villareggio', description:'Scopri le proprietà e le origini del riso Carnaroli di Villareggio', ogUrl:'https://www.molinolucconi.it/riso-carnaroli-villareggio', ogImage:'/assets/img/blog/molino-lucconi-riso-carnaroli-di-villareggio-001-01.JPG'}},
  {path: 'miele', component:MieleComponent, data:{title:'Molino Lucconi | Miele', description:'Scopri le proprietà curative del miele!', ogUrl:'https://www.molinolucconi.it/miele', ogImage:'/assets/img/blog/molino-lucconi-miele-0050.JPG'}},
  {path: 'sport-dei-colombi', component:SportDeiColombiComponent, data:{title:'Molino Lucconi | Colombi', description:'Mangimi e prodotti per colombi', ogUrl:'https://www.molinolucconi.it/sport-dei-colombi', ogImage:'/assets/img/blog/molino-lucconi-mangime-colombi.png'} },
  {path: 'birra-amberale-la-bruciata', component:BirraAmberaleLaBruciataComponent, data:{title:'Birra "Lamberale" La Bruciata | Molino Lucconi', description:'La nuova birra artigianale del sud di Milano: "Lamberale". Lamberale è la birra dedicata al fiume Lambro che attraversa le terre del Sud-Est Milano.', ogUrl:'https://www.molinolucconi.it/birra-amberale-la-bruciata', ogImage:'https://www.molinolucconi.it/assets/img/blog/birra-lamberale-la-bruciata-2.jpg'}},
  {path: 'marzo-in-orto', component:MarzoInOrtoComponent, data:{title:'Marzo in Orto! | Consigli e accorgimenti', description:'Marzo è un mese che ci permette di aumentare il tempo dedicato al nostro orto, tuttavia le temperature instabili richiedono particolare attenzione!', ogUrl:'https://www.molinolucconi.it/marzo-in-orto', ogImage:'https://www.molinolucconi.it/assets/img/blog/consigli-orto-mese-marzo.png'}},
  {path: 'orto-in-casa', component:OrtoInCasaComponent, data:{title:'Orto in casa!', description:'In campagna e in città, un ritorno alle origini sempre maggiore.', ogUrl:'https://www.molinolucconi.it/orto-in-casa', ogImage:'https://www.molinolucconi.it/assets/img/blog/come-fare-orto-in-casa-semenze-online-molino-lucconi.png'}},

  {path:'login',component:LoginComponent}, 
  {path:'registrati',component:RegistratiComponent}, 
  {path:'spesa-online',component:SpesaArticoliComponent},

  {path:'spesa-online/prodotto/:categoria1/:articolo',component:ArticoloSpesaComponent},
  {path:'spesa-online/prodotto/:categoria1/:categoria2/:articolo',component:ArticoloSpesaComponent},
  {path:'spesa-online/prodotto/:categoria1/:categoria2/:categoria3/:articolo',component:ArticoloSpesaComponent},  

  {path:'conclusione-pagamento/esitook',component:ConclusionePagamentoComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.utente]}},
  {path:'conclusione-pagamento/esitoko',component:ConclusionePagamentoComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.utente]}},
  {path:'conclusione-ordine/:idOrdine',component:ConclusioneOrdineComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.utente]}},

  {path:'utente/area-utente',component:AreaUtenteComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.utente]}},
  {path:'utente/ordini',component:ElencoOrdiniUtenteComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.utente]}},
  {path:'utente/indirizzi',component:ElencoIndirizziUtenteComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.utente]}},

  {path:'spesa-online/carrello',component:CarrelloComponent, data:{title:'Molino Lucconi | Carrello'}},
  
  {path:'confermaMail/:codiceConferma',component:ConfermaMailComponent,data:{title:'Molino Lucconi | Spesa Online'}}, 
  {path:'recuperaPassword/:codiceReset',component:RecuperaPasswordComponent, data:{title:'Molino Lucconi | Recupera password'}}, 
  {path:'rimuovinewsletter/:email/:removecode',component:RimozioneNewsletterComponent, data:{title:'Molino Lucconi | Newsletter'}},

  {path:'checkout',component:CheckoutComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.utente]}},  
  {path:'amm/dashboard', component:DashboardComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/utenti', component:ElencoUtentiComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/utente/:username', component:UtenteComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/newsletters', component:NewlsettersComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/articoli',component:ArticoliComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/articolo/:codart', component:ArticoloComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/fornitori',component:FornitoriComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/categorie',component:CategorieComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/categoria/:id', component:CategoriaComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/caratteristiche',component:ArticoliCaratteristicheComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/caratteristica/:id', component:ArticoliCaratteristicaComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/magazzinomovimenti', component:MagazzinoMovimentiComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/magazzinodisponibilita', component:MagazzinoDisponibilitaComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/ordini', component:OrdiniComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'amm/ordine/:id', component:OrdineComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'prove',component:ProvaArrayArticoliComponent,canActivate:[RouteguardService],data:{roles:[Ruoli.amministratore]}},
  {path:'**', component:Errore404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
