import { MagazzinoMovimentoDataService } from './../../services/data/magazzino-movimento-data.service';
import { PatchMethod } from './../../modelli/shared/patchMethod';
import { FormGroup, FormControl } from '@angular/forms';
import { StatoOrdineDataService } from './../../services/data/stato-ordine-data-service';
import { FiltroOrdini } from './../../modelli/shared/Classi/Filtri/filtroOrdini';
import { OrdinitestataDataService } from 'src/app/services/data/ordinitestata-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { OrdiniTestata } from 'src/app/modelli/shared/ordiniTestata';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/app/loading.service';
import { StatoOrdine } from 'src/app/modelli/shared/statoOrdine';
import { DialogClass } from 'src/app/modelli/shared/Classi/Componenti/dialogClass';
import { DialogComponent } from 'src/app/component/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ordini',
  templateUrl: './ordini.component.html',
  styleUrls: ['./ordini.component.css'], animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class OrdiniComponent implements OnInit {
  displayedColumns: string[] = ['numero','data', 'statoOrdine.descrizione', 'importo', 'username', 'metodoPagamento.descrizione', 'pagato', 'ordineTestataPagamento.codTrans', 'modifica', 'elimina'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();
  expandedRow: OrdiniTestata | null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ordini: OrdiniTestata[];
  statiOrdine: StatoOrdine[];
  apiMsg: ApiMsg;

  fFiltro: FormGroup;
  filtroOrdini: FiltroOrdini;


  constructor(private OrdiniService: OrdinitestataDataService, private router: Router, private appLoading: LoadingService, private statoOrdineDataService: StatoOrdineDataService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.popolaCombo();
    let tmpAl: Date = new Date();
    let tmpDal: Date = new Date(tmpAl.getFullYear(), tmpAl.getMonth(), 1);
    this.fFiltro = new FormGroup({
      'dataDal': new FormControl(tmpDal),
      'dataAl': new FormControl(tmpAl),
      'idStatoOrdine': new FormControl(0),
      'statoPagati': new FormControl(0)
    })
    this.filtroOrdini = new FiltroOrdini('', tmpDal, tmpAl, 0, 0);
    this.popolaTabella();
  }

  get dataDal() {
    return this.fFiltro.get('dataDal');
  }
  get dataAl() {
    return this.fFiltro.get('dataAl');
  }
  get idStatoOrdine() {
    return this.fFiltro.get('idStatoOrdine');
  }
  get statoPagati() {
    return this.fFiltro.get('statoPagati');
  }

  popolaTabella() {
    this.appLoading.avvia();
    this.OrdiniService.listaFiltrata(this.filtroOrdini).subscribe(
      response => {
        this.ordini = []
        this.ordini = response;
        this.dataSource = null;
        this.dataSource = new MatTableDataSource(this.ordini);

        //Ordino gli gli ordino con data desc
        this.ordini = this.ordini.sort((a, b) => (a.data > b.data ? -1 : 1));

        this.dataSource.filterPredicate = (data: OrdiniTestata, filter: string) => {

          if (data.ordineTestataPagamento != null) {
            return data.statoOrdine.descrizione.toLocaleLowerCase().includes(filter) ||
              data.metodoPagamento.descrizione.toLocaleLowerCase().includes(filter) ||
              data.ordineTestataPagamento.codTrans.toLocaleLowerCase().includes(filter)
          } else {
            return data.statoOrdine.descrizione.toLocaleLowerCase().includes(filter) ||
              data.metodoPagamento.descrizione.toLocaleLowerCase().includes(filter)
          }
        }

        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
          return this.getPropertyByPath(data, sortHeaderId);
        };

        this.dataSource.paginator = this.paginator;
        this.appLoading.stoppa();
                
      }
    )
  }

  popolaCombo() {
    this.statoOrdineDataService.elenco().subscribe(
      response => {
        this.statiOrdine = response;
      }
    )
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  eliminaOrdine(id: number) {
    let dialogData: DialogClass = new DialogClass("Elimna ordine " + id, "Sicuri di voler impostare l'ordine come eliminato?", true, true, true)
    let dialogRef = this.dialog.open(DialogComponent, { data: dialogData })
    dialogRef.afterClosed().subscribe(
      response => {
        if (response == "Si") {
          this.OrdiniService.impostaEliminato(id).subscribe(
            response=>{
              this.popolaTabella();
            }
          )

        }
      }, error => {

      }
    )
  }

  apriOrdine(id: number) {
    this.router.navigate(['amm/ordine', id]);
  }


  applicaFiltro(filtro: string) {
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

  filtra() {
    // this.filtroOrdini = new FiltroOrdini('', null, null, 0);

    if (isNaN(Date.parse(this.dataDal.value)) || isNaN(Date.parse(this.dataAl.value))) {
      return null;
    }

    let tmpDal = new Date(this.dataDal.value);
    let tmpAl = new Date(this.dataAl.value);
    this.filtroOrdini.dataDal = tmpDal;
    this.filtroOrdini.dataAl = tmpAl;

    this.filtroOrdini.idStatoOrdine = this.idStatoOrdine.value;
    this.filtroOrdini.statoPagati = this.statoPagati.value;

    this.popolaTabella();
  }

  // -1 => Arretra    +1 => avanza 
  cambiaStatoOrdine(tmpIdStatoOrdine: number, idOrdineTestata: number) {

    //Se vado sotto l'1 (inserito) non faccio niente, se vado sopra il 7 idem
    if (tmpIdStatoOrdine < 1 || tmpIdStatoOrdine > 5) {
      return null;
    }

    let param = new PatchMethod(tmpIdStatoOrdine.toString(), '/IDStatoOrdine', 'replace');
    this.OrdiniService.impostaStatoOrdine(idOrdineTestata, [param]).subscribe(
      response => {
        this.popolaTabella();
      }
    )



  }

}
