import { AuthJWTService } from './auth-jwt.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class RouteguardService {

  token: string = "";
  ruoli: string[] = new Array();
  items: any;

  constructor(private AuthJWT: AuthJWTService,private route: Router) { }

  // Il servizio verifica se o meno l'utente è loggato
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    //Gestisco i ruoli
    this.token = this.AuthJWT.getAuthToken(); //Ottengo il token
    
    //Se non ho un token attivo rimando alla login perchè vuol dire che l'utente non è loggato
    if(this.token == null){
      this.route.navigate(["login"]);
      return false
    }

    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(this.token); //Decodifico il token per ricavarne delle informazioni

    this.items = decodedToken['role']; //Inserisco nella variabile items il valore che otteniamo dal gruppo dei ruoli

    if (!Array.isArray(this.items)) { //Verifico se è un array, se c'è un solo ruolo è una stringa mentre se ce ne sono di più torna un array
      //Se non è un'array ma una stringa inserisco la stringa nell'array ruoli
      this.ruoli.push(this.items);
    }
    else{ //Altrimenti inserisco i valori della variabile items nell'array ruoli
      this.ruoli=this.items;
    }
    
    //Verifico se l'utente è loggato
    if (!this.AuthJWT.isLogged()) {
      this.route.navigate(["login"]);
      return false;
    }
    else { //Se l'utente non è loggato verifico se la rotta è stata configurata con i ruoli
      if(route.data.roles==null || route.data.roles.length===0) //Se la rotta non ha blocchi per i ruoli ritorno true
        return true;    
      else if (this.ruoli.some(r=>route.data.roles.includes(r))) //Se i valori dei ruoli è inserito all'interno della variabile ruoli della rotta allora ritorno true
        return true;
      else //Se nessuna delle condizioni precedenti non è soddisfatta vuol dire che l'utente non è abilitato ad entrare nella rotta e quindi lo rimando ad una pagina dove glielo comunico
        this.route.navigate(["forbidden"]);
        return false;
    }

  }
}
