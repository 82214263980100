<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-9">
                    <h3 class="page-title">Risultati Ricerca: <small>Trovati {{numeroCaratteristiche}} caratteristiche articolo</small></h3>
                </div>
                <div class="col-lg-3 text-right">
                    <button mat-flat-button color="primary" (click)="apriCaratteristica(0)">Nuovo</button>
                </div>
            </div>
        </div>
        <div class="col-lg-12 alert alert-success" *ngIf="messaggio">
            {{messaggio}}
        </div>
        <div class="col-lg-12">
            <mat-form-field>
                <input (keyup)="applicaFiltro($event.target.value)" matInput placeholder="Filtro">
            </mat-form-field>
            <div class="mat-elevation-z20">
                <table class="table table-striped table-bordered">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="id">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.id}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="titolo">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Titolo </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.titolo}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="modifica">
                            <mat-header-cell *matHeaderCellDef>MODIFICA</mat-header-cell>
                            <mat-cell *matCellDef="let row"><i class="material-icons"
                                    (click)="apriCaratteristica(row.id)" style="color: green;">build</i></mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="elimina">
                            <mat-header-cell *matHeaderCellDef>ELIMINA</mat-header-cell>
                            <mat-cell *matCellDef="let row"><i class="material-icons" (click)="eliminaCaratteristica(row.id)"
                                    style="color: red;">delete</i></mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </table>
            </div>
        </div>
    </div>
</div>