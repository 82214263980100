import { MatSort } from '@angular/material/sort';
import { FiltroArticoli } from './../Classi/Filtri/filtroArticoli';
import { Injectable } from '@angular/core';

export class MatSortParameter{
    constructor(
        public matSortActive:string,
        public matSortDirection:string,
        public nPagina:number,
        public filtroTable:string
    ){}
}

@Injectable({
    providedIn: 'root'
})

//Questo componente di funzioni viene usato per qualsiasi operazione nella localstorage che non riguarda l'utente, presente solo una funione di lettura
export class FunzioniLocalStorage {
        
    constructor(){}

    getIdCart(){
        return localStorage.getItem('cartId');
    }

    setIdCart(idCart:string){
        localStorage.setItem('cartId',idCart);
    }

    removeIdCart(){
        localStorage.removeItem('cartId');
    }

    getFiltroArticoli(){
        let tmpFiltroString:string=localStorage.getItem('filart');
        let filtro:FiltroArticoli=JSON.parse(tmpFiltroString);
        if (filtro == null){
            return new FiltroArticoli(0,0,[],0,0,0,"",false)
        }else{
            if (!filtro.paginaVisualizzazione){
                filtro.paginaVisualizzazione=1;
            }            
            
            return filtro
        }       
    }

    setFiltroArticoli(filtro:FiltroArticoli){
        localStorage.setItem('filart',JSON.stringify(filtro));
    }

    removeFiltroArticoli(){
        localStorage.removeItem('filart');
    }

    setOrdinePaginaTabellaArticoli(matSortParameter:MatSortParameter){
        localStorage.setItem('matSortParameter',JSON.stringify(matSortParameter));
    }

    getOrdinePaginaTabellaArticoli(){        
        let tmpOrdine:string=localStorage.getItem("matSortParameter");
        let ordine:MatSortParameter;
        //Se non trovo nella localstorage creo quella di default
        if (tmpOrdine==null){ 
            ordine = this.removeOrdinePaginaTabellaArticoli()
         }else{
            ordine = JSON.parse(tmpOrdine);
         }        
        return ordine;
    }

    removeOrdinePaginaTabellaArticoli(){
        let ordine:MatSortParameter = new MatSortParameter("","",0,"");
        this.setOrdinePaginaTabellaArticoli(ordine);
        return ordine;
    }
    
}