import { Articoli } from './../../modelli/shared/articoli';
import { ArticoliDataService } from './../../services/data/articoli-data.service';
import { LoadingService } from './../../services/app/loading.service';
import { FiltroMagazzinoDisponibilita } from './../../modelli/shared/Classi/Filtri/filtroMagazzinoDisponibilita';
import { ArticoliCategorieDataService } from './../../services/data/articoli-categorie-data.service';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MagazzinoDisponibilita } from 'src/app/modelli/shared/magazzinoDisponibilita';
import { MagazzinoDisponibilitaDataService } from 'src/app/services/data/magazzino-disponibilita-data.service';
import { ArticoliCategorie } from 'src/app/modelli/shared/articoliCategorie';
import { FiltroArticoliCategorie } from 'src/app/modelli/shared/Classi/Filtri/filtroArticoliCategorie';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { CrudMovimentoMagazzinoComponent } from 'src/app/componentAdmin/crud-movimento-magazzino/crud-movimento-magazzino.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogMovMagazzinoComponent } from '../dialog-mov-magazzino/dialog-mov-magazzino.component';


@Component({
  selector: 'app-magazzino-disponibilita',
  templateUrl: './magazzino-disponibilita.component.html',
  styleUrls: ['./magazzino-disponibilita.component.css']
})
export class MagazzinoDisponibilitaComponent implements OnInit {
  displayedColumns: string[] = ['articolo.titolo', 'codArt', 'qta', 'inserisci'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('vc', { read: ViewContainerRef }) vcr: ViewContainerRef;

  disponibilita: MagazzinoDisponibilita[];
  categorie: ArticoliCategorie[];

  dataStampa: Date = new Date();
  fFiltro: FormGroup;
  

  constructor(private disponibilitaService: MagazzinoDisponibilitaDataService, private categorieService: ArticoliCategorieDataService, private appLoading: LoadingService,
    private snackBar: MatSnackBar, private cfr: ComponentFactoryResolver,private articoliService:ArticoliDataService,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.fFiltro = new FormGroup({
      'tipoQuantita': new FormControl(0),
      'idCategoriaLivelloUno': new FormControl(0)
    })
    this.popolaCombo();
    this.popolaTabella('');
  }

  get tipoQuantita() {
    return this.fFiltro.get('tipoQuantita');
  }

  get idCategoriaLivelloUno() {
    return this.fFiltro.get('idCategoriaLivelloUno')
  }

  popolaCombo() {
    let filtroCat: FiltroArticoliCategorie = new FiltroArticoliCategorie(1, 0);
    this.categorieService.cercaCategorieConFiltri(filtroCat).subscribe(
      response => {
        this.categorie = response;
      }
    )
  }

  popolaTabella(filtroSearch:string) {
    let filtro: FiltroMagazzinoDisponibilita = new FiltroMagazzinoDisponibilita(this.tipoQuantita.value, this.idCategoriaLivelloUno.value)
    this.disponibilitaService.listaFiltrata(filtro).subscribe(
      response => {
        this.disponibilita = [];
        this.disponibilita = response;
        this.dataSource = new MatTableDataSource(this.disponibilita);

        this.dataSource.filterPredicate = (data: MagazzinoDisponibilita, filter: string) => {
          return data.articolo.titolo.toLocaleLowerCase().includes(filter)
        }

        this.sort.sort(({ id: 'articolo.titolo', start: 'asc' }) as MatSortable);
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
          return this.getPropertyByPath(data, sortHeaderId);
        };

        this.dataSource.paginator = this.paginator;
        if(filtroSearch != ''){
          this.applicaFiltro(filtroSearch);
        }
      }
    )
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  applicaFiltro(filtro: string) {
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

  filtra(dati: FormGroup) {

    this.popolaTabella('');
  }

  riallineaDisponibilita() {
    this.appLoading.avvia();
    this.disponibilitaService.rialiineaDisponibilita().subscribe(
      respnose => {
        let snackBarData: SnackBarClass = new SnackBarClass("Magazzino riallineato con successo!", false)
        let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
        this.appLoading.stoppa();
      }
    )

  }

  insMovimento(row: MagazzinoDisponibilita) {
    let componentFactory = this.cfr.resolveComponentFactory(CrudMovimentoMagazzinoComponent);
    this.vcr.clear();
    let component = this.vcr.createComponent(componentFactory);
    component.instance.codArticolo=row.articolo.codArt;
    //Rimango in ascolto della variabile finito del figlio, quando questa diventa true distruggo il figlio
    component.instance.finito.subscribe(
      response => {
        if (response == true) {
          this.vcr.clear();
          let tmpArticolo:Articoli;
          this.articoliService.cercaArticoloByCodart(row.articolo.codArt).subscribe(
            response=>{
              tmpArticolo=response;   
              this.popolaTabella(tmpArticolo.titolo);          
              // let filtro:FiltroMagazzinoDisponibilita = new FiltroMagazzinoDisponibilita(0,tmpArticolo.categoria.idPadre);
              // this.disponibilitaService.listaFiltrata(filtro).subscribe(
              //   response=>{
              //     let tmpInv:MagazzinoDisponibilita[]=response;
              //     let rowInd:MagazzinoDisponibilita=tmpInv.filter(f=>f.codArt==tmpArticolo.codArt)[0];
              //     let index= tmpInv.findIndex(f=>f.codArt==tmpArticolo.codArt); 
              //     this.disponibilita[index].qta=rowInd.qta;  
              //     this.applicaFiltro(tmpArticolo.titolo);                  
                  
              //   }
              // )
            }
          )          
          //this.popolaTabella();
        }
      }
    )
  }

  insMovimentoPopup(row:MagazzinoDisponibilita){    
    let dialogRef = this.dialog.open(DialogMovMagazzinoComponent,{ 
      height: '420px',
      width:"600px",
      data:row.codArt
    });
    dialogRef.afterClosed().subscribe(
      response=>{
        if (response.event=="OK"){
          let nPage:number=this.paginator.pageIndex;
          //Qui vado a cercare come modificare il valore singolo del mattable          
          this.disponibilitaService.riallineaDisponibilitaCodArt(row.codArt).subscribe(
            response=>{
              row.qta=response;      
            }
          )
          
        }
      }
    )
  }

}
