export class Utenti {
    constructor(
        public id:number,
        public username: string,
        public password: string,
        public nome: string,
        public cognome: string,
        public email: string,
        public abilitato: boolean,
        public ripetiPassword:string,
        public emailConfermata:boolean,
        public codiceMailConferma:string,
        public accettazionePrivacy:boolean,
        public accettazioneNewsletter:boolean
    ) { }
  }
