<div class="body">
    <div style="text-align: -webkit-center;">
        <div class="col-lg-6 text-center ">
            <img class="img-fluid mat-elevation-z20 w-75" src="/assets/img/blog/consigli-orto-mese-marzo.png">
        </div>
    </div>

    <div class="container text-left">
        <div class=" mat-elevation-z20 px-5 pb-3 mx-3 mt-4 white">
            <div class=" py-5">
                <p class="testo-blog lead  ">
                    Marzo è un mese che ci permette di aumentare il tempo dedicato al nostro orto.<br>
                    Le temperature sono ancora instabili con la possibilità di avere sbalzi termici e per questo motivo
                    si deve <b>prestare attenzione alla tipologia di ortaggi scelti per la semina</b>.<br>

                    I lavori più importanti da fare prima di passare alla scelta della semina per il vostro orto
                    sono:<br>
                </p>
                <ul class="testo-blog lead  ">
                    <li><b>Arieggiatura delle serre e dei tunnel</b> durante le ore più calde del giorno</li>
                    <li>Eliminazione delle <b>erbe infestanti</b> presenti sul terreno</li>
                </ul>
                <p class="testo-blog lead  "> Ecco un paio di <b>attrezzature da giardino</b> che possono essere molto
                    utili per eseguire questi lavori nell'orto!</p>
            </div>

        </div>
    </div>
    <div class="row centrare my-5">

        <div class="col-sm-12 col-md-6 col-lg-2 align-self-stretch " *ngFor="let articolo of articoli">
            <app-articoli-card [articolo]="articolo" [visualizzazione]="2"></app-articoli-card>
        </div>

    </div>

    <div class="container text-left">
        <div class=" mat-elevation-z20 px-5 pb-3 mx-3 mt-4 white">
            <div class=" py-5">
                <p class="testo-blog lead  ">
                    In caso di giornate soleggiate e con temperature più alte rispetto alla media stagionale è meglio
                    <b>prestare attenzione a spinaci, rucole e ravanelli</b> seminati all’aperto in quanto le giovani
                    piantine <b>potrebbero essere attaccate dall’Altica e richiedere quindi dei trattamenti</b>.<br>
                    Se la vostra zona è soggetta ad attacchi di questo insetto l’ideale è proteggere le piantine fin dal
                    momento della semina con <b>tessuto non tessuto avendo</b> cura di lasciarlo finché le piante non
                    raggiungono medie dimensioni.<br><br>
                    Si consiglia anche l’utilizzo di <b>BIO AFID</b>, alle dosi consigliate sulla confezione, <b>per
                        tener lontani insetti</b> che potrebbero compromettere lo sviluppo delle piantine.<br><br>
                </p>
            </div>
        </div>
    </div>
    <div class="p-4" style="text-align: -webkit-center;">
        <div class="col-sm-12 col-md-6 col-lg-2 align-self-stretch">
            <app-articoli-card [articolo]="articolo" [visualizzazione]="2"></app-articoli-card>
        </div>
    </div>


    <div class="container text-left">
        <div class=" mat-elevation-z20 px-5 pb-3 mx-3 mt-3 white">
            <p class="testo-blog lead py-3">
                Nel <b>nord Italia</b>, caratterizzato da un clima più rigido, è <b>meglio procedere alle semine nella
                    seconda metà del mese</b>, mentre al <b>centro e sud</b> Italia si può seminare <b>già da inizio
                    mese</b><br>
                Questo mese si consiglia di procedere con queste semenze:
            </p>
            <ul class="testo-blog lead  ">
                <li><b>Basilico</b></li>
                <li><b>Carote</b></li>
                <li><b>Lattughe</b></li>
                <li><b>Prezzemolo</b></li>
                <li><b>Ravanelli</b></li>
                <li><b>Scarola</b></li>
                <li><b>Zucchine</b></li>

            </ul>
            <p class="testo-blog lead  ">La luna in questo mese è calante dal <b>1 al 12</b> marzo e crescente dal <b>14
                    al 27</b>. E’ consigliato <b>seminare prodotti da foglia durante il periodo di luna calante</b>
                (lattuga, scarola…) mentre in <b>luna crescente prodotti da frutto</b> (zucchine, ravanelli carote…).
            </p>
        </div>
    </div>

    <div class="row centrare my-5">

        <div class="col-sm-12 col-md-6 col-lg-2 align-self-stretch " *ngFor="let articolo of articoliBlog">
            <app-articoli-card [articolo]="articolo" [visualizzazione]="2"></app-articoli-card>
        </div>

    </div>
</div>