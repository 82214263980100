import { ApiMsgBoolean } from './../../modelli/shared/apiMsgBoolean';
import { PatchMethod } from './../../modelli/shared/patchMethod';
import { ApiMsgArray } from './../../modelli/shared/apiMsgArray';

import { authServerUrlAccount } from './../../modelli/app.contstant';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utenti } from 'src/app/modelli/shared/utenti';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';


@Injectable({
  providedIn: 'root'
})
export class UtentiDataService {

  constructor(private http: HttpClient) { }

  registraUtente(utente: Utenti) {
    return this.http.post<ApiMsg>(authServerUrlAccount + "/inserisci", utente);
  }

  modificaUtente(username:string,campi:PatchMethod[]){
    return this.http.patch<ApiMsg>(authServerUrlAccount + "/modifica/" + username,campi);
  }

  eliminaUtente(username: string) {
    return this.http.delete<ApiMsg>(authServerUrlAccount + "/elimina/" + username);
  }

  elencoUtenti() {
    return this.http.get<Utenti[]>(authServerUrlAccount + "/all");
  }

  cercaUtente(username: string) {
    return this.http.get<Utenti>(authServerUrlAccount + "/cerca/" + username);
  }

  verificaSePresente(username:string){
    return this.http.get<ApiMsgBoolean>(authServerUrlAccount + "/verificautentepresente/" + username);
  }

  confirmcodeUtente(username: string) {
    return this.http.get<ApiMsg>(authServerUrlAccount + "/confirmcode/" + username);
  }

  confirmAccount(codeconfirm: string) {
    return this.http.post<ApiMsg>(authServerUrlAccount + "/confirmaccount/" + codeconfirm, codeconfirm);
  }

  creaCodiceRecuperoPassword(username: string) {
    return this.http.get<string>(authServerUrlAccount + "/coderecoverpassword/" + username)
  }

  confirmResetPassword(apiMsgArray: ApiMsgArray) {
    return this.http.post<ApiMsg>(authServerUrlAccount + "/confirmresetpassword", apiMsgArray);
  }
}
