<section class="">
    <div class="row">
        <div class="col-lg-12">
            <button mat-flat-button color="warn" id=btnChiudi type="button" class="pull-right" (click)="chiudi()">
                <i class="fa fa-times" aria-hidden="true"></i> Chiudi
            </button>
        </div>
        <div class="col-lg-12">
            <ul>
                <li class="">Data: <b>{{ ordine.data | date: 'dd/MM/yyyy' }}</b></li>
                <li class="">Username: <b>{{ ordine.userName }}</b></li>
                <li class="">Importo totale articoli: <b>{{ ordine.importoArticoli | currency: 'EUR':'symbol' }}</b>
                </li>
                <li class="">Importo spese di spedizione: <b>{{ ordine.importoSpeseSpedizione | currency: 'EUR':'symbol'
                        }}</b></li>
                <li class="">Importo totale ordine: <b>{{ ordine.importoTotale | currency: 'EUR':'symbol' }}</b></li>
                <li class="">Note: <b>{{ ordine.noteOrdine }}</b></li>
            </ul>
        </div>
        <div class="col-lg-12">
            <button mat-flat-button color="primary" (click)="apriDettaglio(0)">Aggiungi dettaglio</button>
        </div>
        <div class="col-lg-12">
            <ng-template #vc></ng-template>
        </div>
        <div class="col-lg-12">
            <div class="mat-elevation-z20">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="codart">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> CODICE </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.codArt}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="descrizione">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> DESCRIZIONE </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.descrizioneArticolo}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="qta">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> QTA </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.quantita}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="prezzo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> PREZZO </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.prezzoSingolo | currency: 'EUR':'symbol'}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="totale">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> TOTALE </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.prezzoTotale | currency: 'EUR':'symbol'}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="modifica">
                        <mat-header-cell *matHeaderCellDef>MODIFICA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="apriDettaglio(row.id)"
                                style="color: green;">build</i></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="elimina">
                        <mat-header-cell *matHeaderCellDef>ELIMINA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="eliminaOrdine(row.id)"
                                style="color: red;">delete</i></mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                    </tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>
        
    </div>
</section>