<div class="centrare-due container bg-white">
  <div class="  text-center  ">
    <owl-carousel-o [options]="customOptions " class="min-h-carosello">
      <ng-container *ngFor="let articolo of articoli">
        <ng-template carouselSlide [id]="articolo.codArt" [width]="400">
          <app-articoli-card [articolo]="articolo" [visualizzazione]="visualizzazione"></app-articoli-card>
        </ng-template>
      </ng-container>

    </owl-carousel-o>
  </div>
</div>