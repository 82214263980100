<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <form *ngIf="fFiltro" [formGroup]="fFiltro" (ngSubmit)="filtra()">                    
                    <mat-form-field appearance="fill" style="width: 300px;">
                        <mat-label>Stato magazzino</mat-label>
                        <mat-select formControlName="tipoQuantita">
                            <mat-option value=0>Tutti</mat-option>
                            <mat-option value=1>Disponibili</mat-option>
                            <mat-option value=-2>Valori negativi</mat-option>
                            <mat-option value=-1>A zero</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" style="width: 300px;">
                        <mat-label>Categoria</mat-label>
                        <mat-select formControlName="idCategoriaLivelloUno">
                            <mat-option value=0>Tutte</mat-option>
                            <mat-option [value]="cat.id" *ngFor="let cat of categorie">{{ cat.titolo }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button class="btn-success" type="submit">Filtra</button>
                </form>
            </div>
        </div>
        <div class="col-lg-12">
            <ng-template #vc></ng-template>
        </div>    
        <div class="col-lg-12">
            <mat-form-field>
                <input (keyup)="applicaFiltro($event.target.value)" matInput placeholder="Filtro">
            </mat-form-field>
            <div class="mat-elevation-z20">
                <table class="table table-striped table-bordered">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="codArt">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Codice </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.codArt}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="articolo.titolo">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.articolo.titolo}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="qta">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Quantità </mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.qta}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="inserisci">
                            <mat-header-cell *matHeaderCellDef>INSERISCI</mat-header-cell>
                            <mat-cell *matCellDef="let row"><i class="material-icons" (click)="insMovimentoPopup(row)"
                                    style="color: green;">trending_up</i></mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" #paginator></mat-paginator>
                </table>
            </div>
            <button class="btn-success" [useExistingCss]="true" printSectionId="printInventario" ngxPrint>Stampa</button>
            <button class="btn-warning" (click)="riallineaDisponibilita()">Riallinea disponibilità</button>
            <div style="display: none;"  id="printInventario">
                <h1>Stampa magazzino al {{ dataStampa | date:'dd/MM/yyyy hh:mm' }}</h1>
                <table class="table">
                    <thead>
                      <tr style="border-bottom: 2px solid #000;">
                        <th scope="col" style="text-align: left!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">Codice</th>
                        <th scope="col" style="text-align: left!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">Descrizione</th>
                        <th scope="col" style="text-align: left!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">Quantità</th>                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let disp of dataSource.filteredData" style="border-bottom: 2px solid #000;">
                        <th scope="col" style="text-align: left!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">{{ disp.codArt }}</th>
                        <th scope="col" style="text-align: left!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">{{ disp.articolo.titolo }}</th>
                        <th scope="col" style="text-align: left!important;padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;">{{ disp.qta }}</th>
                      </tr>                      
                    </tbody>
                  </table>
            </div>
        </div>
    </div>
</div>