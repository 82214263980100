<div class="">
    <div class="row">        
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-9">
                    <h3 class="page-title">Risultati Ricerca: <small>Trovati {{numeroArticoli}} articoli</small></h3>
                </div>
                <div class="col-lg-3 text-right">
                    <button mat-flat-button color="primary" (click)="apriArticolo('-1')">Nuovo</button>
                </div>
            </div>
        </div>        
        <div class="col-lg-12">
            <mat-form-field>
                <input (keyup)="applicaFiltro($event.target.value)" matInput placeholder="Filtro" [(ngModel)]="filtroTab">
            </mat-form-field>
            <div class="mat-elevation-z20">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="codArt">
                        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="clickSort('codArt')"> CODART </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.codArt}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="titolo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="clickSort('titolo')"> TITOLO </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.titolo}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="categoria.titolo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="clickSort('categoria.titolo')"> CATEGORIA </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.categoria.titolo}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="produttore.ragioneSociale">
                        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="clickSort('produttore.ragioneSociale')"> PRODUTTORE </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.idProduttore>0 ? row.produttore.ragioneSociale : ''}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fornitore.ragioneSociale">
                        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="clickSort('fornitore.ragioneSociale')"> FORNITORE </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.idFornitore>0 ? row.fornitore.ragioneSociale : ''}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="prezzo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="clickSort('prezzo')"> PREZZO </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.prezzo | currency: 'EUR':'symbol'}}</mat-cell>
                    </ng-container>                    
                    <ng-container matColumnDef="modifica">
                        <mat-header-cell *matHeaderCellDef>MODIFICA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="apriArticolo(row.codArt)"
                                style="color: green;">build</i></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="elimina">
                        <mat-header-cell *matHeaderCellDef>ELIMINA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="eliminaArticolo(row.codArt)"
                                style="color: red;">delete</i></mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                    </tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>
    </div>
</div>