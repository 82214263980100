import { FormControl, FormGroup } from '@angular/forms';
import { FiltroArticoliCategorie } from './../../modelli/shared/Classi/Filtri/filtroArticoliCategorie';
import { AppCarrelloService } from 'src/app/services/app/app-carrello.service';
import { AppCarrelloImportoService } from './../../services/app/app-carrello-importo.service';
import { AuthJWTService } from './../../services/auth-jwt.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ArticoliCategorie } from 'src/app/modelli/shared/articoliCategorie';
import { ArticoliCategorieDataService } from 'src/app/services/data/articoli-categorie-data.service';
import { FiltroArticoli } from 'src/app/modelli/shared/Classi/Filtri/filtroArticoli';
import { FunzioniLocalStorage } from 'src/app/modelli/shared/Funzioni/funzioniLocalStorage';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  public isMenuCollapsed = true;
  loggato: boolean;
  username: string;
  importoCarrello: any = [];
  subImportoCarrello: Subscription;
  categorie: ArticoliCategorie[];
  f:FormGroup;

  constructor(public AuthJWT: AuthJWTService, private route: Router, private appCarrelloImporto: AppCarrelloImportoService, 
    private appCarrello: AppCarrelloService, private categorieService:ArticoliCategorieDataService, private funzioniLS: FunzioniLocalStorage) {
    this.importoCarrello = [{ text: 0 }];
    //Sottoscrivo l'observable dell'importo del carrello in maniera che rimanga sempre riallineato con le azioni che vengono eseguite nel carrello
    this.subImportoCarrello = this.appCarrelloImporto.rilevaImporto().subscribe(
      importo => {
        this.importoCarrello = [];
        if (importo) {
          this.importoCarrello.push(importo)
        } else {
          this.importoCarrello = [{ text: 0 }];
        }
      });
  }

  ngOnInit(): void {
    //Vado a rinfrescare l'importo iniziale dal carrello
    this.appCarrelloImporto.impostaImporto();
    
    //Popolo tendina categorie nella navbar
    let filtro = new FiltroArticoliCategorie(1,0)
    this.categorieService.cercaCategorieConFiltri(filtro).subscribe(
      response=>{
        this.categorie=response;      
      }
    )    
    this.f = new FormGroup({
      'keySearchNavBar':new FormControl("")
    })
  }

  get keySearchNavBar(){
    return this.f.get('keySearchNavBar');
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subImportoCarrello.unsubscribe();
  } 

  vaiCarrello() {
    this.route.navigate(['spesa-online/carrello']);
  }

  logout() {
    this.AuthJWT.logout();
    this.appCarrello.eliminaCarrello()
    this.route.navigate(['login']);
  }

  cercaArticoliKeySearch(){       
    let filtro: FiltroArticoli = new FiltroArticoli(0, 0, null, 0, 0, 0, this.keySearchNavBar.value,false);
    this.funzioniLS.setFiltroArticoli(filtro);
    this.keySearchNavBar.setValue("");
    this.vaiSpesaOnline();
    
  }

  vaiCategoria(idCategoria:number){
    let filtro: FiltroArticoli = new FiltroArticoli(0, idCategoria, null, 0, 0, 0, "",false);
    this.funzioniLS.setFiltroArticoli(filtro);
    this.vaiSpesaOnline();
  }

  vaiSpesaOnline(){
    //Verifico la pagina attiva, se è spesa-articoli eseguo una reload della pagina
    if(this.route.url=="/spesa-online"){
      window.location.reload();
    }else{
      this.route.navigate(['spesa-online']);
    }    
  }

}
