<section class="container">
    <app-spesa-breadcrumb></app-spesa-breadcrumb>


    <div style="text-align: -webkit-center">
        <p class="text-uppercase  text-success testo-titolo font-alto mb-4">{{ articolo.titolo }}</p>
        <img class="img-fluid" *ngIf="articolo.foto != null && articolo.foto.length > 0"
            src='https://www.foto.molinolucconi.it/{{articolo.foto[0].nomeFile}}'>
        <img class="img-fluid" *ngIf="articolo.foto != null && articolo.foto.length < 1"
            src='/assets/img/molino-lucconi-logo.jfif'>
        <div class=" col-lg-6 mt-5 " *ngIf="articolo.disponibilita != null">
<!-- 
            <div class="col-lg-12">
                <span class="price-new h3  ">{{ articolo.prezzo | currency: 'EUR':'symbol' }}</span>
            </div>

            <div class="centrare text-center ">
                <button mat-raised-button class="w-100 button centrare bg-success text-white my-2" (click)="aggiungiAlCarrello(articolo)"  *ngIf="articolo.disponibilita.qta >0">
                    Aggiungi al carrello
                </button>
            </div>
            <div class="input-group">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-success btn-number color-white" (click)="rimuovi1()">
                        <i class=" fa fa-minus mx-2"></i>
                    </button>
                </span>
                <input type="text" class="form-control input-number text-center bg-white border-white" value="1" min="1"
                    disabled value='{{ quantita }}'>
                <span class="input-group-btn">
                    <button type="button" class="btn btn-success btn-number" (click)="aggiungi1()">
                        <i class="fa fa-plus mx-2"></i>
                    </button>
                </span>
            </div> -->
        </div>
        <!-- <p class="text-center testo-basso" *ngIf="articolo.disponibilita != null">Disponibilià: {{
            articolo.disponibilita.datiDisponibilita.dicitura }}</p>
        <p class="text-center testo-basso" *ngIf="articolo.disponibilita == null">Disponibilià: esaurita</p> -->
        
        <div *ngIf="articolo.soloRitiro" class="bg-warning text-white text-center testo-basso "> <mat-icon class="text-white mt-2"> store_mall_directory</mat-icon><br>DISPONIBILE SOLO PER IL RITIRO IN NEGOZIO</div>
    </div>

    <p class="font-medio sottile interlinea my-5">{{ articolo.descrizione }}</p>
    <div class=" mat-elevation-z4 mb-5 p-4 text-left">
        <div class="row">
            <mat-icon class=" font-medio mr-5 " style="font-size: 40px">person_pin</mat-icon>
            <p class="font-medio sottile mt-3 "> {{articolo.produttore != null ? articolo.produttore.ragioneSociale :
                ''}} </p>
        </div>
        <hr>
        <p class="font-medio sottile mt-2 interlinea p-3 ">{{ articolo.ingredienti }} </p>

    </div>
    <p class="mb-4">
        <mat-chip-list aria-label="Fish selection">
            <mat-chip *ngFor="let caratteristica of articolo.caratteristiche" class="viola" color="accent" selected> {{
                caratteristica.caratteristica.titolo }} </mat-chip>
        </mat-chip-list>
    </p>


    <div *ngIf="articolo.foto.length>1" class="mb-5 row">
        <div class="col-lg-4 mx-1 my-2 " *ngFor="let f of articolo.foto;index as i">
            <img class="img-fluid zoom" *ngIf="articolo.foto != null && articolo.foto.length > 0"
                src='https://www.foto.molinolucconi.it/{{articolo.foto[i].nomeFile}}'>
            <img class="img-fluid zoom" *ngIf="articolo.foto != null && articolo.foto.length < 1"
                src='/assets/img/molino-lucconi-logo.jfif'>
        </div>
    </div>

</section>
<div class=" container text-uppercase text-left text-viola bold testo-titolo font-alto mb-5">
    Articoli correlati
</div>
<app-articoli-carosello [articoli]="articoli" [visualizzazione]="1"></app-articoli-carosello>