import { Articoli } from './articoli';

export class MagazzinoMovimento {
    constructor(
        public id:number,
        public codArt:string,
        public qta:number,
        public data:Date,
        public timeStamp:Date,
        public userName:string,
        public causale:string,
        public nota:string,
        public idDettaglioOrdineOnline:number,
        public articolo:Articoli
    ) { }
  }
