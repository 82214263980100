import { AppImpostazioni } from './../../modelli/shared/appImpostazioni';
import { backServerUrlAppImpostazioni } from './../../modelli/app.contstant';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppImpostazioniDataService {

  constructor(private http:HttpClient) { }

  lista(){
    return this.http.get<AppImpostazioni>(backServerUrlAppImpostazioni + "/lista");
  }
  
}
