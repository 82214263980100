import { Mail } from './../../modelli/shared/mail';
import { ContattiDataService } from './../../services/data/contatti-data.service';
import { Contatti } from './../../modelli/shared/contatti';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { EmailDataService } from 'src/app/services/data/email-data.service';

@Component({
  selector: 'app-contattaci',
  templateUrl: './contattaci.component.html',
  styleUrls: ['./contattaci.component.css']
})
export class ContattaciComponent implements OnInit {
  f: FormGroup;
  contatto: Contatti;
  apiMsg: ApiMsg;
  erroreMsg: string = "";
  confermaMsg: string = "";

  constructor(private contattiService: ContattiDataService, private emailDataService: EmailDataService) { }

  ngOnInit(): void {
    this.f = new FormGroup({
      'nominativo': new FormControl('', [Validators.required, Validators.maxLength(100)]),
      'mail': new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]),
      'messaggio': new FormControl('', [Validators.required, Validators.maxLength(1000)])
    })
  }

  get mail() {
    return this.f.get('mail');
  }

  get nominativo() {
    return this.f.get('nominativo');
  }

  get messaggio() {
    return this.f.get('messaggio');
  }

  salva(dati: FormGroup) {
    this.confermaMsg = "";
    this.erroreMsg = "";
    this.contatto = dati.value;

    this.contattiService.inviaRichiestaContatto(this.contatto).subscribe(
      response => {
        let mail: Mail = new Mail(this.contatto.nominativo, this.contatto.mail, 'info@molinolucconi.it', 'Nuovo contatto da Molino Lucconi', this.testoMail(this.contatto), "Grazie " + this.contatto.nominativo + ", richiesta inviata correttamente.")
        this.emailDataService.InviaMail(mail).subscribe(
          response => {
            this.apiMsg = response;
            this.confermaMsg = this.apiMsg.messaggio;
          },
          error => {
            this.apiMsg = error.error;
            this.erroreMsg = this.apiMsg.messaggio;
          });
        this.f.reset();
      }, error => {
        this.apiMsg = error.error;
        this.erroreMsg = this.apiMsg.messaggio;
      }
    )
  }

  testoMail(contatto: Contatti) {
    let tmpTesto: string
    tmpTesto = "NOMINATIVO: " + contatto.nominativo + "<br>";
    tmpTesto += "MAIL: " + contatto.mail + "<br>";
    tmpTesto += "MESSAGGIO: " + contatto.messaggio + "<br>";
    return tmpTesto;
  }
}
