import { DialogClass } from './../../modelli/shared/Classi/Componenti/dialogClass';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {  

  //Ne costruttiore stiamo dicendo di accettare qualsiasi cosa che poi va trattata come dati della dialog
  //Magari qui creiamo una classe per il passaggio dati dai componenti al componente dialog
  constructor(@Inject(MAT_DIALOG_DATA) public data:DialogClass) { }

  ngOnInit(): void { 

  }

}
