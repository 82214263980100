export class Mail {
    constructor(
        public nominativoMittente:string,
        public mittenteMail: string,
        public destinatarioMail:string,
        public oggettoMail:string,
        public testoMail:string,
        public messaggioFrontEnd:string
    ) { }
  }
