import { ArticoliCaratteristicheDataService } from './../../services/data/articoli-caratteristiche-data.service';
import { ArticoliCaratteristiche } from './../../modelli/shared/articoliCaratteristiche';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-articoli-caratteristica',
  templateUrl: './articoli-caratteristica.component.html',
  styleUrls: ['./articoli-caratteristica.component.css']
})
export class ArticoliCaratteristicaComponent implements OnInit {
  f:FormGroup;
  tmpid:number=0;
  caratteristica:ArticoliCaratteristiche;
  
  apiMsg: ApiMsg;
  erroreMsg:string = "";
  confermaMsg: string = "";
  IsModifica: boolean = false;

  constructor(private route:ActivatedRoute,private caratteristicheService: ArticoliCaratteristicheDataService, private router:Router) { }

  ngOnInit(): void {
    this.caratteristica=new ArticoliCaratteristiche(0,"",null);
    this.tmpid=this.route.snapshot.params['id'];
    this.f = new FormGroup({
      'titolo': new FormControl('',[Validators.required,Validators.maxLength(100)])
    })
    if(this.tmpid>0){
      this.IsModifica=true;
      this.caratteristicheService.cercaCaratteristicaById(this.tmpid).subscribe(
        response=>{
          this.caratteristica=response;
          this.f.controls['titolo'].setValue(this.caratteristica.titolo);
        },
        error=>{
          this.erroreMsg=error.error;
        }
      )
    }else{
      this.IsModifica=false;
      this.caratteristica.id=0;      
    }
  }

  get titolo(){
    return this.f.get('titolo');
  }

  salva(dati:FormGroup){
    this.confermaMsg="";
    this.erroreMsg="";
    this.caratteristica=dati.value

    if(this.IsModifica){
      this.caratteristica.id=this.tmpid;
      this.caratteristicheService.modificaCaratteristica(this.caratteristica).subscribe(
        response=>{
          this.apiMsg=response;
          this.confermaMsg=this.apiMsg.messaggio;
        },
        error=>{
          this.apiMsg=error.error;
          this.erroreMsg=this.apiMsg.messaggio;
        }
      )
    }
    else{
      this.caratteristicheService.inserisciCaratteristica(this.caratteristica).subscribe(
        response=>{
          this.apiMsg=response;
          this.confermaMsg=this.apiMsg.messaggio;
        },
        error=>{
          this.apiMsg=error.error;
          this.erroreMsg=this.apiMsg.messaggio;
        }
      )
    }
  }

  chiudi(){
    this.router.navigate(['amm/caratteristiche']);
  }


}
