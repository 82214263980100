<div class="">
    <div class="row">
        <div class="col-lg-12 text-center">
            <mat-button-toggle-group #toggleGroup="matButtonToggleGroup">
                <mat-button-toggle value="1" (change)="selectionChanged($event)">Livello 1</mat-button-toggle>
                <mat-button-toggle value="2" (change)="selectionChanged($event)">Livello 2</mat-button-toggle>
                <mat-button-toggle value="3" (change)="selectionChanged($event)">Livello 3</mat-button-toggle>
              </mat-button-toggle-group>
              {{ toggleGroup.Value }}
        </div>
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-9">
                    <h3 class="page-title">Risultati Ricerca: <small>Trovati {{numeroCategorie}} categorie</small></h3>
                </div>
                <div class="col-lg-3 text-right">
                    <button mat-flat-button color="primary" (click)="apriCategoria(0)">Nuovo</button>
                </div>
            </div>
        </div>        
        <div class="col-lg-12">
            <mat-form-field>
                <input (keyup)="applicaFiltro($event.target.value)" matInput placeholder="Filtro">
            </mat-form-field>
            <div class="mat-elevation-z20">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.id}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="livello">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Livello </mat-header-cell>
                        <mat-cell *matCellDef="let row"> Liv. {{row.livello}}</mat-cell>
                    </ng-container>                    
                    <ng-container matColumnDef="titolo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> TITOLO </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.titolo}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="descrizione">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> DESCRIZIONE </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.descrizione}} </mat-cell>
                    </ng-container>        
                    <ng-container matColumnDef="aggiungi">
                        <mat-header-cell *matHeaderCellDef>AGGIUNGI FIGLIO</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons"
                                (click)="nuovaConIdPadre(row.id)" style="color: green;">add_task</i></mat-cell>
                    </ng-container>            
                    <ng-container matColumnDef="modifica">
                        <mat-header-cell *matHeaderCellDef>MODIFICA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons"
                                (click)="apriCategoria(row.id)" style="color: green;">build</i></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="elimina">
                        <mat-header-cell *matHeaderCellDef>ELIMINA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="eliminaCategoria(row.id)"
                                style="color: red;">delete</i></mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                    </tr>
                </table>
                <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>
    </div>
</div>