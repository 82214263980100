import { ApiMsg } from './../../modelli/shared/apiMsg';
import { ArticoliCaratteristiche } from './../../modelli/shared/articoliCaratteristiche';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backServerUrlArticoliCaratteristiche } from 'src/app/modelli/app.contstant';

@Injectable({
  providedIn: 'root'
})
export class ArticoliCaratteristicheDataService {

  constructor(private http:HttpClient) { }

  elencoCaratteristiche(){
    return this.http.get<ArticoliCaratteristiche[]>(backServerUrlArticoliCaratteristiche + "/lista");
  }
  cercaCaratteristicaById(id:number){
    return this.http.get<ArticoliCaratteristiche>(backServerUrlArticoliCaratteristiche + "/cerca/id/" + id );
  }

  cercaCaratteristicaByTitolo(titolo:string){
    return this.http.get<ArticoliCaratteristiche>(backServerUrlArticoliCaratteristiche + "/cerca/titolo/" + titolo );
  }

  inserisciCaratteristica(caratteristica:ArticoliCaratteristiche){
    return this.http.post<ApiMsg>(backServerUrlArticoliCaratteristiche + "/inserisci",caratteristica);
  }

  modificaCaratteristica(caratteristica:ArticoliCaratteristiche){
    return this.http.put<ApiMsg>(backServerUrlArticoliCaratteristiche + "/modifica",caratteristica);
  }

  eliminaCaratteristica(id:number){
    return this.http.delete<ApiMsg>(backServerUrlArticoliCaratteristiche + "/elimina/" + id)
  }
}
