import { FiltroArticoli } from './../../modelli/shared/Classi/Filtri/filtroArticoli';
import { ArticoliCategorieDataService } from 'src/app/services/data/articoli-categorie-data.service';
import { ArticoliCategorie } from './../../modelli/shared/articoliCategorie';
import { ArticoliDataService } from './../../services/data/articoli-data.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbCategorie } from 'src/app/modelli/shared/Classi/breadcrumbCategorie';
import { ActivatedRoute, Router } from '@angular/router';
import { FunzioniLocalStorage } from 'src/app/modelli/shared/Funzioni/funzioniLocalStorage';

@Component({
  selector: 'app-spesa-breadcrumb',
  templateUrl: './spesa-breadcrumb.component.html',
  styleUrls: ['./spesa-breadcrumb.component.css']
})
export class SpesaBreadcrumbComponent implements OnInit, OnDestroy {
  categorie: BreadcrumbCategorie[];
  tmpDicituraUrl1: string;
  tmpDicituraUrl2: string;
  // tmpDicituraUrl3: string;
  tmpDicituraArticolo: string;
  tmpDicituraUrlUltimo: string;

  constructor(private route: ActivatedRoute, private articoliDataService: ArticoliDataService, private articoliCategorieService: ArticoliCategorieDataService,private router: Router,private funzioniLS: FunzioniLocalStorage) {
    //Qua creo un osservabile che osserva i param dell'url, se questo cambia lancia la funzione all'interno
    this.route.paramMap.subscribe(params => {
      this.valorizzaLink();
    });
  }

  ngOnInit(): void {

  }

  valorizzaLink() {
    this.tmpDicituraUrl1 = this.route.snapshot.params['categoria1'];
    this.tmpDicituraUrl2 = this.route.snapshot.params['categoria2'];
    // this.tmpDicituraUrl3 = this.route.snapshot.params['categoria3'];
    this.tmpDicituraArticolo = this.route.snapshot.params['articolo'];

    this.categorie = [];
    //Se c'è l'articolo cerco i link delle categorie padri
    if (this.tmpDicituraArticolo) {
      this.articoliDataService.cercaArticoloByDicituraUrl(this.tmpDicituraArticolo).subscribe(
        response => {
          //Valorizzo l'array con l'articolo
          this.categorie.push(new BreadcrumbCategorie(response.titolo, response.dicituraUrl, 4,response.idCategoria))
          //Valorizzo l'array con le categorie
          if (response.idCategoria > 0) {
            this.categorie.push(new BreadcrumbCategorie(response.categoria.titolo, response.categoria.dicituraUrl, response.categoria.livello,response.categoria.id));
            if (response.categoria.idPadre > 0) {
              this.categorie.push(new BreadcrumbCategorie(response.categoria.padre.titolo, response.categoria.padre.dicituraUrl, response.categoria.padre.livello,response.categoria.padre.id))
              if (response.categoria.padre.idPadre > 0) {
                this.categorie.push(new BreadcrumbCategorie(response.categoria.padre.padre.titolo, response.categoria.padre.padre.dicituraUrl, response.categoria.padre.padre.livello,response.categoria.padre.padre.id))
              }
            }
          }
          //Ordino l'arra in base al livello, dal più basso al più alto, il 4 sono gli articoli
          this.categorie = this.ordinaCategorie();
        }
      )
    } else {
      //Verifico qual'è la categoria più piccola valorizzata
      let dicituraUrlUltima: string = ""
      // if (this.tmpDicituraUrl3) { dicituraUrlUltima = this.tmpDicituraUrl3 } else {
      //   if (this.tmpDicituraUrl2) { dicituraUrlUltima = this.tmpDicituraUrl2 } else {
      //     dicituraUrlUltima = this.tmpDicituraUrl1
      //   }
      // }
      this.articoliCategorieService.cercaByDicituraUrl(dicituraUrlUltima).subscribe(
        response=>{         
          this.categorie.push(new BreadcrumbCategorie(response.titolo,response.dicituraUrl,response.livello,response.id))
          if (response.idPadre>0){
            this.categorie.push(new BreadcrumbCategorie(response.padre.titolo,response.padre.dicituraUrl,response.padre.livello,response.id))
            if(response.padre.idPadre>0){
              this.categorie.push(new BreadcrumbCategorie(response.padre.padre.titolo,response.padre.padre.dicituraUrl,response.padre.padre.livello,response.id))
            }
          }
          //Ordino l'arra in base al livello, dal più basso al più alto, il 4 sono gli articoli                    
          this.categorie = this.ordinaCategorie();         
        }
      )
    }


  }

  ordinaCategorie() {       
    return this.categorie.sort((a, b) => (a.livello > b.livello) ? 1 : -1);    
    
  }



  vaiCategoria(livello: number) {    
    let percorso=livello;
    //In base al livello valorizzo il filtro 
    let filtro:FiltroArticoli= this.funzioniLS.getFiltroArticoli();    
    //Se clicco sull'ultimo link non faccio niente
    // if (livello == this.categorie.length){
    //   return;
    // }
    // if(livello==1){
    //   this.router.navigate(['spesa-online/categoria',this.categorie[0].link]);
    // }else if (livello==2){

    //   this.router.navigate(['spesa-online/categoria',this.categorie[0].link,this.categorie[1].link]);    
    // }
    if (livello==1){
      filtro.idCategoriaArticoliFigli=this.categorie[0].id;
    };
    if (livello==2){
      filtro.idCategoriaArticoliFigli=this.categorie[1].id;
    }
    
    
    this.funzioniLS.setFiltroArticoli(filtro);
    this.router.navigate(['spesa-online'])
  }

  ngOnDestroy() {

  }

}
