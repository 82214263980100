import { Component, OnInit } from '@angular/core';
import { FunzioniLayout } from 'src/app/modelli/shared/Funzioni/funzioniLayout';

@Component({
  selector: 'app-condizioni-di-vendita',
  templateUrl: './condizioni-di-vendita.component.html',
  styleUrls: ['./condizioni-di-vendita.component.css']
})
export class CondizioniDiVenditaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

  downloadMyFile(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'assets/doc/Lettera-di-recesso.pdf');
    link.setAttribute('download', `assets/doc/Lettera-di-recesso.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();

}

}
