<section class="container">
    <mat-card class="mat-elevation-z20">
        <mat-card-title class="text-center">            
            <label>Utente: {{ username }}</label>
        </mat-card-title>
        <mat-card-content>
            <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salva(f)">
                <p>
                    <mat-form-field>
                        <mat-label>Nome</mat-label>
                        <input matInput id="nome" formControlName="nome">
                        <mat-hint *ngIf="nome.touched && nome.errors?.required" style="color: red;">Il nome è obbligatorio</mat-hint>
                      </mat-form-field>
                </p>
                <p>
                    <mat-form-field>
                        <mat-label>Cognome</mat-label>
                        <input matInput id="cognome" formControlName="cognome">
                        <mat-hint *ngIf="cognome.touched && cognome.errors?.required" style="color: red;">Il cognome è obbligatorio</mat-hint>
                      </mat-form-field>
                </p>
                <p>
                    <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit">
                        <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
                    </button>
                    <button mat-flat-button color="warn" id=btnChiudi type="submit" class="pull-right"
                        (click)="chiudi()">
                        <i class="fa fa-times" aria-hidden="true"></i> Chiudi
                    </button>
                </p>
                <p class="alert alert-success" *ngIf="confermaMsg">
                    {{confermaMsg}}
                </p>
                <p class="alert alert-danger" *ngIf="erroreMsg">
                    {{erroreMsg}}
                </p>
            </form>
        </mat-card-content>
    </mat-card>
</section>

