<div class="col-lg-12 text-center ">
  <img class="img-fluid mat-elevation-z20 mb-5" src="/assets/img/blog/molino-lucconi-miele-009.jpeg">
</div>
<div class="text-center mt-5">
 
  <h3 class=" text-uppercase titoli-blog text-success mb-5 mt-4">Miele LUAP - proprietà e origini<br><br></h3>
  <hr>
  <h4 class=" text-uppercase text-success mb-5 sottotitoli-blog">Molino Lucconi</h4>
</div>
<!-- <div class="text-center">
  <iframe src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.molinolucconi.it%2Fmiele&layout=button_count&size=large&width=111&height=28&appId" width="111" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</div> -->
<div class="row m-0 mb-5 ">
  <div class="col-lg-2">
    <img class="img-fluid" src="/assets/img/blog/molino-lucconi-miele-004.JPG">
  </div>
  <div class=" col-lg-8  mat-elevation-z20 p-4 mt-5  ">
    <div class="container">
      <p class="testo-blog lead">Il miele è un <b>alimento naturale</b> che non richiede delle trasformazioni o aggiunte
        di altre sostanze per essere conservato.<br> Lo possiamo gustare durante la merenda o la colazione, spalmato in
        giuste quantità, su una fetta di pane o con il formaggio.<br><br>Può addolcire una tazza di latte e essere usato
        come medicinale se abbiamo il <b>raffreddore o un po' di influenza</b>.<br> Il miele viene impiagato in cucina
        per preparare alcuni piatti di carne o pesce ma soprattutto per alcuni dolci tra cui il torrone. </p>
      <p class="testo-blog lead">Da sempre considerato un alimento dalle proprietà eccezionali, sin dall’antichità il
        miele viene utilizzato per la cura di <b>molteplici disturbi dell’organismo</b>. Contiene glucosio e fruttosio,
        due zuccheri dalle calorie naturali, è un prodotto completo per eccellenza ed è ottimo per chi desidera seguire
        un’<b>alimentazione sana</b> e prendersi cura del proprio corpo, in maniera naturale.</p>
    </div>
  </div>
  <div class="col-lg-2 ">
    <img class="img-fluid" src="/assets/img/blog/molino-lucconi-miele-005.JPG">

  </div>
</div>

<div class="row centrare my-5">
   
  <div class="col-sm-12 col-md-6 col-lg-2 align-self-stretch "   *ngFor="let articolo of articoli">
    <app-articoli-card [articolo]="articolo" [visualizzazione]="2"></app-articoli-card>
  </div>

</div>

<div class="row m-0 mb-5 ">
  <div class="col-lg-2">
    <img class="img-fluid" src="/assets/img/blog/molino-lucconi-miele-006.JPG">
    <img class="img-fluid mt-3" src="/assets/img/blog/molino-lucconi-miele-001.jpg">
    <img class="img-fluid mt-3" src="/assets/img/blog/molino-lucconi-miele-008.jpg">
  </div>
  <div class=" col-lg-8  mat-elevation-z20 p-4 mt-5  ">
    <div class="container">
      <p class="testo-blog lead">Il consumo di miele è indicato non solo per il suo gusto inconfondibile ma anche perché
        è ottimo per la salute.<br> Il miele combatte bene i mali di stagione, che arrivano con il primo freddo. Inoltre
        esso regala energia e vitalità.<br> <b>Vediamo insieme le sue proprietà curative</b>.</p>
      <h3 class="  sottotitoli-blog text-success">Proprietà curative del miele</h3>


      <section class="container mt-5">
        <div class="row mx-0">
          <div class="col-lg-4 px-0 g-mb-30">
            <!-- Article -->
            <article class="u-block-hover  text-center">
              <figure class="g-overflow-hidden">
                <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
                  src="/assets/img/blog/molino-lucconi-proprieta-miele-001.png" alt="Proprietà miele Molino Lucconi">
              </figure>

              <div class="text-center">
                <h3 class=" text-uppercase">
                  <p class="lead"><b>Antinfiammatorio</b></p>
                </h3>
                <p class="p-3 lead testo-blog">Il miele è un ottimo antinfiammatorio per la gola, seda l’eccitazione,
                  allevia l’insonnia e disintossica il fegato. Il miele chiaro è indicato per l’estate, mentre quello
                  scuro, ricco di sali minerali, è ideale per l’inverno.</p>

              </div>
            </article>
            <!-- End Article -->
          </div>

          <div class="col-lg-4 px-0 g-mb-30">
            <!-- Article -->
            <article class="u-block-hover  text-center">
              <figure class="g-overflow-hidden">
                <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
                  src="/assets/img/blog/molino-lucconi-proprieta-miele-002.png" alt="Proprietà miele Molino Lucconi">
              </figure>

              <div class="text-center">
                <h3 class=" text-uppercase">
                  <p class="lead"><b>Fa bene alla salute!</b></p>
                </h3>
                <p class="p-3 lead testo-blog">Il miele è ricco di polifenoli che aiutano a combattere l’invecchiamento
                  e a prevenire le malattie.</p>

              </div>
            </article>
            <!-- End Article -->
          </div>

          <div class="col-lg-4 px-0 g-mb-30">
            <!-- Article -->
            <article class="u-block-hover text-center">
              <figure class="g-overflow-hidden">
                <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
                  src="/assets/img/blog/molino-lucconi-proprieta-miele-003.png" alt="Proprietà miele Molino Lucconi">
              </figure>

              <div class="text-center">
                <h3 class=" text-uppercase">
                  <p class="lead"><b>Miele di castagno</b></p>
                </h3>
                <p class="p-3 lead testo-blog">Il miele di castagno è un buon ricostituente che stimola la circolazione
                  sanguigna e combatte l’anemia.</p>

              </div>
            </article>
            <!-- End Article -->
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-lg-4 px-0 g-mb-30">
            <!-- Article -->
            <article class="u-block-hover  text-center">
              <figure class="g-overflow-hidden">
                <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
                  src="/assets/img/blog/molino-lucconi-proprieta-miele-004.png" alt="Proprietà miele Molino Lucconi">
              </figure>

              <div class="text-center">
                <h3 class=" text-uppercase">
                  <p class="lead"><b>Miele di millefiori</b></p>
                </h3>
                <p class="p-3 lead testo-blog">Il miele di millefiori funziona come un vaccino e aiuta a combattere le
                  allergie.</p>

              </div>
            </article>
            <!-- End Article -->
          </div>

          <div class="col-lg-4 px-0 g-mb-30">
            <!-- Article -->
            <article class="u-block-hover  text-center">
              <figure class="g-overflow-hidden">
                <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
                  src="/assets/img/blog/molino-lucconi-proprieta-miele-005.png" alt="Proprietà miele Molino Lucconi">
              </figure>

              <div class="text-center">
                <h3 class=" text-uppercase">
                  <p class="lead"><b>Fa bene all'intestino e ai denti!</b></p>
                </h3>
                <p class="p-3 lead testo-blog">Il miele non fa ingrassare e non caria i denti, anzi contrasta la
                  formazione di tartaro e favorisce il transito intestinale.</p>

              </div>
            </article>
            <!-- End Article -->
          </div>

          <div class="col-lg-4 px-0 g-mb-30">
            <!-- Article -->
            <article class="u-block-hover text-center">
              <figure class="g-overflow-hidden">
                <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
                  src="/assets/img/blog/molino-lucconi-proprieta-miele-006.png" alt="Proprietà miele Molino Lucconi">
              </figure>

              <div class="text-center">
                <h3 class=" text-uppercase">
                  <p class="lead "> <b>Alleato contro il colesterolo alto</b></p>
                </h3>
                <p class="p-3 lead testo-blog">Il miele non contiene colesterolo e per tale ragione aiuta a controllare
                  il livello di quest’ultimo nel corpo.</p>

              </div>
            </article>
            <!-- End Article -->
          </div>
          <div class="col-lg-4 px-0 g-mb-30">
            <!-- Article -->
            <article class="u-block-hover text-center">
              <figure class="g-overflow-hidden">
                <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
                  src="/assets/img/blog/molino-lucconi-proprieta-miele-007.png" alt="Proprietà miele Molino Lucconi">
              </figure>

              <div class="text-center">
                <h3 class=" text-uppercase">
                  <p class="lead "> <b>Zuccheri buoni</b></p>
                </h3>
                <p class="p-3 lead testo-blog">Il miele ha un indice glicemico equilibrato e quindi tiene sotto
                  controllo il livello di insulina, oltre a favorire una buona digestione.</p>

              </div>
            </article>
            <!-- End Article -->
          </div>
          <div class="col-lg-4 px-0 g-mb-30">
            <!-- Article -->
            <article class="u-block-hover text-center">
              <figure class="g-overflow-hidden">
                <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
                  src="/assets/img/blog/molino-lucconi-proprieta-miele-008.png" alt="Proprietà miele Molino Lucconi">
              </figure>

              <div class="text-center">
                <h3 class=" text-uppercase">
                  <p class="lead "> <b>Super Food!</b></p>
                </h3>
                <p class="p-3 lead testo-blog">Il miele accelera il metabolismo e aiuta a bruciare i grassi.</p>

              </div>
            </article>
            <!-- End Article -->
          </div>
          <div class="col-lg-4 px-0 g-mb-30">
            <!-- Article -->
            <article class="u-block-hover text-center">
              <figure class="g-overflow-hidden">
                <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
                  src="/assets/img/blog/molino-lucconi-proprieta-miele-009.png" alt="Proprietà miele Molino Lucconi">
              </figure>

              <div class="text-center">
                <h3 class=" text-uppercase">
                  <p class="lead "> <b>Antibiotico</b></p>
                </h3>
                <p class="p-3 lead testo-blog">Il miele può essere utilizzato per curare piccole ustioni e abrasioni
                  perché si rivela un eccellente antibiotico.</p>

              </div>
            </article>
            <!-- End Article -->
          </div>
        </div>
      </section>



    </div>
  </div>
  <div class="col-lg-2 ">
    <img class="img-fluid mt-3" src="/assets/img/blog/molino-lucconi-miele-007.JPG">
    <img class="img-fluid mt-3" src="/assets/img/blog/molino-lucconi-miele-002.jpg">
    <img class="img-fluid mt-3" src="/assets/img/blog/molino-lucconi-miele-003.jpg">
  </div>
</div>