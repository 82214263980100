<section class="container">
    <div class="row">
        <div class="col-lg-12">
            <h1>File upload</h1>
            <input type="file" [(ngModel)]="fileSelezionato" (change)="onFileSelected($event)">
            <br>
            <mat-checkbox [disabled]="primarioInserito" class="example-margin" [(ngModel)]="chkPrimario">Primario</mat-checkbox>
            <br>
            <button type="button" (click)="carica()">Carica</button>            
        </div>
        <div class="col-lg-12" style="margin-top: 50px;">
            <div class="row text-center text-lg-left">

                <div class="col-lg-3 col-md-4 col-6" *ngFor="let foto of articoliFoto">
                    <h3>Posizione {{foto.posizione}}</h3>
                        <img class="img-fluid img-thumbnail" src="https://www.foto.molinolucconi.it/{{ foto.nomeFile }}" alt="">                            
                            <button type="button" (click)="eliminaFoto(foto.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</section>
