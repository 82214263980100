import { CarrelliTestata } from './../../modelli/shared/carrelliTestata';
import { AppCarrelloImportoService } from './app-carrello-importo.service';
import { catchError, map } from 'rxjs/operators';
import { CarrellidettaglioDataService } from './../data/carrellidettaglio-data.service';
import { AuthJWTService } from './../auth-jwt.service';
import { CarrelliDettaglio } from './../../modelli/shared/carrelliDettaglio';
import { FunzioniLocalStorage } from './../../modelli/shared/Funzioni/funzioniLocalStorage';
import { CarrellitestataDataService } from './../data/carrellitestata-data.service';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppCarrelloService {
  constructor(private carrelloTService: CarrellitestataDataService, private carrelloDService: CarrellidettaglioDataService, private funzioniLS: FunzioniLocalStorage,
    private auth: AuthJWTService, private appCarrelloIMporto: AppCarrelloImportoService) { }

  //Funzione che crea il carrello
  aggiungiRigaCarrello(dettaglio: CarrelliDettaglio) {
    //Verifico se è presente un idCart nella LocalStorage
    let idCart: string = this.funzioniLS.getIdCart()
    if (idCart == null) {
      let username = this.auth.loggedUser();
      let testata: CarrelliTestata = new CarrelliTestata("", null, username, null);
      testata.carrelloDettagli = [];
      testata.carrelloDettagli.push(dettaglio);
      this.carrelloTService.inserisciCarrelloTestata(testata).subscribe(
        response => {
          testata = response;
          this.funzioniLS.setIdCart(testata.id);
          this.appCarrelloIMporto.impostaImporto();
        }
      )
    } else {
      this.carrelloDService.inserisciCarrelloDettaglio(dettaglio).subscribe(
        response => {
          this.appCarrelloIMporto.impostaImporto();
        }
      )
    }
  }

  //Funzione che aggiorna la quantità di un dettaglio
  aggiornaQtaDettaglio(id: number, qta: number): Observable<any> {

    return this.carrelloDService.aggiornaQuantitaRiga(id, qta).pipe(
      map((response) => {
        this.appCarrelloIMporto.impostaImporto();
      }))

  }  

  associaCarrelloUtente(): Observable<any> {
    let carrelloT: CarrelliTestata = new CarrelliTestata(this.funzioniLS.getIdCart(), null, this.auth.loggedUser(), null)
    return this.carrelloTService.inserisciCarrelloTestata(carrelloT).pipe(
      map((response) => {
        this.funzioniLS.setIdCart(response.id)
        this.appCarrelloIMporto.impostaImporto();
      })
    )
  }

  //Funzione che elimina un dettaglio del carrello
  eliminaDettaglio(id: number): Observable<any> {
    return this.carrelloDService.eliminaCarrelloDettaglio(id).pipe(
      map((response) => {
        this.appCarrelloIMporto.impostaImporto();
      }
      ))
  }

  //Funzione che elimina il carrello dalla localstorage e pulisce l'ossrvabile del carrello
  eliminaCarrello() {
    this.funzioniLS.removeIdCart();
    this.appCarrelloIMporto.pulisciImporto();
  }



}
