import { SEOService } from './services/app/seo.service';
import { FunzioniLayout } from './modelli/shared/Funzioni/funzioniLayout';
import { LoadingService } from './services/app/loading.service';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Component, HostListener } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';


declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Molino Lucconi';
  loadingObs: Subscription;
  loadingVisible: boolean;

  isShow: boolean;
  topPosToStartShowing = 100;

  //Serve per gestire la freccia che riporta in su la pagina
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;


    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }

  }

  //Qui metto la logica di Analitycs perchè app component è sempre attiva e potrà sempre osservare l'url che cambia
  constructor(private router: Router, private appLoading: LoadingService, private funzioniLayout: FunzioniLayout,private seoService:SEOService,private activatedRoute:ActivatedRoute) {
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-0WJQC1RN17', { 'page_path': event.urlAfterRedirects });
      this.funzioniLayout.gotoTopPage();
    })

    //Abbonamento all'observable che fa apparire e scomparire la gif di loading
    this.loadingVisible = false;
    this.loadingObs = this.appLoading.rilevaStato().subscribe(
      stato => {
        this.loadingVisible = stato;
      }
    )
  }

  ngOnInit(){
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
     )
     .subscribe((event) => {
       this.seoService.updateTitle(event['title']);       
       this.seoService.updateOgUrl(event['ogUrl']);
       this.seoService.updateOgImage(event['ogImage']);       
       this.seoService.updateOgTitle(event['title']);
       this.seoService.updateOgDescription(event['description']);
       //Updating Description tag dynamically with title
       this.seoService.updateDescription(event['description'])       
       
     }); 
  }

  //Funzione che riporta su la pagina
  gotoTop() {
    this.funzioniLayout.gotoTopPage();
  }
}
