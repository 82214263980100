import { FunzioniLayout } from './../../modelli/shared/Funzioni/funzioniLayout';
import { ArticoliDataService } from './../../services/data/articoli-data.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { ArticoliCategorie } from 'src/app/modelli/shared/articoliCategorie';
import { Fornitori } from 'src/app/modelli/shared/fornitori';
import { ArticoliCaratteristiche } from 'src/app/modelli/shared/articoliCaratteristiche';
import { ArticoliCategorieDataService } from 'src/app/services/data/articoli-categorie-data.service';
import { FornitoriDataService } from 'src/app/services/data/fornitori-data.service';
import { ArticoliCaratteristicheDataService } from 'src/app/services/data/articoli-caratteristiche-data.service';
import { FiltroArticoli } from 'src/app/modelli/shared/Classi/Filtri/filtroArticoli';
import { LoadingService } from 'src/app/services/app/loading.service';
import { FunzioniLocalStorage } from 'src/app/modelli/shared/Funzioni/funzioniLocalStorage';


@Component({
  selector: 'app-spesa-articoli',
  templateUrl: './spesa-articoli.component.html',
  styleUrls: ['./spesa-articoli.component.css']
})
export class SpesaArticoliComponent implements OnInit, OnDestroy {
  articoli: Articoli[];
  numeroArticoli: number = 0

  f: FormGroup;
  categoriePadri: ArticoliCategorie[];
  categorieLivelloDue: ArticoliCategorie[];
  produttori: Fornitori[];
  caratteristiche: ArticoliCaratteristiche[];

  ordinamentoArticoli: number = 2;
  labelFiltro: string;
  filtro: FiltroArticoli;
  visualizzaDisponibili: boolean;

  pannelloFiltroAperto: boolean = false;
  tmpChipsFiltri: string[] = [];

  primaVisualizzazione:boolean=true;

  public currentPage:number=1;  
  public sizeArticoli:number;

  constructor(private articoliService: ArticoliDataService, private router: Router, private categorieService: ArticoliCategorieDataService, private produttoriService: FornitoriDataService,
    private caratteristicheService: ArticoliCaratteristicheDataService, private appLoading: LoadingService, private funzioniLS: FunzioniLocalStorage, private funzioniLayout: FunzioniLayout,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.popolaCombo();
  }

  verificaPresenzaParametri() {
    let parametri = this.route.snapshot.queryParams
    let parametriPresenti: boolean = false;
    let tmpFiltro: FiltroArticoli = new FiltroArticoli(0, 0, [], 0, 0, 0, "", false);
    if (parametri.categoria) {
      tmpFiltro.idCategoriaArticoliFigli = parametri.categoria
      parametriPresenti = true;
    }
    if (parametri.sottocategoria) {
      tmpFiltro.idCategoriaArticoliFigli = parametri.sottocategoria
      parametriPresenti = true;
    }
    if (parametri.produttore) {
      tmpFiltro.idProduttore = parametri.produttore;
      parametriPresenti = true;
    }
    if (parametri.caratteristica) {
      let tmpCaratteristica = new ArticoliCaratteristiche(parametri.caratteristica, "", [])
      tmpFiltro.caratteristiche.push(tmpCaratteristica);
      parametriPresenti = true;
    }

    if (parametriPresenti) {
      this.funzioniLS.setFiltroArticoli(tmpFiltro);
      this.filtro = tmpFiltro;
      this.router.navigate(["spesa-online"]);
    }

  }

  get idCategoriaPadri() {
    return this.f.get('idCategoriaPadri');
  }
  get idCategoriaLivelloDue() {
    return this.f.get('idCategoriaLivelloDue');
  }
  get idProduttore() {
    return this.f.get('idProduttore');
  }
  get prezzoMax() {
    return this.f.get('prezzoMax');
  }
  get caratteristicheGroup() {
    return this.f.get('caratteristicheGroup');
  }
  get keySearch() {
    return this.f.get('keySearch');
  }
  get soloDisponibili() {
    return this.f.get('soloDisponibili');
  }

  popolaArticoli(filtro: FiltroArticoli) {
    //Avvio icona loading
    this.appLoading.avvia();

    //Salvo filtro nella localstorage per riprenderlo successivamente
    this.funzioniLS.setFiltroArticoli(filtro);

    this.articoliService.cercaArticoliConFiltri(filtro).subscribe(
      response => {
        this.articoli = response;
        this.numeroArticoli = this.articoli.length
        this.ordinaArticoli(this.ordinamentoArticoli)

        if (this.primaVisualizzazione){
          this.cambioPagina(this.filtro.paginaVisualizzazione,this.numeroArticoli);
          this.primaVisualizzazione=false;
        }
        else{
          this.cambioPagina(1,this.numeroArticoli)
        }

        this.appLoading.stoppa();
      }, error => {
        this.articoli = []
        this.numeroArticoli = 0;
        this.appLoading.stoppa();
      }
    )
    this.aggiornaChipsInfoFiltro(false);
  }


  popolaCombo() {
    this.appLoading.avvia();
    this.categorieService.elencoSmall().subscribe(
      response => {
        let categorie: ArticoliCategorie[] = response;
        this.categoriePadri = categorie.filter(x => x.livello == 1);
        this.categorieLivelloDue = categorie.filter(x => x.livello == 2);
        this.produttoriService.elencoProduttori().subscribe(
          response => {
            this.produttori = response;
            this.caratteristicheService.elencoCaratteristiche().subscribe(
              response => {
                this.caratteristiche = response;
                this.filtro = this.funzioniLS.getFiltroArticoli();
                this.f = new FormGroup({
                  'idCategoriaPadri': new FormControl(0),
                  'idCategoriaLivelloDue': new FormControl(0),
                  'idProduttore': new FormControl(0),
                  'prezzoMax': new FormControl(0),
                  'caratteristicheGroup': new FormControl(0),
                  'keySearch': new FormControl(""),
                  'soloDisponibili': new FormControl(false)
                })
                //Verifico se ci sono dei parametri nel route
                this.verificaPresenzaParametri();
                this.popolaDatiDaFiltro();
                this.funzioniLS.setFiltroArticoli(this.filtro);
                this.popolaArticoli(this.filtro);                
              }
            )
          }
        )
      }
    );
  }

  popolaDatiDaFiltro() {
    this.idCategoriaPadri.setValue(this.filtro.idCategoriaArticoliFigli);
    this.idCategoriaLivelloDue.setValue(this.filtro.idCategoriaArticoliFigli);
    this.idProduttore.setValue(this.filtro.idProduttore);
    this.prezzoMax.setValue(this.filtro.prezzoMax);
    this.keySearch.setValue(this.filtro.keySearch);
    this.soloDisponibili.setValue(this.filtro.soloDisponibili)
    this.visualizzaDisponibili = this.filtro.soloDisponibili;
    //Valorizzo le caratteristiche
    let tmpIdCaratteristiche: number[] = [];
    if (this.filtro.caratteristiche != null) {
      this.filtro.caratteristiche.forEach(function (x) {
        tmpIdCaratteristiche.push(x.id)
      })
    }
    this.caratteristicheGroup.setValue(tmpIdCaratteristiche);
  }

  filtra(dati: FormGroup) {
    //Chiudo il pannello
    this.chiudiApriPannelloFIltro(false);
    this.funzioniLayout.gotoTopPage();

    let filtro: FiltroArticoli = new FiltroArticoli(0, 0, null, 0, 0, 0, "", false);
    if (this.keySearch.value != "" && this.keySearch.value != null) {
      filtro.keySearch = this.keySearch.value;
      //setto gli altri valori a zero tranne l'ordinamento
      filtro.idCategoriaArticoliFigli = 0;
      this.idCategoriaPadri.setValue(0);
      this.idCategoriaLivelloDue.setValue(0);
      this.idProduttore.setValue(0);
      this.caratteristicheGroup.setValue(null);
    }
    if (this.idCategoriaPadri.value > 0) {
      filtro.idCategoriaArticoliFigli = this.idCategoriaPadri.value;
    }
    if (this.idCategoriaLivelloDue.value > 0) {
      filtro.idCategoriaArticoliFigli = this.idCategoriaLivelloDue.value;
    }
    if (this.idProduttore.value > 0) {
      filtro.idProduttore = this.idProduttore.value;
    }
    if (this.caratteristicheGroup.value != null && this.caratteristicheGroup.value != 0) {
      filtro.caratteristiche = [];
      let array: number[] = this.caratteristicheGroup.value;
      if (array.length > 0) {
        array.forEach(function (x) {
          let tmp: ArticoliCaratteristiche = new ArticoliCaratteristiche(x, "", null);
          filtro.caratteristiche.push(tmp);
        })
      }


    }
    if (this.prezzoMax.value > 0) {
      filtro.prezzoMax = this.prezzoMax.value;
    }
    if (this.soloDisponibili.value == true) {
      filtro.soloDisponibili = this.soloDisponibili.value;
    }
    this.visualizzaDisponibili = this.soloDisponibili.value;

    this.popolaArticoli(filtro);
    this.currentPage = 1;
  }

  aggiornaChipsInfoFiltro(pulisci: boolean) {
    this.tmpChipsFiltri = [];
    if (!pulisci) {
      if (this.idCategoriaPadri.value > 0) {
        let tmpCat: ArticoliCategorie = this.categoriePadri.find(x => x.id == this.idCategoriaPadri.value);
        if (tmpCat) {
          this.tmpChipsFiltri.push(tmpCat.titolo)
        }
      }
      if (this.idCategoriaLivelloDue.value > 0) {
        let tmpCat: ArticoliCategorie = this.categorieLivelloDue.find(x => x.id == this.idCategoriaLivelloDue.value);
        if (tmpCat) {
          this.tmpChipsFiltri.push(tmpCat.titolo)
        }
      }
      if (this.idProduttore.value > 0) {
        let tmpFornitore: Fornitori = this.produttori.find(x => x.id == this.idProduttore.value);
        if (tmpFornitore) {
          this.tmpChipsFiltri.push(tmpFornitore.ragioneSociale);
        }
      }
      if (this.soloDisponibili.value) {
        this.tmpChipsFiltri.push("Solo disponibili")
      }
      if (this.keySearch.value) {
        this.tmpChipsFiltri.push(this.keySearch.value);
      }
      if (this.prezzoMax.value > 0) {
        this.tmpChipsFiltri.push("Prezzo max: " + this.prezzoMax.value);
      }
      if (this.caratteristicheGroup.value) {
        if (this.caratteristicheGroup.value.length > 0) {
          let tmpArrayCar = this.caratteristiche;
          let appoggioChips = this.tmpChipsFiltri;
          this.caratteristicheGroup.value.forEach(function (x) {
            let tmpCarat: ArticoliCaratteristiche = tmpArrayCar.find(f => f.id == x);
            appoggioChips.push(tmpCarat.titolo)
          })
          this.tmpChipsFiltri = appoggioChips;
        }
      }
    }

    //Limito a max 3 chips
    if (this.tmpChipsFiltri.length > 3) {
      let numrecord: number = this.tmpChipsFiltri.length;
      this.tmpChipsFiltri = this.tmpChipsFiltri.slice(1, 3)
      this.tmpChipsFiltri.push("Altri (" + (numrecord - 2) + ")")
    }

    if (this.tmpChipsFiltri.length == 0) {
      this.tmpChipsFiltri.push("Nessun filtro applicato")
    }
  }

  cambiaPagina(pagina: number) {
    this.funzioniLayout.gotoTopPage();
  }

  resettaFiltro() {
    let filtro = new FiltroArticoli(0, 0, null, 0, 0, 0, "", false);
    this.funzioniLS.setFiltroArticoli(filtro);
    this.popolaArticoli(filtro)
    this.f.reset();
    this.chiudiApriPannelloFIltro(false);
    this.visualizzaDisponibili = false;
    this.aggiornaChipsInfoFiltro(true);
  }

  resettaDatoFiltro(nomeControl: string) {
    if (nomeControl == 'idCategoriaLivelloDue') {
      this.idCategoriaPadri.setValue(0);
    }
    if (nomeControl == 'idCategoriaPadri') {
      this.idCategoriaLivelloDue.setValue(0);
    }
    this.cancellaKeySearch();
  }

  chiudiApriPannelloFIltro(aperto: boolean) {
    this.pannelloFiltroAperto = aperto;
  }

  cancellaKeySearch() {
    this.keySearch.setValue("");
  }

  filtraCategoria(livello: number, idCategoria: number) {
    this.f.reset();
    if (livello == 1) {
      this.idCategoriaPadri.setValue(idCategoria);
      this.idCategoriaLivelloDue.setValue(0);
    } else if (livello == 2) {
      this.idCategoriaPadri.setValue(0);
      this.idCategoriaLivelloDue.setValue(idCategoria);
    }
    this.cancellaKeySearch();
    this.filtra(this.f);

  }

  filtraPerDisponibilita(visualizzaSolodDisponibili: boolean) {
    this.soloDisponibili.setValue(visualizzaSolodDisponibili);
    this.filtra(this.f);
  }

  ordinaArticoli(ordine: number) {
    //0 = prezzo crescente - 1 = prezzo descrescente - 2 = ultimiArrivi
    this.ordinamentoArticoli = ordine;

    switch (this.ordinamentoArticoli) {
      case 0:
        this.articoli = this.articoli.sort((a, b) => (a.prezzo < b.prezzo ? -1 : 1));
        break;
      case 1:
        this.articoli = this.articoli.sort((a, b) => (a.prezzo > b.prezzo ? -1 : 1));
        break;
      case 2:
        this.articoli = this.articoli.sort((a, b) => (a.timeStamp_Ins > b.timeStamp_Ins ? 1 : -1));
        break;
    }

    this.currentPage = 1;


  }

  cambioPagina(n:number,size:number) {
    // this.p = event;
    this.currentPage = n;    
    this.sizeArticoli=size;
    this.funzioniLayout.gotoTopPage();        
  }

  ngOnDestroy(): void {
    //Memorizzo il numero di pagina in uscita
    let filtroLS: FiltroArticoli = this.funzioniLS.getFiltroArticoli();
    filtroLS.paginaVisualizzazione = this.currentPage;
    this.funzioniLS.setFiltroArticoli(filtroLS);

  }

}
