import { FunzioniLayout } from './../../modelli/shared/Funzioni/funzioniLayout';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-chi-siamo',
  templateUrl: './chi-siamo.component.html',
  styleUrls: ['./chi-siamo.component.css']
})
export class ChiSiamoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
