import { UtentiDataService } from '../../services/data/utenti-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Utenti } from 'src/app/modelli/shared/utenti';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';


@Component({
  selector: 'app-elenco-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.css']
})
export class ElencoUtentiComponent implements OnInit {

  displayedColumns: string[] = ['email', 'nominativo', 'abilitato', 'modifica', 'elimina' ];    
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  utenti:Utenti[];
  numeroUtenti:number;

  apiMsg: ApiMsg;
  messaggio:string;

  constructor(private utentiService:UtentiDataService,private router:Router) { }

  ngOnInit(): void {
    this.getUtenti();
  }

  getUtenti(){
    this.utentiService.elencoUtenti().subscribe(
      response=>{
        this.utenti=[];
        this.utenti=response;
        this.dataSource = new MatTableDataSource(this.utenti);      
        this.dataSource.sort = this.sort;        
        this.dataSource.paginator = this.paginator; 
        this.numeroUtenti=this.utenti.length;        
      }
    )
  }

  eliminaUtente(username:string){
    this.utentiService.eliminaUtente(username).subscribe(
      response=>{
        this.apiMsg=response;
        this.messaggio=this.apiMsg.messaggio;        
        this.getUtenti();
      }
    )
  }

  apriUtente(codart:string){
    this.router.navigate(['amm/utente',codart]);
  }

  applicaFiltro(filtro:string){
    this.dataSource.filter= filtro.trim().toLowerCase();
  }

}
