import { AuthJWTService } from './../auth-jwt.service';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

//Intercettore che serve per portarsi dietro l'header(utente, password, etc...) di ogni chiamata all'api
export class AuthInterceptService implements HttpInterceptor {

  constructor(private AuthJWT: AuthJWTService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {

    let AUthToken = this.AuthJWT.getAuthToken();
    let User = this.AuthJWT.loggedUser();

    if(AUthToken && User){ //Se sono valorizzate tutte e due le variabili
      request = request.clone(
        {
          setHeaders : { Authorization: AUthToken }
        }
      )
    }
    return next.handle(request);
    
  }
}
