<div>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2808.5285190716863!2d9.18998261576174!3d45.257326055244015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786d83989faee1f%3A0x47c4246ec1c69124!2sVia%20Parco%20Visconteo%2C%204%2C%2027010%20Zeccone%20PV!5e0!3m2!1sit!2sit!4v1605005900884!5m2!1sit!2sit" width="100%" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>
<section class="container">
    <mat-card class="mat-elevation-z20 mt-5">
        <mat-card-title class="text-center text-success  font-size-18  text-uppercase">
            Contattaci
        </mat-card-title>
        <mat-card-content>
            <form [formGroup]="f" (ngSubmit)="f.valid && salva(f)">
                <p>
                    <mat-form-field>
                        <mat-label>Nominativo</mat-label>
                        <input matInput id="nominativo" formControlName="nominativo">
                        <mat-hint align="end">{{nominativo?.value?.length}} / 100</mat-hint>
                        <mat-hint *ngIf="nominativo.touched && nominativo.errors?.required" style="color: red;">Il nominativo è obbligatorio</mat-hint>
                        <mat-hint *ngIf="nominativo.touched && nominativo.errors?.maxlength" style="color: red;">Il nominativo può essere massimo di {{ nominativo.errors.maxlength.requiredLength }} caratteri</mat-hint>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field>
                        <mat-label>Mail</mat-label>
                        <input matInput id="mail" formControlName="mail">
                        <mat-hint align="end">{{mail?.value?.length}} / 100</mat-hint>
                        <mat-hint *ngIf="mail.touched && mail.errors?.required" style="color: red;">L'indirizzo mail è obbligatorio</mat-hint>
                        <mat-hint *ngIf="mail.touched && mail.errors?.email" style="color:red">Inserire una mail corretta</mat-hint>
                        <mat-hint *ngIf="mail.touched && mail.errors?.maxlength" style="color: red;">La mail può essere massimo di {{ mail.errors.maxlength.requiredLength }} caratteri</mat-hint>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field>
                        <mat-label>Messaggio</mat-label>
                        <textarea matInput rows="1" formControlName="messaggio"></textarea>
                        <mat-hint *ngIf="messaggio.touched && messaggio.errors?.required" style="color: red;">Il nominativo è obbligatorio</mat-hint>
                        <mat-hint *ngIf="messaggio.touched && messaggio.errors?.maxlength" style="color: red;">Il nominativo può essere massimo di {{ messaggio.errors.maxlength.requiredLength }} caratteri</mat-hint>
                    </mat-form-field>
                </p>
                <p>
                    <button mat-flat-button color="primary" [disabled]="!f.valid" type="submit">
                        <i class="fa fa-floppy-o" aria-hidden="true"></i> Invia
                    </button>
                </p>
                <p class="alert alert-success" *ngIf="confermaMsg">
                    {{confermaMsg}}
                </p>
                <p class="alert alert-danger" *ngIf="erroreMsg">
                    {{erroreMsg}}
                </p>
            </form>
        </mat-card-content>
    </mat-card>
</section>

