import { Articoli } from './articoli';
import { ArticoliCaratteristiche } from './articoliCaratteristiche';

export class UArticoliCaratteristiche {
    constructor(
        public id:number,
        public codart:string,
        public idCaratteristica:number,
        public articolo:Articoli,
        public caratteristica:ArticoliCaratteristiche
    ) { }
  }