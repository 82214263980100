//File con costanti che possono essere utilizzate in tutta l'applicazione
export const serverDati="data.molinolucconi.it"
export const serverUtenti ="users.molinolucconi.it"

// export const serverDati="localhost:44383"
// export const serverUtenti ="localhost:44354"

export const authServerUrlAccount="https://" + serverUtenti + "/api/account"
export const authServerUrlMail="https://" + serverUtenti + "/api/mail"
export const authServerUrlContatti="https://" + serverUtenti + "/api/contatti"
export const authServerUrlEmailNewsletter="https://" + serverUtenti + "/api/newsletter"

export const backServerUrlUtentiIndirizzi="https://" + serverDati + "/utentiindirizzi"
export const backServerUrlArticoli="https://" + serverDati + "/articoli"
export const backServerUrlFornitori="https://" + serverDati  + "/fornitori"
export const backServerUrlArticoliCategorie="https://" + serverDati +  "/articolicategorie"
export const backServerUrlArticoliFoto="https://" + serverDati +  "/articolifoto"
export const backServerUrlArticoliCaratteristiche="https://" + serverDati + "/articolicaratteristiche"
export const backServerUrlUArticoliCaratteristiche="https://" + serverDati + "/uarticolicaratteristiche"
export const backServerUrlCarrelliTestata = "https://" + serverDati + "/carrelliTestata"
export const backServerUrlCarrelliDettaglio = "https://" + serverDati + "/carrellidettaglio"
export const backServerUrlOrdineTestata = "https://" + serverDati + "/ordinitestata"
export const backServerUrlOrdineDettaglio = "https://" + serverDati + "/ordinedettaglio"
export const backServerUrlStatoOrdine="https://" + serverDati + "/statiordine"
export const backServerUrlMagazzinoMovimento = "https://" + serverDati + "/magazzino"
export const backServerUrlMagazzinoDisponibilita = "https://" + serverDati + "/disponibilita"
export const backServerUrlMetodiPagamento="https://" + serverDati + "/metodipagamento"
export const backServerUrlPagamento="https://" + serverDati + "/pagamento"

export const backServerUrlAppImpostazioni="https://" + serverDati + "/impostazioni"

export const UrlImmaginiRepository = "https://www.foto.molinolucconi.it"
export const UrlSito="https://www.molinolucconi.it"

export const mittenteMail="tecnico@molinolucconi.it"
export const nominativoMittenteMail ="Molino Lucconi"
export const recaptchaKey="6LcjfT4aAAAAALz1cer7SrHrXxlmlZXLi4FSxdKE";
