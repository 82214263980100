import { Component, OnInit } from '@angular/core';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { FiltroArticoli } from 'src/app/modelli/shared/Classi/Filtri/filtroArticoli';
import { ArticoliDataService } from 'src/app/services/data/articoli-data.service';

@Component({
  selector: 'app-marzo-in-orto',
  templateUrl: './marzo-in-orto.component.html',
  styleUrls: ['./marzo-in-orto.component.css']
})
export class MarzoInOrtoComponent implements OnInit {
  articoli: Articoli[];
  articoliSemina: Articoli[];
  articoliBlog: Articoli[] = [];
  articolo: Articoli;

  constructor(private articoliService: ArticoliDataService) { }

  ngOnInit(): void {
    let filtro: FiltroArticoli = new FiltroArticoli(76, 0, null, 0, 0, 0, '', false)
    this.articoliService.cercaArticoliConFiltri(filtro).subscribe(
      response => {
        this.articoli = response;
      }
    )

    let filtroSemina: FiltroArticoli = new FiltroArticoli(65, 0, null, 0, 0, 0, '', false)
    this.articoliService.cercaArticoliConFiltri(filtroSemina).subscribe(
      response => {
        this.articoliSemina = response;
        let tmpArticoli: Articoli[]=[];
        this.articoliSemina.forEach(function (x) {
          if (x.codArt == "SEMBASITA" || x.codArt == "SEMCARFLA" || x.codArt == "SEMLATCAN" || x.codArt == "SEMLATGHI" || x.codArt == "SEMPRECOM" || x.codArt == "SEMPREGIG" || x.codArt == "SEMRAVRAP" || x.codArt == "SEMINDSCA" || x.codArt == "SEMZUCNER") {            
            tmpArticoli.push(x);
          }
        })
        this.articoliBlog=tmpArticoli;
      }
    )

    this.articoliService.cercaArticoloByDicituraUrl('bio-afid-25-ml').subscribe(
      response => {
        this.articolo = response;
      }
    )
  }

}