<div class="col-lg-12 text-center ">
    <img class="img-fluid mat-elevation-z20" src="/assets/img/blog/molino-lucconi-mangime-colombi.png">
</div>
<div class="text-center mt-5">
    <h1 class="titoli-blog text-uppercase text-success mb-5">Mangime colombi</h1>
    <h3 class=" text-uppercase sottotitoli-blog text-muted">Il moderno sport dei colombi va oltre il semplice
        allevamento di alcuni colombi.<br><br></h3>
</div>
<!-- <div class="text-center">
    <iframe
        src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.molinolucconi.it%2Fsport-dei-colombi&layout=button_count&size=large&width=111&height=28&appId"
        width="111" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</div> -->
<div class="container">
    <div class="row mat-elevation-z20 px-3 mt-4" style="text-align: -webkit-center;">
        <div class="col-lg-6 py-5">
            <p class="testo-blog lead text-right ">
                Il moderno sport dei colombi va oltre il semplice allevamento di alcuni colombi. Il successo può essere
                paragonato a una catena di cui ogni anello è importante.<br> Uno di questi anelli è l'alimentazione, da
                cui dipende il generale stato di salute e che può consentire ai colombi di raggiungere la forma
                migliore.<br><br> O come dicono molti allevatori di colombi: l'alimentazione è e rimane un'arte...
            </p>
        </div>
        <div class="col-lg-6 mt-5 ">
            <img class="img-fluid mat-elevation-z20" src="/assets/img/blog/molino-lucconi-mangime-colombi-5.png">
        </div>
    </div>
    <div class="text-center mt-5">

        <h3 class=" text-uppercase sottotitoli-blog text-success">Miscele rossetti<br><br></h3>
    </div>
    <div class="row mat-elevation-z20 px-3 mt-4" style="text-align: -webkit-center;">
        <div class="col-lg-6 mt-5 ">
            <img class="img-fluid mat-elevation-z20" src="/assets/img/blog/molino-lucconi-mangime-colombi-6.png">
            <img class="img-fluid mat-elevation-z20 mt-5" src="/assets/img/blog/molino-lucconi-mangime-colombi-9.png">
        </div>
        <div class="col-lg-6 py-5">
            <p class="testo-blog lead text-left ">
                MISCELE ROSSETTI nasce negli anni '70 da una grande passione per i colombi che il suo fondatore Paolo ha
                sempre avuto fin da bambino.<br> L'esigenza di allevare i suoi colombi in modo sano e corretto lo spinge
                a produrre con un semplice miscelatore e un buratto un misto sano e completo.<br> La passione diventa
                lavoro e oggi come allora si lavora <b>da allevatore per l'allevatore</b>.<br> La materia prima è
                selezionata e la produzione seguita in ogni punto grazie ad un impianto moderno e ben studiato. Non
                vengono usati oli o paraffine per lucidare i semi, ma solo un buon sistema di aspirazione e tanta
                pazienza.<br> Solo lavorando e conservando i cereali in modo corretto è possibile eliminare tutte le
                impurità dai semi.<br> Queste miscele coprono l'esigenza di tutti i colombi in ogni stagione e in ogni
                fase di crescita.
            </p>
        </div>
    </div>
    <div class="text-center mt-5">

        <h3 class=" text-uppercase sottotitoli-blog text-success">Prodotti beyers<br><br></h3>
    </div>
    <div class="row mat-elevation-z20 px-3 mt-4" style="text-align: -webkit-center;">
        <div class="col-lg-6 py-5">
            <p class="testo-blog lead text-left ">
                La gamma di prodotti BEYERS, oltre alle nostre miscele, consiste in un'offerta di alta qualità di
                integratori alimentari, miscele di graniglie e minerali e prodotti per la cura e l'igiene.<br> Come
                complemento alle nostre miscele, possono garantire che le esigenze dei tuoi colombi siano soddisfatte in
                modo efficiente (e più rapido).<br> Queste esigenze dipenderanno dalle richieste poste ai tuoi
                colombi.<br> Queste richieste possono variare a seconda della stagione, ma possono anche differire
                durante una particolare stagione.<br><br>La gamma BEYERS rappresenta:
            </p>
            <ul class="lead testo-blog text-left">
                <li>Valore aggiunto extra: funzionale ed efficace</li>
                <li>Qualità</li>
                <li>Basato sulla competenza e con affermazioni realistiche</li>
                <li>Sostenuto scientificamente</li>
            </ul>
        </div>
        <div class="col-lg-6 mt-5 ">
            <img class="img-fluid mat-elevation-z20" src="/assets/img/blog/molino-lucconi-mangime-colombi-3.png">
            <img class="img-fluid mat-elevation-z20 mt-5" src="/assets/img/blog/molino-lucconi-mangime-colombi-4.png">
        </div>
    </div>
    <div class="text-center mt-5">

        <h3 class=" text-uppercase sottotitoli-blog text-success">Prodotti CEREAL FARINE <br><br></h3>
    </div>
    <div class="row mat-elevation-z20 px-3 mt-4" style="text-align: -webkit-center;">
        <div class="col-lg-6 mt-5 ">
            <img class="img-fluid mat-elevation-z20" src="/assets/img/blog/molino-lucconi-mangime-colombi-1.jpg">

        </div>
        <div class="col-lg-6 py-5">
            <p class="testo-blog lead text-left ">
                I prodotti CEREAL FARINE sono integratori complementari. <br><br><b>RECOVER:</b><br>Adatto per il
                recupero del tono e della massa muscolare dopo intensa attività motoria <br><b>PROVIGOR:</b><br>Aiuta
                l’attività di fegato, reni ed intestino per una migliore prestazione fisica
            </p>
        </div>
    </div>
</div>
<div class="row centrare my-5">
    <div class="col-sm-12 col-md-6 col-lg-2 align-self-stretch " *ngFor="let articolo of articoli">
        <app-articoli-card [articolo]="articolo" [visualizzazione]="2"></app-articoli-card>
    </div>
</div>