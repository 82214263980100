import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { FiltroArticoli } from 'src/app/modelli/shared/Classi/Filtri/filtroArticoli';
import { ArticoliDataService } from 'src/app/services/data/articoli-data.service';

@Component({
  selector: 'app-miele',
  templateUrl: './miele.component.html',
  styleUrls: ['./miele.component.css']
})
export class MieleComponent implements OnInit {
  articoli:Articoli[];

  constructor(private articoliService: ArticoliDataService, private router:Router) { }

  ngOnInit(): void {
    
    this.router.navigate(['/']);
    let filtro:FiltroArticoli= new FiltroArticoli(37,0,null,0,0,0,'',false)

    this.articoliService.cercaArticoliConFiltri(filtro).subscribe(
      response=>{
        this.articoli=response;
      }
    )
  }

}
