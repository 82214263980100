import { MatDialog } from '@angular/material/dialog';
import { ArticoliDataService } from 'src/app/services/data/articoli-data.service';
import { OrdinidettaglioDataService } from './../../services/data/ordinidettaglio-data.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { OrdiniDettaglio } from 'src/app/modelli/shared/ordiniDettaglio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { PatchMethod } from 'src/app/modelli/shared/patchMethod';

@Component({
  selector: 'app-crud-ordine-dettaglio',
  templateUrl: './crud-ordine-dettaglio.component.html',
  styleUrls: ['./crud-ordine-dettaglio.component.css']
})
export class CrudOrdineDettaglioComponent implements OnInit {

  @Input('idOrdineDettaglio') idDettaglio: number;
  @Input('idOrdineTestata') idTestata: number;
  @Output() finito = new EventEmitter<boolean>();

  f: FormGroup;
  dettaglio: OrdiniDettaglio;

  articoli: Articoli[];
  fArticoli;

  apiMsg: ApiMsg;

  constructor(private ordiniDettaglioService: OrdinidettaglioDataService, private ordiniDettaglioCRUDService: OrdinidettaglioDataService, private articoliService: ArticoliDataService,
    private snackBar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.popolaDettaglio();
  }

  popolaDettaglio() {
    this.dettaglio = new OrdiniDettaglio(0, 0, 0, 0, 0, '', '');
    this.f = new FormGroup({
      'codArt': new FormControl('', [Validators.required]),
      'quantita': new FormControl(0, [Validators.required, Validators.min(1)])
    })
    this.PopolaCombo();
    if (this.idDettaglio > 0) {
      this.f.controls['codArt'].disable();
      this.ordiniDettaglioService.cercaByID(this.idDettaglio).subscribe(
        response => {
          this.dettaglio = response;
          this.codArt.setValue(this.dettaglio.codArt);
          this.quantita.setValue(this.dettaglio.quantita);
        }
      )
    } else {

    }

  }

  get codArt() {
    return this.f.get('codArt');
  }

  get quantita() {
    return this.f.get('quantita');
  }

  PopolaCombo() {
    //Popolo la tendina articoli
    this.articoli = [];
    this.articoliService.elencoArticoliCompleto().subscribe(
      response => {
        this.articoli = response;
        this.fArticoli = this.articoli.slice();
      }
    )
  }

  salvaDettaglio(dati: FormGroup) {
    if (this.dettaglio.id > 0) { //Modifico
      //Creo l'array di campi che passo poi per la patch
      let paramQta = new PatchMethod(this.quantita.value, '/Quantita', 'replace');
      let paramCodArt = new PatchMethod(this.codArt.value, '/CodArt', 'replace');

      this.ordiniDettaglioCRUDService.modifica(this.dettaglio.id, [paramCodArt, paramQta]).subscribe(
        response => {
          this.apiMsg = response;
          this.visualizzaSnackBar();
        }, error => {
          this.apiMsg = error;
        }, () => {
          this.annulla();
        }
      )

    } else { //Salvo
      this.dettaglio = dati.value;
      this.dettaglio.idTestata = this.idTestata;
      this.ordiniDettaglioCRUDService.inserisci(this.dettaglio).subscribe(
        response => {
          this.apiMsg = response;
          this.visualizzaSnackBar();
        }, error => {
          this.apiMsg = error;
        }, () => {
          this.annulla();
        }
      )

    }
  }

  visualizzaSnackBar() {
    let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }

  //Con questa funzione chiudo il componente
  annulla() {
    this.finito.emit(true);
  }

}
