<mat-card *ngIf="visualizzazione==2 && articolo != null" class="mat-elevation-z4 my-2 altezza-card  ">
    <owl-carousel-o class="container" [options]="customOptions" *ngIf="articolo.foto.length<=1">
        <ng-template carouselSlide >
            <a class="puntatore-mano" (click)="vaiArticolo(articolo.dicituraUrl)">
                <img *ngIf="articolo.foto.length > 0" mat-card-image class="altezza-immagine "
                    src='https://www.foto.molinolucconi.it/{{articolo.foto[0].nomeFile}}'>
                <img *ngIf="articolo.foto.length < 1" mat-card-image class="altezza-immagine "
                    src='/assets/img/molino-lucconi-logo.jfif'>
            </a>
        </ng-template>
        <ng-template carouselSlide >
            <a class="puntatore-mano" (click)="vaiArticolo(articolo.dicituraUrl)">
            <img *ngIf="articolo.foto.length>0" mat-card-image class="altezza-immagine mx-2 " src='https://www.foto.molinolucconi.it/{{articolo.foto[0].nomeFile}}'>
            <!-- <img *ngIf="articolo.foto.length<2" mat-card-image class="altezza-immagine mx-2 " src='/assets/img/molino-lucconi-logo.jfif'> -->
        </a>
        </ng-template>
    </owl-carousel-o>

    <owl-carousel-o class="container" [options]="customOptions" *ngIf="articolo.foto.length>1">

            <ng-template carouselSlide *ngFor="let f of articolo.foto;index as i">
                <a class="puntatore-mano" (click)="vaiArticolo(articolo.dicituraUrl)">
                <img mat-card-image class="altezza-immagine px-1 mx-1"
                    src='https://www.foto.molinolucconi.it/{{articolo.foto[i].nomeFile}}'>
                </a>
                
            </ng-template>

    </owl-carousel-o>

    <mat-card-header class="testo-titolo text-center">
        <mat-card-title class="text-uppercase  text-success testo-titolo">
            {{ articolo.titolo }}
        </mat-card-title>
        <mat-card-subtitle>
            <p>{{ articolo.dtoFornitoreRagioneSociale }}</p>
        </mat-card-subtitle>
        <!-- <mat-card-sibtitle>Sotto titolo</mat-card-sibtitle> -->
    </mat-card-header>

    <!-- <mat-card-content class="card-body d-flex flex-column mb-0 ">
        <p class="dissolvi-testo">{{ articolo.descrizione | slice:0:200 }}</p>
    </mat-card-content> -->

    <mat-card-actions class="bottom-wrap mb-0 ">
        <div class="price-wrap h5 text-center container ">
            <div class="mb-4">
                <!-- <span class="price-new h3  ">{{ articolo.prezzo | currency: 'EUR':'symbol' }}</span> -->
                <button mat-button class="text-uppercase  " color="primary"
                    (click)="vaiArticolo(articolo.dicituraUrl)">Visualizza</button>
            </div>
            <!-- <div class="input-group">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-success btn-number color-white" (click)="rimuovi1()">
                        <i class=" fa fa-minus mx-2"></i>
                    </button>
                </span>
                <input type="text" class="form-control input-number text-center bg-white border-white" value="1" min="1"
                    disabled value='{{ quantita }}'>
                <span class="input-group-btn">
                    <button type="button" class="btn btn-success btn-number" (click)="aggiungi1()">
                        <i class="fa fa-plus mx-2"></i>
                    </button>
                </span>
            </div> -->
            <!-- <div class="row centrare text-center" *ngIf="articolo.disponibilita != null">
                <button class="centrare m-4" mat-icon-button (click)="aggiungiAlCarrello(articolo)"
                    *ngIf="articolo.disponibilita.qta>0">
                    <button class="mat-elevation-z4" mat-icon-button>
                        <mat-icon>add_shopping_cart</mat-icon>

                    </button>
                </button>
            </div> -->
            <button mat-button color="primary" class="m-5  " class="text-viola text-uppercase bold ml-3 "> {{
                articolo.categoria.titolo }}</button>
            <!-- <p class="text-center testo-basso" *ngIf="articolo.disponibilita != null">Disponibilià: {{
                articolo.disponibilita.datiDisponibilita.dicitura }}</p>
            <p class="text-center testo-basso" *ngIf="articolo.disponibilita == null">Disponibilià: esaurita</p> -->
        </div>
        <div *ngIf="articolo.soloRitiro" class="bg-warning text-white text-center testo-basso align-bottom">DISPONIBILE SOLO PER IL RITIRO IN NEGOZIO</div>
    </mat-card-actions>

</mat-card>

<mat-card *ngIf="visualizzazione==1 && articolo != null" class="mat-elevation-z20 m-3 altezza-card-small">
    <a class="puntatore-mano " (click)="vaiArticolo(articolo.dicituraUrl)">
        <img *ngIf="articolo.foto.length > 0" mat-card-image class="altezza-immagine larghezza-auto"
            src='https://www.foto.molinolucconi.it/{{articolo.foto[0].nomeFile}}'>
        <img *ngIf="articolo.foto.length < 1" mat-card-image class="altezza-immagine larghezza-auto"
            src='/assets/img/molino-lucconi-logo.jfif'>
    </a>
    <mat-card-header class="testo-titolo text-center">
        <mat-card-title class="text-uppercase  text-success testo-titolo">
            {{ articolo.titolo }}
        </mat-card-title>
        <!-- <mat-card-subtitle>
            <p>{{ articolo.produttore.ragioneSociale }}</p>
        </mat-card-subtitle> -->
        <!-- <mat-card-sibtitle>Sotto titolo</mat-card-sibtitle> -->
    </mat-card-header>
    <mat-card-actions class="bottom-wrap">
        <div class="price-wrap h5">
            <!-- <span class="price-new">{{ articolo.prezzo | currency: 'EUR':'symbol' }}</span> -->
            <button mat-button color="primary" (click)="vaiArticolo(articolo.dicituraUrl)">Visualizza</button>
        </div>
    </mat-card-actions>

</mat-card>