import { FiltroArticoli } from 'src/app/modelli/shared/Classi/Filtri/filtroArticoli';
import { FunzioniLocalStorage } from 'src/app/modelli/shared/Funzioni/funzioniLocalStorage';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export class OggettoInput {
  constructor(
    public idCategoria:number,
    public PercorsoImmagine: string,
    public Macrocategoria: string
  ) { }
}

@Component({
  selector: 'app-griglia-layout',
  templateUrl: './griglia-layout.component.html',
  styleUrls: ['./griglia-layout.component.css']
})
export class GrigliaLayoutComponent implements OnInit {

  @Input("oggettiInput") oggettiImput: OggettoInput[]
  @Input("nMaxOggettiVisual") nMaxOggettiVisual: number

  constructor(private funzioniLS:FunzioniLocalStorage, private route: Router) { }


  ngOnInit(): void {
    this.oggettiImput = this.oggettiImput.slice(0, this.nMaxOggettiVisual)
  }

  ifIndexMaxVisual() {
  }

  getLunghezza(indice: any) {
    if (indice % 2 == 0) {
      if (this.oggettiImput.length > indice + 1) {
        return "col-lg-12";
      } else {
        return "col-lg-12";
      };
    };
    return "col-lg-12";
  }

  vaiCategoria(idCategoria:number){
    let filtro:FiltroArticoli= new FiltroArticoli(0,idCategoria,null,0,0,0,"",false)
    this.funzioniLS.setFiltroArticoli(filtro);
    this.route.navigate(['spesa-online']);  
  }

}
