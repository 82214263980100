import { ApiMsg } from './../../modelli/shared/apiMsg';
import { backServerUrlUtentiIndirizzi } from './../../modelli/app.contstant';
import { UtentiIndirizzo } from './../../modelli/shared/utentiIndirizzo';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtentiIndirizziDataService {

  constructor(private http:HttpClient) { }

  cercaByUsername(username:string){
    return this.http.get<UtentiIndirizzo[]>(backServerUrlUtentiIndirizzi + "/cerca/username/" + username)
  }

  inserisciIndirizzo(indirizzo:UtentiIndirizzo){
    return this.http.post<ApiMsg>(backServerUrlUtentiIndirizzi + "/inserisci",indirizzo)
  }

  modificaIndirizzo(indirizzo:UtentiIndirizzo){
    return this.http.put<ApiMsg>(backServerUrlUtentiIndirizzi + "/modifica",indirizzo)
  }

  eliminaIndirizzo(id:number){
    return this.http.delete<ApiMsg>(backServerUrlUtentiIndirizzi + "/elimina/" + id)
  }

}
