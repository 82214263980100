import { MatSortParameter } from './../../modelli/shared/Funzioni/funzioniLocalStorage';
import { FunzioniLocalStorage } from 'src/app/modelli/shared/Funzioni/funzioniLocalStorage';
import { ArticoliDataService } from './../../services/data/articoli-data.service';
import { ApiMsg } from './../../modelli/shared/apiMsg';
import { Articoli } from './../../modelli/shared/articoli';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { LoadingService } from 'src/app/services/app/loading.service';

@Component({
  selector: 'app-articoli',
  templateUrl: './articoli.component.html',
  styleUrls: ['./articoli.component.css']
})
export class ArticoliComponent implements OnInit {
  displayedColumns: string[] = ['codArt', 'titolo', 'categoria.titolo', 'produttore.ragioneSociale', 'fornitore.ragioneSociale', 'prezzo', 'modifica', 'elimina'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  articoli: Articoli[];
  numeroArticoli: number;

  apiMsg: ApiMsg;

  filtroTab:string="";

  constructor(private articoliService: ArticoliDataService, private router: Router, private snackBar: MatSnackBar, private appLoading: LoadingService,
    private funzionLS: FunzioniLocalStorage) {

  }

  ngOnInit(): void {
    this.getArticoli();
  }

  getArticoli() {
    this.appLoading.avvia();
    this.articoliService.elencoArticoliCompleto().subscribe(
      response => {
        this.articoli = [];
        this.articoli = response;
        this.dataSource = new MatTableDataSource(this.articoli);
        let tmpOrdinePagina: MatSortParameter = this.funzionLS.getOrdinePaginaTabellaArticoli();

        this.dataSource.filterPredicate = (data: Articoli, filter: string) => {
          return data.categoria.titolo.toLocaleLowerCase().includes(filter) ||
            data.produttore.ragioneSociale.toLocaleLowerCase().includes(filter) ||
            data.fornitore.ragioneSociale.toLocaleLowerCase().includes(filter) ||
            data.titolo.toLocaleLowerCase().includes(filter)
        }

        let defaultSort: MatSortable = {
          id: tmpOrdinePagina.matSortActive,
          start: tmpOrdinePagina.matSortDirection == "asc" ? "asc" : "desc",
          disableClear: true
        };
        this.sort.sort(defaultSort);
        this.sort.direction = defaultSort.start;

        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
          return this.getPropertyByPath(data, sortHeaderId);
        };

        this.paginator.pageIndex = tmpOrdinePagina.nPagina;
        this.dataSource.paginator = this.paginator;
        this.numeroArticoli = this.articoli.length;       

        this.filtroTab=tmpOrdinePagina.filtroTable;
        this.applicaFiltro(this.filtroTab);

        this.funzionLS.removeOrdinePaginaTabellaArticoli();

        this.appLoading.stoppa();
      }
    )
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  eliminaArticolo(codart: string) {
    this.articoliService.eliminaArticolo(codart).subscribe(
      response => {
        this.apiMsg = response;
        this.visualizzaSanckBar(1);
        this.getArticoli();
      }, error => {
        this.apiMsg = error.error;
        this.visualizzaSanckBar(2);
      }
    )
  }

  visualizzaSanckBar(design: number) {
    let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 0 });
  }

  apriArticolo(codart: string) {
    let sortParameter: MatSortParameter
    sortParameter = new MatSortParameter(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.filtroTab)
    this.funzionLS.setOrdinePaginaTabellaArticoli(sortParameter);
    this.router.navigate(['amm/articolo', codart]);
  }

  applicaFiltro(filtro: string) {
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

  clickSort(e: any) {
    


  }

}
