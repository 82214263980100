import { Articoli } from './../../modelli/shared/articoli';
import { ArticoliDataService } from './../data/articoli-data.service';
import { FiltroArticoli } from './../../modelli/shared/Classi/Filtri/filtroArticoli';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppArrayArticoliService {

  private subject = new Subject<any>();

  constructor(private articoliSercives:ArticoliDataService) { }

  impostaArrayArticoli(filtro:FiltroArticoli) {
    //Con next dando un altro valore a subject è come se immettessi il valore successivo
    
      this.articoliSercives.cercaArticoliConFiltri(filtro).subscribe(
        response => {
          this.subject.next();
          this.subject.next({ articoli: response });
          
        },error=>{
          
        }
      )
    
  }

//   pulisciImporto() {
//     //con next imposto il valore successivo di subject a niente
//     this.subject.next();
//     this.subject.next({text : 0});
//   }

  rilevaArrayArticoli(): Observable<any> {
    //Ritorno l'ultimo valore impostato a subject    
    return this.subject.asObservable();
  }
}
