<section class="container">
    <mat-card class="mat-elevation-z20">
        <mat-card-title class="text-center">
            <label *ngIf="tmpid==0">Nuova categoria</label>
            <!-- <label *ngIf="tmpid>0">Categoria: {{ categoria.titolo }}</label> -->
        </mat-card-title>
        <mat-card-content>
            <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salva(f)">
                <p>
                    <mat-form-field>
                        <mat-label>Titolo</mat-label>
                        <input matInput id="titolo" formControlName="titolo">
                        <mat-hint align="end">{{titolo?.value?.length}} / 250</mat-hint>
                        <mat-hint *ngIf="titolo.touched && titolo.errors?.minlength" style="color: red;">Il titolo della
                            categoria deve essere almeno di 2 caratteri.</mat-hint>
                        <mat-hint *ngIf="titolo.touched && titolo.errors?.maxlenght" style="color: red;">Il titolo della
                            categoria deve essere massimo di 250 caratteri.</mat-hint>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field>
                        <mat-label>Descrizione</mat-label>
                        <input matInput id="descrizione" formControlName="descrizione">
                        <mat-hint align="end">{{descrizione?.value?.length}} / 1000</mat-hint>
                        <mat-hint *ngIf="descrizione.touched && descrizione.errors?.maxlenght" style="color: red;">La
                            descrizione della categoria deve essere massimo di 1000 caratteri.</mat-hint>
                    </mat-form-field>
                </p>
                <!-- <p>
                    <mat-form-field>
                        <mat-label>Dicitura url</mat-label>
                        <input matInput id="dicituraUrl" formControlName="dicituraUrl">
                        <mat-hint *ngIf="dicituraUrl.touched && dicituraUrl.errors?.required" style="color: red;">La
                            dicitura dell'url della categoria è obbligatoria.</mat-hint>
                    </mat-form-field>
                </p> -->
                <mat-form-field>
                    <mat-label>Categoria padre</mat-label>
                    <mat-select name="idPadre" formControlName="idPadre" #sPadri>
                        <mat-select-filter [placeholder]="'Cerca'" *ngIf="sPadri.focused" [displayMember]="'titolo'"
                            [array]="categoriePadre" (filteredReturn)="fCategoriePadre =$event">
                        </mat-select-filter>
                        <mat-option value="0">Nessuna categoria selezionata</mat-option>
                        <mat-option [value]="categoria.id" *ngFor="let categoria of fCategoriePadre">
                            {{categoria.titolo}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>
                    <mat-checkbox id="ultima" formControlName="ultima">Ultima</mat-checkbox>
                </p>
                <p>
                    <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit">
                        <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
                    </button>
                    <button mat-flat-button color="warn" id=btnChiudi type="submit" class="pull-right"
                        (click)="chiudi()">
                        <i class="fa fa-times" aria-hidden="true"></i> Chiudi
                    </button>
                </p>
                <p class="alert alert-success" *ngIf="confermaMsg">
                    {{confermaMsg}}
                </p>
                <p class="alert alert-danger" *ngIf="erroreMsg">
                    {{erroreMsg}}
                </p>
            </form>
        </mat-card-content>
    </mat-card>
</section>