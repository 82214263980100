<section>
    <div class="container" *ngIf="utenteCorretto==1">
        <div class="col-lg-12 bg-light text-center mt-5 ">
            <mat-icon class="font-alto-icone bold mt-5">done</mat-icon>
            <p class="text-success font-alto pt-3 pb-5">
                GRAZIE !
            </p>
        </div>
        <div class="alert alert-success font-medio-basso text-center" role="alert">
            Ordine concluso con successo!
        </div>
        <div class="text-center mb-5">
            <p class="text-success font-medio pt-3 pb-3">Riceverai presto una mail di riepilogo del tuo ordine. </p>
        </div>
        <div class="text-center">
            <a [routerLink]="'/'">
                <button type="button" class="btn btn-success">Torna alla home</button>
            </a>
        </div>
    </div>    
    <div class="container" *ngIf="utenteCorretto==-1">
        <div class="col-lg-12 bg-light text-danger text-center mt-5 ">
            <mat-icon class="font-alto-icone text-danger bold mt-5">error</mat-icon>
            <p class="text-secondary font-alto pt-3 pb-5">
                Oops...
            </p>
        </div>
        <div class="alert alert-danger font-medio-basso text-center" role="alert">
            C'è stato un problema con la conferma ordine
        </div>
        <div class="text-center mb-5">
            <p class="text-danger font-medio pt-3 pb-3">Per problemi tecnici scrivere a: <a
                href="mailto:lucconi.stefano@libero.it" class="text-danger bold" target="_blank">lucconi.stefano@libero.it</a>
            </p>
        </div>
        <div class="text-center">
            <a [routerLink]="'/utente/area-utente'">
                <button type="button" class="btn btn-danger">Torna all'area utente</button>
            </a>
        </div>
    </div>
</section>