import { FunzioniLayout } from 'src/app/modelli/shared/Funzioni/funzioniLayout';
import { recaptchaKey } from './../../modelli/app.contstant';
import { EmailNewsletterDataService } from './../../services/data/email-newsletter-data.service';
import { EmailNewsletter } from './../../modelli/shared/emailNewsletter';
import { DialogClass } from './../../modelli/shared/Classi/Componenti/dialogClass';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { IubendaDataService } from 'src/app/services/data/iubenda-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { LoadingService } from 'src/app/services/app/loading.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private dialog: MatDialog, private iubendaData: IubendaDataService, private newsletterDataService: EmailNewsletterDataService,
    private appLoading: LoadingService, private snackBar: MatSnackBar,private funzioniLayout:FunzioniLayout) { }

  fNews: FormGroup;
  apiMsg: ApiMsg;
  confermaIscrizione: string = "";
  erroreIscrizione: string = "";
  emailNewsletter: EmailNewsletter;
  keyR:string=recaptchaKey;

  ngOnInit(): void {
    this.emailNewsletter = new EmailNewsletter(0, '', '', '', false, '')
    this.fNews = new FormGroup({
      'nomeNews': new FormControl('', [Validators.required, Validators.maxLength(100)]),
      'cognomeNews': new FormControl('', [Validators.required, Validators.maxLength(100)]),
      'emailNews': new FormControl('', [Validators.required, Validators.maxLength(100), Validators.email]),
      'privacyAccettataNews': new FormControl(false,[Validators.requiredTrue]),
      'recaptcha': new FormControl(null, Validators.required)
    })
  }

  get nomeNews() {
    return this.fNews.get('nomeNews');
  }
  get cognomeNews() {
    return this.fNews.get('cognomeNews');
  }
  get emailNews() {
    return this.fNews.get('emailNews');
  }
  get privacyAccettataNews() {
    return this.fNews.get('privacyAccettataNews')
  }

  salvaIscrizioneNewsletter(dati: FormGroup) {
    this.appLoading.avvia();
    this.confermaIscrizione = "";
    this.erroreIscrizione = "";
    this.emailNewsletter.nome=dati.value['nomeNews']
    this.emailNewsletter.cognome=dati.value['cognomeNews']
    this.emailNewsletter.email=dati.value['emailNews']
    this.emailNewsletter.privacyAccettata=dati.value['privacyAccettataNews']


    this.newsletterDataService.inserisciIscrizione(this.emailNewsletter).subscribe(
      response => {
        this.apiMsg = response;
        this.confermaIscrizione = this.apiMsg.messaggio;        
        this.fNews.reset();
        this.appLoading.stoppa();
        let contenuto = "Iscrizione alla newsletter eseguita con successo con la mail" + this.emailNewsletter.email
        let snackBarData: SnackBarClass = new SnackBarClass(contenuto, false)
        let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 }); 
        this.funzioniLayout.gotoTopPage();
      },
      error => {
        this.apiMsg = error.error;
        this.erroreIscrizione = this.apiMsg.messaggio;
        this.appLoading.stoppa();
      }
    )

  }

  openIubendaDialog(visualizzazione: number) {
    let contenuto: string;
    //Se 1 visualizzo privacy, se 2 visualizzo cookie policy
    if (visualizzazione == 1) {
      this.iubendaData.privacyPolicy().subscribe(
        response => {
          this.lanciaDialogIubenda(response.content);
        }
      )
    } else if (visualizzazione == 2) {
      this.iubendaData.cookiePolicy().subscribe(
        response => {
          this.lanciaDialogIubenda(response.content);
        }
      )
    }

  }

  lanciaDialogIubenda(contenuto: string) {
    let dialogData: DialogClass = new DialogClass("Molino Lucconi", contenuto, true, false, false)
    let dialogRef = this.dialog.open(DialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(
      response => {
      }
    )
  }
}
