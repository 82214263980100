<h2 mat-dialog-title> {{ data.titolo }} </h2>
<mat-dialog-content *ngIf="!data.contenutoInHtml">{{data.contenuto}}</mat-dialog-content>
<mat-dialog-content *ngIf="data.contenutoInHtml">
    <div [innerHtml]="data.contenuto"></div>
</mat-dialog-content>
<mat-dialog-actions>
    <!-- Il valore della mat-dialog-close è quello che mi ritorna nella response del componente che la chiama -->
    <button *ngIf="data.bottoneSi" mat-button mat-dialog-close="Si">Si</button>
    <button *ngIf="data.bottoneNo" mat-button mat-dialog-close="No">No</button> 
</mat-dialog-actions>
