import { PatchMethod } from './../../modelli/shared/patchMethod';
import { ApiMsg } from './../../modelli/shared/apiMsg';
import { FiltroMagazzinoMovimento } from './../../modelli/shared/Classi/Filtri/filtroMagazzinoMovimento';
import { backServerUrlMagazzinoMovimento } from './../../modelli/app.contstant';
import { MagazzinoMovimento } from './../../modelli/shared/magazzinoMovimento';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MagazzinoMovimentoDataService {

  constructor(private http:HttpClient) { }

  // lista(){
  //   return this.http.get<MagazzinoMovimento[]>(backServerUrlMagazzinoMovimento + "/lista");
  // }

  listaFiltrata(filtro:FiltroMagazzinoMovimento){
    return this.http.post<MagazzinoMovimento[]>(backServerUrlMagazzinoMovimento + "/listafiltrata",filtro);
  }  

  inserisci(movimento:MagazzinoMovimento){
    return this.http.post<ApiMsg>(backServerUrlMagazzinoMovimento + "/inserisci",movimento);
  }

  modifica(id:number,campi:PatchMethod[]){   
    return this.http.patch<ApiMsg>(backServerUrlMagazzinoMovimento + "/modifica/" +id,campi);
  }

  elimina(id:number){
    return this.http.delete<ApiMsg>(backServerUrlMagazzinoMovimento + "/elimina/" + id);
  }
}
