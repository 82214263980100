<div class="parallax mat-elevation-z20 ">
    <div class="text-center  mt-4 mb-4 vertical-center">
        <p class="h1  text-white font-light text-uppercase font-italic  bg-success  "> la storia del
                molino</p>
    </div>

</div>
<section class="container">
    <div class="row  mt-5">
        <div class="col-lg-5 mt-5  text-center"><img src="assets/img/molino-lucconi-storia-002.png"
                alt="molino lucconi storia pavia" class="img-fluid mt-100 " style="margin-top: 10%;"></div>
        <div class="col-lg-7 mat-elevation-z20">
            <h2 class="m-4 lead text-uppercase">La famiglia Lucconi</h2>
            <p class="m-4 lead">
                Costituita nel <b>1721</b>, l’impresa del Molino, si lega alla famiglia Lucconi nel <b>1908</b> quando
                il sig. Giuseppe Lucconi, da tutti conosciuto come Giovanni, ci si trasferisce con la famiglia.<br><br>
                Dopo anni di duro lavoro come conduttore, nel <b>1936</b> Giovanni corona il suo sogno di acquistare
                l'intero immobile e le attività produttive ad esso collegate.<br><br>
                Nel <b>1967</b> subentrano nella conduzione del Molino i figli Gaetano e Luigi presto affiancati dalla
                terza
                generazione di Lucconi che vede i nipoti Terenzio e Giuseppe proseguire con quella che è ormai diventata
                una tradizione di famiglia fino al termine degli anni novanta.<br>
                Col tempo Il Molino ha diversificato la propria offerta, affiancando all'attività artigiana di
                macinatura la vendita al dettaglio di alimentari, vini e bevande, sementi, articoli per la zootecnia,
                per la pulizia della casa e del giardino.<br><br>
                Dal <b>2000</b> è Stefano che, erede dell’antico mestiere di artigiano mugnaio iniziato dal bisnonno
                Giovanni, manda avanti il Molino nella sua originaria sede di via Parco Visconteo affiancato dalla
                moglie Tatiana.<br><br>
                A più di un secolo dai suoi inizi e per restare al passo con i tempi, l’impresa si è evoluta anche su
                piattaforma e-commerce, mantenendo però un’impronta famigliare e genuina che ha sempre caratterizzato il
                nostro modo di essere e di lavorare.


            </p>
        </div>
    </div>

    <div class="row  mt-5">
        <div class="col-lg-7 mat-elevation-z20">
            <h2 class="m-4 lead text-uppercase">La storia del molino</h2>
            <p class="m-4 lead">
                Il Molino è stato <b>costruito nel 1721 dal conte Federico Barbiano di Belgioioso</b>, come certifica
                un'epigrafe ancora presente nel nostro cortile. Sempre documentati risultano essere alcuni lavori di
                ampliamento, effettuati nel 1877, che hanno portato alla <b>costruzione di una torre centrale e alla
                    spalletta del corso d'acqua.</b><br><br>
                Dal 1936 al 1947 sono stati costruiti gli impianti per la pulizia delle granaglie macinate (tramogge,
                cassero, colonne) presenti e funzionanti ancora oggi.<br>
                Nel 1988 sono stati effettuati ulteriori ampliamenti dei magazzini, che però non hanno alterato la parte
                storica del fabbricato che è tuttora intatta nella sua conformazione originale.

            </p>
        </div>
        <div class="col-lg-5  text-center"><img src="assets/img/molino-lucconi-macinati-a-pietra-001.png"
                alt="molino lucconi storia pavia" class="img-fluid mt-5"></div>
    </div>
    <div class="row  mt-5">
        <div class="col-lg-5  text-center"><img src="assets/img/molino-lucconi-storia-003.png"
                alt="molino lucconi storia pavia" class="img-fluid w-50  mb-5"></div>
        <div class="col-lg-7 mat-elevation-z20">
            <h2 class="m-4 lead text-uppercase">L'attivita'</h2>
            <p class="m-4 lead">
                <b>La lavorazione e il commercio al minuto di cereali, mangimi e prodotti per la zootecnia rappresenta il
                cuore della nostra attività</b>.Siamo orgogliosi di macinare il mais ad uso zootecnico come si faceva una
                volta e cioè utilizzando le nostre macine in pietra e gli impianti di lavorazione originali. Il prodotto
                finale così ottenuto rispecchia la semplicità e la genuinità di un’epoca ormai passata ma alla quale noi
                ci ispiriamo per tornare alle nostre origini.<br><br>
                A questa attività storica abbiamo affiancato il commercio di generi alimentari con un occhio di riguardo
                ai prodotti locali e a km 0, così come la vendita di articoli per il giardinaggio (piante, fiori,
                sementi, attrezzi, ecc.) per la casa, e per la cura della persona.

            </p>
        </div>
    </div>

</section>
<div class="parallax2 mat-elevation-z20 ">
    <div class="text-center  mt-4 mb-4 vertical-center container">

    </div>

</div>