<div class="container">
    <div class="col-lg-12 bg-light text-center mt-5 ">
        <mat-icon class="font-alto-icone bold mt-5">done</mat-icon>
        <p class="text-success font-alto pt-3 pb-5">
            Grazie per esserti registrato
        </p>
    </div>
    <div class="alert alert-success font-medio-basso text-center" role="alert">
        Account confermato con successo!
    </div>

    <div class="text-center">
        <a [routerLink]="['/login']">
            <button type="button" class="btn btn-success">Login</button>
        </a>
    </div>
</div>