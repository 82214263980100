import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private subject = new Subject<boolean>();

  constructor() { }

  //Questa funzione avvia la visualizzazione della loading
  avvia(){
    this.subject.next();
    this.subject.next(true);
  }

  //Questa funzione stoppa la visualizzazione della loading
  stoppa(){
    this.subject.next();
    this.subject.next(false);
  }

  rilevaStato(): Observable<any> {
    //Ritorno l'ultimo valore impostato a subject    
    return this.subject.asObservable();
  }
}
