<mat-card class="mat-elevation-z20">
    <mat-card-title class="text-center">
        <label *ngIf="dettaglio==null">Nuovo movimento</label>
    </mat-card-title>
    <mat-card-content>
        <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salvaDettaglio(f)">
            <p>
                <mat-form-field>
                    <mat-label>Articolo</mat-label>
                    <mat-select name="codArt" formControlName="codArt" #sArticoli id="codArt">
                        <mat-select-filter [placeholder]="'Cerca'" *ngIf="sArticoli.focused"
                            [displayMember]="'titolo'" [array]="articoli"
                            (filteredReturn)="fArticoli =$event"></mat-select-filter>
                        <mat-option value="">Nessun articolo selezionato</mat-option>
                        <mat-option [value]="articolo.codArt" *ngFor="let articolo of fArticoli">
                            {{articolo.codArt}} - {{articolo.titolo}}</mat-option>                                    
                    </mat-select>
                    <mat-hint *ngIf="codArt.touched && codArt.errors?.required" style="color: red;">
                        L'articolo è obbligatorio</mat-hint>
                </mat-form-field>
            </p>            
            <p>
                <mat-form-field>
                    <mat-label>Qta</mat-label>
                    <input matInput type="number" id="quantita" formControlName="quantita">
                    <mat-hint *ngIf="quantita.touched && quantita.errors" style="color: red;">La
                        quantità deve essere maggiore di 0</mat-hint>
                </mat-form-field>
            </p>            
            <p>
                <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit">
                    <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
                </button>
                <button mat-flat-button color="warn" id=btnAnnulla type="button" class="pull-right"
                    (click)="annulla()">
                    <i class="fa fa-times" aria-hidden="true"></i> Annulla
                </button>
            </p>
        </form>
    </mat-card-content>
</mat-card>