<section class="container">
    <form (ngSubmit)="utenteForm.valid && salva()" #utenteForm="ngForm" >
        <div class="form-body">
            <div class="form-group">
                <label for="username">{{ utente.username }}</label>                
            </div>
            <div class="form-group">
                <label for="password">Password *</label>
                <input name=password type="password" [(ngModel)]="utente.password" class="form-control" required minlength="8" maxlength="100" #password="ngModel" >
                <span style="color: red;" *ngIf="password.touched && password.errors?.required">La password è obbligatoria.</span>
                <span style="color: red;" *ngIf="password.touched && password.errors?.minlength">La password deve essere minimo di 8 caratteri.</span>
                <span style="color: red;" *ngIf="password.touched && password.errors?.maxlenght">La password deve essere massimo di 100 caratteri.</span>
            </div>
            <div class="form-group">
                <label for="ripetiPassword">Conferma password *</label>
                <input name="ripetiPassword" type="password" [(ngModel)]="utente.ripetiPassword" required class="form-control" #ripetiPassword="ngModel">
                <span style="color: red;" *ngIf="ripetiPassword.touched && ripetiPassword.errors?.required">La conferma della password è obbligatoria.</span>
                <span style="color: red;" *ngIf="ripetiPassword.touched && ripetiPassword.valid && ripetiPassword.value != password.value">La password e la conferma password devono corrispondere.</span>
            </div>            
            <hr class="line-form">
            <button class="btn btn-primary form-buttons" id=btnRegistrazione [disabled]="utenteForm.invalid || ripetiPassword.value != password.value" type="submit">
                <i class="fa fa-floppy-o" aria-hidden="true"></i> Reset
            </button>
        </div>
    </form>
</section>
