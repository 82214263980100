import { SEOService } from './services/app/seo.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from './modelli/shared/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPrintModule } from 'ngx-print';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import { ClipboardModule } from '@angular/cdk/clipboard';

import { TopbarComponent } from './component/topbar/topbar.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { FooterComponent } from './component/footer/footer.component';
import { HomepageComponent } from './page/homepage/homepage.component';
import { ContattaciComponent } from './page/contattaci/contattaci.component';
import { ChiSiamoComponent } from './page/chi-siamo/chi-siamo.component';
import { Errore404Component } from './page/errore404/errore404.component';
import { LoginComponent } from './page/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthInterceptService } from './services/http/auth-intercept.service';
import { RegistratiComponent } from './page/registrati/registrati.component';
import { DashboardComponent } from './pagesAdmin/dashboard/dashboard.component';
import { ElencoUtentiComponent } from './pagesAdmin/utenti/utenti.component';
import { ConfermaMailComponent } from './page/conferma-mail/conferma-mail.component';
import { RecuperaPasswordComponent } from './page/recupera-password/recupera-password.component';
import { ArticoliComponent } from './pagesAdmin/articoli/articoli.component';
import { ArticoloComponent } from './pagesAdmin/articolo/articolo.component';
import { FornitoriComponent } from './pagesAdmin/fornitori/fornitori.component';
import { CategorieComponent } from './pagesAdmin/categorie/categorie.component';
import { CategoriaComponent } from './pagesAdmin/categoria/categoria.component';
import { UtenteComponent } from './pagesAdmin/utente/utente.component';
import { SpesaArticoliComponent } from './page/spesa-articoli/spesa-articoli.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ArticoloSpesaComponent } from './page/articolo-spesa/articolo-spesa.component';
import { ProvaComponent } from './zProve/prova/prova.component';
import { ArticoliCaroselloComponent } from './component/articoli-carosello/articoli-carosello.component';
import { ArticoliCardComponent } from './component/articoli-card/articoli-card.component';
import { CarrelloComponent } from './page/carrello/carrello.component';
import { CheckoutComponent } from './page/checkout/checkout.component';
import { AreaUtenteComponent } from './page/area-utente/area-utente.component';
import { SitoCaroselloComponent } from './component/sito-carosello/sito-carosello.component';
import { GrigliaLayoutComponent } from './component/griglia-layout/griglia-layout.component';
import { SpesaBreadcrumbComponent } from './component/spesa-breadcrumb/spesa-breadcrumb.component';
import { ArticoliCaratteristicheComponent } from './pagesAdmin/articoli-caratteristiche/articoli-caratteristiche.component';
import { ArticoliCaratteristicaComponent } from './pagesAdmin/articoli-caratteristica/articoli-caratteristica.component';
import { DialogComponent } from './component/dialog/dialog.component';
import { SnackbarComponent } from './component/snackbar/snackbar.component';
import { ArticoloFotoComponent } from './component/articolo-foto/articolo-foto.component';
import { MagazzinoMovimentiComponent } from './pagesAdmin/magazzino-movimenti/magazzino-movimenti.component';
import { MagazzinoDisponibilitaComponent } from './pagesAdmin/magazzino-disponibilita/magazzino-disponibilita.component';
import { CrudMovimentoMagazzinoComponent } from './componentAdmin/crud-movimento-magazzino/crud-movimento-magazzino.component';
import { OrdiniComponent } from './pagesAdmin/ordini/ordini.component';
import { CrudFornitoreComponent } from './componentAdmin/crud-fornitore/crud-fornitore.component';
import { OrdineComponent } from './pagesAdmin/ordine/ordine.component';

import { CrudOrdineDettaglioComponent } from './componentAdmin/crud-ordine-dettaglio/crud-ordine-dettaglio.component';
import { CrudUtentiIndirizziComponent } from './component/crud-utenti-indirizzi/crud-utenti-indirizzi.component';
import { AnteprimaCarrelloComponent } from './component/anteprima-carrello/anteprima-carrello.component';
import { ProvaArrayArticoliComponent } from './zProve/prova-array-articoli/prova-array-articoli.component';
import { ConclusionePagamentoComponent } from './page/conclusione-pagamento/conclusione-pagamento.component';
import { ElencoOrdiniUtenteComponent } from './page/elenco-ordini-utente/elenco-ordini-utente.component';
import { ElencoIndirizziUtenteComponent } from './page/elenco-indirizzi-utente/elenco-indirizzi-utente.component';
import { RimozioneNewsletterComponent } from './page/rimozione-newsletter/rimozione-newsletter.component';
import { ConclusioneOrdineComponent } from './page/conclusione-ordine/conclusione-ordine.component';
import { CondizioniDiVenditaComponent } from './page/condizioni-di-vendita/condizioni-di-vendita.component';
import { TerminiECondizioniComponent } from './page/termini-e-condizioni/termini-e-condizioni.component';
import { MieleComponent } from './page/blog/miele/miele.component';
import { RisoCarnaroliVillareggioComponent } from './page/blog/riso-carnaroli-villareggio/riso-carnaroli-villareggio.component';
import { BlogENewsComponent } from './page/blog-e-news/blog-e-news.component';
import { SportDeiColombiComponent } from './page/blog/sport-dei-colombi/sport-dei-colombi.component';
import { NewlsettersComponent } from './pagesAdmin/newlsetters/newlsetters.component';
import { FaqSpedizioneComponent } from './page/faq-spedizione/faq-spedizione.component';
import { BirraAmberaleLaBruciataComponent } from './page/blog/birra-amberale-la-bruciata/birra-amberale-la-bruciata.component';
import { MarzoInOrtoComponent } from './page/blog/marzo-in-orto/marzo-in-orto.component';
import { DialogMovMagazzinoComponent } from './pagesAdmin/dialog-mov-magazzino/dialog-mov-magazzino.component';
import { OrtoInCasaComponent } from './page/blog/orto-in-casa/orto-in-casa.component';



@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    NavbarComponent,
    FooterComponent,
    HomepageComponent,
    ContattaciComponent,
    ChiSiamoComponent,
    Errore404Component,
    LoginComponent,
    RegistratiComponent,
    DashboardComponent,
    ElencoUtentiComponent,
    ConfermaMailComponent,
    RecuperaPasswordComponent,
    ArticoliComponent,
    ArticoloComponent,
    FornitoriComponent,
    CategorieComponent,
    CategoriaComponent,
    UtenteComponent,
    SpesaArticoliComponent,
    ArticoloSpesaComponent,
    ProvaComponent,
    ArticoliCaroselloComponent,
    ArticoliCardComponent,
    CarrelloComponent,
    CheckoutComponent,
    AreaUtenteComponent,
    SitoCaroselloComponent,
    GrigliaLayoutComponent,
    SpesaBreadcrumbComponent,
    ArticoliCaratteristicheComponent,
    ArticoliCaratteristicaComponent,
    DialogComponent,
    SnackbarComponent,
    ArticoloFotoComponent,
    MagazzinoMovimentiComponent,
    MagazzinoDisponibilitaComponent,
    CrudMovimentoMagazzinoComponent,
    OrdiniComponent,
    CrudFornitoreComponent,
    OrdineComponent,
    CrudOrdineDettaglioComponent,
    CrudUtentiIndirizziComponent,
    AnteprimaCarrelloComponent,
    ProvaArrayArticoliComponent,
    ConclusionePagamentoComponent,
    ElencoOrdiniUtenteComponent,
    ElencoIndirizziUtenteComponent,
    RimozioneNewsletterComponent,
    ConclusioneOrdineComponent,
    CondizioniDiVenditaComponent,
    TerminiECondizioniComponent,
    MieleComponent,
    RisoCarnaroliVillareggioComponent,
    BlogENewsComponent,
    SportDeiColombiComponent,
    NewlsettersComponent,
    FaqSpedizioneComponent,
    BirraAmberaleLaBruciataComponent,
    MarzoInOrtoComponent,
    DialogMovMagazzinoComponent,
    OrtoInCasaComponent,    
  ],
  entryComponents: [DialogComponent, SnackbarComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    CarouselModule,
    NgxPaginationModule,
    NgxPrintModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ClipboardModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptService, multi: true }, { provide: MAT_DATE_LOCALE, useValue: 'it-IT' }, SEOService],
  bootstrap: [AppComponent]
})
export class AppModule { }
