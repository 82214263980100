<section class="container">
    <mat-card class="mat-elevation-z20">
        <mat-card-title class="text-center">
            <label *ngIf="tmpid==0">Nuova caratteristica</label>
            <label *ngIf="tmpid>0">Caratteristica: {{ caratteristica.titolo }}</label>
        </mat-card-title>
        <mat-card-content>
            <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salva(f)">
                <p>
                    <mat-form-field>
                        <mat-label>Titolo</mat-label>
                        <input matInput  id="titolo" formControlName="titolo">
                        <mat-hint align="end">{{titolo?.value?.length}} / 200</mat-hint>
                        <mat-hint *ngIf="titolo.touched && titolo.errors?.required" style="color: red;">Il titolo è obbligatorio</mat-hint>                        
                        <mat-hint *ngIf="titolo.touched && titolo.errors?.maxlenght" style="color: red;">il titolo deve essere massimo di 100 caratteri.</mat-hint>
                    </mat-form-field>
                </p>
                <p>
                    <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit">
                        <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
                    </button>
                    <button mat-flat-button color="warn" id=btnChiudi type="submit" class="pull-right"
                        (click)="chiudi()">
                        <i class="fa fa-times" aria-hidden="true"></i> Chiudi
                    </button>
                </p>
                <p class="alert alert-success" *ngIf="confermaMsg">
                    {{confermaMsg}}
                </p>
                <p class="alert alert-danger" *ngIf="erroreMsg">
                    {{erroreMsg}}
                </p>
            </form>
        </mat-card-content>
    </mat-card>
</section>
