import { Mail } from './../../modelli/shared/mail';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { authServerUrlMail } from 'src/app/modelli/app.contstant';

@Injectable({
  providedIn: 'root'
})
export class EmailDataService {

  constructor(private http:HttpClient) { }

  InviaMail(mail:Mail){
    return this.http.post<ApiMsg>(authServerUrlMail + "/invia",mail);
  }

  
}
