import { Component, OnInit } from '@angular/core';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { FiltroArticoli } from 'src/app/modelli/shared/Classi/Filtri/filtroArticoli';
import { ArticoliDataService } from 'src/app/services/data/articoli-data.service';

@Component({
  selector: 'app-orto-in-casa',
  templateUrl: './orto-in-casa.component.html',
  styleUrls: ['./orto-in-casa.component.css']
})
export class OrtoInCasaComponent implements OnInit {

  articoli: Articoli[];
  articoliSemina: Articoli[];
  articoliBlog: Articoli[] = [];
  articolo: Articoli;
  constructor(private articoliService: ArticoliDataService) { }

  ngOnInit(): void {
    let filtro: FiltroArticoli = new FiltroArticoli(65, 0, null, 0, 0, 0, '', false)
    this.articoliService.cercaArticoliConFiltri(filtro).subscribe(
      response => {
        this.articoli = response;
      }
    )


  }

}
