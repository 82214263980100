<section>
    <div class="container" >
        <div class="col-lg-12 bg-light text-center mt-5 ">
            <mat-icon class="font-alto-icone bold mt-5">shopping_bag</mat-icon>
            <p class="text-success font-alto pt-3 pb-5">
           CONDIZIONI DI VENDITA
            </p>
        </div>
        
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">ORDINI</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                <b>A)</b> Trasmettendo un ordine attraverso questo sito web, via fax o via telefono, voi vi impegnate ad acquistare i prodotti che avete selezionato, conformemente alle presenti Condizioni di Vendita. <br><br>
                <b>B)</b> Gli ordini una volta trasmessi al sistema non possono più essere modificati o bloccati, pertanto è vostra responsabilità accertarvi della loro correttezza prima di darne conferma.<br><br> 
                <b>C)</b> Per gli ordini eseguiti attraverso questo sito al momento della conferma vi verrà inviata sulla vostra casella di posta elettronica una mail di conferma dell’ordine appena effettuato.<br><br>
                <b>D)</b> Molino Lucconi Stefano offre i suoi prodotti su questo sito entro il limite della loro disponibilità di stock. <br><br>
                <b>E)</b> Molino Lucconi Stefano si riserva il diritto, a propria discrezione, di rifiutare gli ordini, in particolare in caso di precedenti fatture non pagate.<br><br>
                <b>F)</b> I dati registrati da Molino Lucconi Stefano costituiranno prova dell’ordine e dell’intera transazione. I dati registrati attraverso il sistema di pagamento costituiranno prova della transazione finanziaria.<br><br>
            </p>

        </div>

        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">PREZZO</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                <b>A)</b> I prezzi applicabili per prodotti da voi ordinati sono quelli che risultano su questo sito alla data in cui avete trasmesso l’ordine.<br> Tali prezzi sono da intendersi IVA inclusa. Non sono incluse invece le spese di spedizione. <br><br>
                <b>B)</b> Le spese di consegna sono fatturate sulla base delle tariffe indicate su questo sito alla data in cui avete trasmesso l’ordine.<br><br>
                <b>C)</b> Molino Lucconi Stefano si riserva il diritto di modificare in ogni momento i suoi prezzi e le spese di spedizione e di annullare un ordine in caso di errore evidente nel sistema di calcolo o nei dati.<br><br>
            </p>

        </div>
       
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">CONSEGNA DELLA MERCE</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                <b>A)</b> Tutti gli ordini eseguiti saranno consegnati all’indirizzo di spedizione da voi specificato al momento della trasmissione dell’ordine. <br><br>
                <b>B)</b> La data per la consegna sarà da intendersi come preferenziale ma non vincolante per Molino Lucconi Stefano, che in ogni caso farà quanto possibile per garantire il puntuale rispetto.<br><br>
                <b>C)</b> Le consegne possono essere effettuate esclusivamente in Italia. <br><br>
                <b>D)</b> E’ vostra responsabilità verificare la quantità e il buono stato della merce al momento della consegna. <br><br>
                <b>E)</b> Nel caso in cui riscontriate che la merce è danneggiata o non conforme ai quantitativi ordinati, dovrete segnalarlo al trasportatore e firmare con riserva il documento di consegna oltre che informarne Molino Lucconi Stefano entro 3 giorni dalla consegna. Molino Lucconi Stefano in questo caso provvederà, previa verifica, alla eventuale sostituzione della merce mancante o danneggiata, conformemente al vostro ordine.<br><br>
               
            </p>

        </div>
       

        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">MODALITÀ DI PAGAMENTO E FATTURAZIONE</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                <!-- <b>A)</b> Potete pagare al momento dell'ordine tramite carta di credito oppure con PayPal. Per questi metodi non sono previste spese aggiuntive.<br><br> -->
                <b>A)</b> Potete pagare al momento dell'ordine tramite bonifico bancario. Per questi metodi non sono previste spese aggiuntive.<br><br>
                <b>B)</b> Le fatture vengono inviate unitamente alla merce.<br><br>
            </p>

        </div>
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">RAFFIGURAZIONI PRODOTTI</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                <b>A)</b> Le immagini dei prodotti e delle modalità di consumo presenti sul sito Molino Lucconi Stefano sono da considerarsi puramente illustrative e non vincolanti.<br><br>
            </p>

        </div>
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">FORZA MAGGIORE</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                <b>A)</b>Molino Lucconi Stefano farà tutti gli sforzi necessari per mantenere fede alle proprie obbligazioni. Tuttavia, Molino Lucconi Stefano non può essere ritenuta responsabile per ritardi o mancata consegna provocata da circostanze al di fuori del suo ragionevole controllo. Tali circostanze includono scioperi, guerre, catastrofi naturali e qualsiasi altro evento che rende impraticabile la produzione, il trasporto o la consegna dei prodotti.
            </p>

        </div>
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">LUOGO DI APPLICAZIONE DELLA LEGGE E FORO COMPETENTE</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                <b>A)</b> Le presenti Condizioni di Vendita sono disciplinate dalla legge Italiana. Per tutto quanto non specificamente indicato nelle presenti Condizioni si richiama il D.lgs. 185/99 “attuazione della direttiva 97/7 relativa alla protezione dei consumatori in materia di contratti a distanza”. <br><br>
                <b>B)</b> Per ogni controversia riguardante le presenti Condizioni o la compravendita dei prodotti, sarà competente il Tribunale di Milano.<br><br>
            </p>

        </div>
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">DIRITTO DI RECESSO</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                <b>A)</b> Ai sensi dell'art. 52 D. Lgs. 206/2005, in materia di vendite a distanza, il Consumatore ha diritto di recedere dal contratto di acquisto per qualsiasi motivo e senza fornire spiegazioni, alle condizioni di seguito esposte. Per esercitare tale diritto, il cliente dovrà inviare la lettera allegata a mezzo lettera raccomandata A.R., entro 14 giorni lavorativi dalla data di ricevimento della merce. La comunicazione dovrà essere indirizzata a: Molino Lucconi Stefano, via Parco Visconteo 4, 27010 Zeccone PV.<br><br>
                <b>B)</b> Molino Lucconi Stefano accetterà esclusivamente i resi di merce in buono stato e nel loro imballo originale, accompagnati dalla relativa fattura. La merce dovrà poi essere restituita, allo stesso indirizzo, entro 30 giorni dalla data di consegna della merce stessa. I costi di trasporto per la restituzione della merce sono a carico del Consumatore.<br><br>
                <b>C)</b> Nel caso in cui i resi siano stati effettuati conformemente a quanto sopra descritto, Molino Lucconi Stefano rimborserà il prezzo della merce restituita al netto delle spese di spedizione, entro un massimo di 30 giorni dal ricevimento del reso.<br><br>
            </p>
           
        </div>
        <div class="text-center mt-5">
            <button type="button" class="btn btn-success" (click)="downloadMyFile()">Scarica lettera di recesso</button> 
        </div>

    </div>    
    
</section>

