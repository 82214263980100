<section class="container">
  <mat-card class="mat-elevation-z20" *ngIf="!isRecuperaPassword">
    <mat-card-title class="text-center">Effettua la login</mat-card-title>
    <mat-card-content>
      <form [formGroup]="f" (ngSubmit)="f.valid && gestAuth()">
        <p>
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput id="username" formControlName="username">
            <mat-hint align="end">Username di accesso</mat-hint>
            <mat-hint *ngIf="username.touched && username.errors?.required" style="color: red;">La username è
              obbligatoria</mat-hint>
            <mat-hint *ngIf="username.touched && username.errors?.email" style="color:red">Inserire una mail corretta
            </mat-hint>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput id="password" type="password" formControlName="password"
              [type]="hidePassword ? 'password' : 'text'">
            <mat-icon class="puntatore-mano" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ?
              'visibility_off' : 'visibility'}}</mat-icon>
            <mat-hint align="end">Password di accesso</mat-hint>
            <mat-hint *ngIf="password.touched && password.errors?.required" style="color: red;">La password è
              obbligatoria</mat-hint>
          </mat-form-field>
        </p>
        <p>
          <button mat-button color="primary" class="pull-right"
            (click)="isRecuperaPassword=true;this.funzioniLayout.gotoTopPage()" type="button">Recupera
            password</button>
        </p>
        <p>
          <button mat-flat-button color="primary" [disabled]="!f.valid" type="submit">Accedi</button>
        </p>
        <p *ngIf="!autenticato" class="text-danger">
          {{ errorMsg }}
        </p>
        <p *ngIf="isConfermare" class="text-danger">
          {{ confermaMsg }}
        </p>
        <p *ngIf="messaggeMailInviata!=''" class="text-success">
          {{ messaggeMailInviata }}
        </p>
      </form>
    </mat-card-content>
  </mat-card>
  <mat-card class="mat-elevation-z20" *ngIf="isRecuperaPassword">
    <mat-card-title class="text-center">Recupera password</mat-card-title>
    <mat-card-content>
      <form [formGroup]="fRecuperaPassword" (ngSubmit)="fRecuperaPassword.valid && recuperoPassword()">
        <p>
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput id="email" formControlName="email">
            <mat-hint align="end">Email per il recupero della password</mat-hint>
            <mat-hint *ngIf="email.touched && username.errors?.required" style="color: red;">L'email è
              obbligatoria</mat-hint>
            <mat-hint *ngIf="email.touched && username.errors?.email" style="color:red">Inserire una mail corretta
            </mat-hint>
          </mat-form-field>
        </p>
        <p>
          <button mat-flat-button color="primary" [disabled]="!fRecuperaPassword.valid" type="submit">Invia</button>
        </p>
      </form>
    </mat-card-content>
  </mat-card>
</section>
<div class="container mat-elevation-z20 mt-5 text-center interlinea">
  <p class="text-uppercase lead p-4">Non hai un account?<br>Non preoccuparti, <a class="text-success"
      [routerLink]="['/registrati']">Registrati qui!</a></p>
</div>