import { Component, OnInit } from '@angular/core';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { ArticoliDataService } from 'src/app/services/data/articoli-data.service';

@Component({
  selector: 'app-riso-carnaroli-villareggio',
  templateUrl: './riso-carnaroli-villareggio.component.html',
  styleUrls: ['./riso-carnaroli-villareggio.component.css']
})
export class RisoCarnaroliVillareggioComponent implements OnInit {
  articolo: Articoli;

  constructor(private articoliService: ArticoliDataService) { }

  ngOnInit(): void {
    this.articoliService.cercaArticoloByDicituraUrl('riso-carnaroli-di-villareggio-1kg').subscribe(
      response=>{
        this.articolo=response;
       
        
      }
    )
  }

}
