import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export class IubendaJson{
  constructor(
    public success:boolean,
    public content:string
  ){}
}

@Injectable({
  providedIn: 'root'
})
export class IubendaDataService {

  constructor(private http:HttpClient) { }

  privacyPolicy(){
    return this.http.get<IubendaJson>("https://www.iubenda.com/api/privacy-policy/89678986")
  }

  cookiePolicy(){
    return this.http.get<IubendaJson>("https://www.iubenda.com/api/privacy-policy/89678986/cookie-policy")
  }
}
