import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backServerUrlUArticoliCaratteristiche } from 'src/app/modelli/app.contstant';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { UArticoliCaratteristiche } from 'src/app/modelli/shared/UArticoliCaratteristiche';

@Injectable({
  providedIn: 'root'
})
export class UarticoliCaratteristicheDataService {

  constructor(private http:HttpClient) { }

  cercaUCaratteristicheByCodArt(codart:string){
    return this.http.get<UArticoliCaratteristiche[]>(backServerUrlUArticoliCaratteristiche + "/cerca/codart/" + codart );
  }

  inserisciUCaratteristica(uCaratteristica:UArticoliCaratteristiche){
    return this.http.post<ApiMsg>(backServerUrlUArticoliCaratteristiche + "/inserisci",uCaratteristica);
  }

  eliminaCaratteristica(id:number){
    return this.http.delete<ApiMsg>(backServerUrlUArticoliCaratteristiche + "/elimina/" + id)
  }
}
