<div class="body">
    <div style="text-align: -webkit-center;">
        <div class="col-lg-6 text-center pt-4">
            <img class="img-fluid mat-elevation-z20 w-75" src="/assets/img/blog/come-fare-orto-in-casa-semenze-online-molino-lucconi.png">
        </div>
    </div>

    <div class="container text-left">
        <div class=" mat-elevation-z20 px-5 pb-3 mx-3 mt-4 white">
            <div class=" py-5">
                <p class="testo-blog lead  ">
                    Negli ultimi tempi si è notato un incremento nella coltivazione di orti casalinghi.<br> Non solo per chi vive in campagna e ha a disposizione del terreno, ma anche per chi vive in città, utilizzando balconi o terrazzi e coltivando in vasi o fioriere.<br> E’ un ritorno alle origini, ad una vita semplice che aiuta a combattere lo stress dei giorni nostri.<br> Ecco alcuni motivi importanti che hanno spinto molte persone a scegliere questa strada:
                </p>
                <h1 style="color: #5e2e8e;">L’ORTO FA BENE ALL’AMBIENTE</h1>
                <p class="testo-blog lead  ">
                    L’autoproduzione di prodotti che di solito compriamo al supermercato non può che fare bene all’ambiente e alla biodiversità del nostro pianeta. In questo modo andiamo a diminuire le coltivazioni intensive che sfruttano le risorse del terreno.
                </p>
                <h1 style="color: #5e2e8e;">PRODOTTI PIU' SANI</h1>
                <p class="testo-blog lead  ">
                    I frutti coltivati nell’orto di casa sono più buoni e più sani. Oltre al controllo della coltura, aiutandoci con prodotti biologici, abbiamo anche uno sviluppo della pianta in modo naturale, e questo favorisce un apporto di sostanze che rendono il raccolto impareggiabile rispetto a verdure coltivate in modo forzato.
                </p>
                <h1 style="color: #5e2e8e;">CIBO DI STAGIONE</h1>
                <p class="testo-blog lead  ">
                    Ogni periodo dell’anno ha i suoi prodotti. Il tuo km0 privato, come si faceva una volta quando si viveva in armonia con la natura. Inoltre consumare cibo di stagione apporta al nostro organismo un maggior numero di nutrienti rispetto a frutti coltivati contro natura.
                </p>
                <h1 style="color: #5e2e8e;">RISPARMIARE ENERGIA</h1>
                <p class="testo-blog lead  ">
                    Le colture intensive sfruttano in maniera massiccia il terreno e hanno bisogno di enormi quantità di risorse ed energia. Coltivando un orto in casa quindi andiamo a ridurre anche l’impatto energetico. 
                </p>
                <h1 style="color: #5e2e8e;">ANTISTRESS</h1>
                <p class="testo-blog lead  ">
                    Un modo naturale per staccarsi dallo stress a cui siamo costantemente sottoposti. Un buon pretesto per staccare la spina e dedicare il tempo libero ad un’attività rilassante e gratificante all’aria aperta.
                </p>
                <h1 style="color: #5e2e8e;">CAPIRE LA NATURA</h1>
                <p class="testo-blog lead  ">
                    Un buon modo per riscoprire i ritmi e le esigenze della natura. I periodi di semina, le lune, i trapianti, e adoperare metodi tramandati da generazioni.
                </p>
                <p class="testo-blog lead  ">
                    Nel nostro shop on-line puoi trovare sia alcuni attrezzi indispensabili per la buona riuscita del tuo lavoro, sia un’ottima selezione di semenze marchiate <a href="https://franchisementi.it/calendario/" target="_blank" style="color:#3baa3b;">FRANCHI SEMENTI</a>. Ditta Italiana presente sul territorio dal 1783 e leader nel settore. Scorpi nei link sottostanti i suoi consigli abbinati alle varie fasi dell’anno.
                </p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="text-center pt-5">
        <h1 style="color: rgb(0, 0, 0);">LE SEMENZE:</h1>
    </div>
    <div class="row centrare my-5">
        <div class="col-sm-12 col-md-6 col-lg-4 align-self-stretch " *ngFor="let articolo of articoli">
            <app-articoli-card [articolo]="articolo" [visualizzazione]="2"></app-articoli-card>
        </div>

    </div>
</div>

</div>