<nav class="navbar navbar-expand-lg navbar-light bg-light mb-3 sticky-top">
  <a class="navbar-brand" [routerLink]="'/'">
    <img class="img-fluid w-50" src="assets/img/molino-lucconi-logo.jfif">
  </a>
  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>
  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/'" (click)="isMenuCollapsed = true">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/chi-siamo'" (click)="isMenuCollapsed = true">Chi siamo</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/blog-e-news'" (click)="isMenuCollapsed = true">Blog e News</a>
      </li>
      <li class="nav-item" ngbDropdown>
        <a ngbDropdownToggle class="nav-link  dropdown-toggle thumbnail" type="button" id="dropdownMenu1"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          Prodotti
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
          <a ngbDropdownItem class="puntatore-mano" *ngFor="let categoria of categorie"
            (click)="vaiCategoria(categoria.id)" (click)="isMenuCollapsed = true">{{categoria.titolo}}</a>
        </div>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="'/spesa-online'" (click)="isMenuCollapsed = true">Fai la tua spesa</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/contattaci'" (click)="isMenuCollapsed = true">Contattaci</a>
      </li>
    </ul>
    <ul class="navbar-nav" *ngIf="AuthJWT.isLogged() && AuthJWT.CurrentRole">
      <li class="nav-item" ngbDropdown>
        <button ngbDropdownToggle class="btn btn-default dropdown-toggle thumbnail" type="button" id="dropdownMenu1"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          <mat-icon aria-hidden="false" aria-label="Example home icon">admin_panel_settings</mat-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
          <a ngbDropdownItem [routerLink]="['/amm/dashboard']" (click)="isMenuCollapsed = true">Dashboard</a>
          <a ngbDropdownItem [routerLink]="['/amm/utenti']" (click)="isMenuCollapsed = true">Utenti</a>
          <a ngbDropdownItem [routerLink]="['/amm/newsletters']" (click)="isMenuCollapsed = true">Newsletters</a>
          <a ngbDropdownItem [routerLink]="['/amm/articoli']" (click)="isMenuCollapsed = true">Articoli</a>
          <a ngbDropdownItem [routerLink]="['/amm/fornitori']" (click)="isMenuCollapsed = true">Fornitori</a>
          <a ngbDropdownItem [routerLink]="['/amm/categorie']" (click)="isMenuCollapsed = true">Categorie
            mercelogiche</a>
          <a ngbDropdownItem [routerLink]="['/amm/caratteristiche']" (click)="isMenuCollapsed = true">Caratteristiche
            articoli</a>
          <a ngbDropdownItem [routerLink]="['/amm/magazzinomovimenti']" (click)="isMenuCollapsed = true">Movimenti
            magazzino</a>
          <a ngbDropdownItem [routerLink]="['/amm/magazzinodisponibilita']" (click)="isMenuCollapsed = true">Inventario
            magazzino</a>
          <a ngbDropdownItem [routerLink]="['/amm/ordini']" (click)="isMenuCollapsed = true">Ordini</a>
          <a ngbDropdownItem [routerLink]="['/prove']" (click)="isMenuCollapsed = true">Pagina per prove</a>
        </div>
      </li>

    </ul>

    <ul class="navbar-nav">
      <!-- <li class="nav-item" ngbDropdown>
        <button ngbDropdownToggle class="btn btn-default dropdown-toggle thumbnail" type="button" id="dropdownMenu1"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          <mat-icon aria-hidden="false" aria-label="Example home icon">person</mat-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
          <a ngbDropdownItem [routerLink]="['/utente/area-utente']" *ngIf="AuthJWT.isLogged()">Area utente</a>
          <a ngbDropdownItem [routerLink]="['/login']" *ngIf="!AuthJWT.isLogged()">Accedi</a>
          <a ngbDropdownItem [routerLink]="['/registrati']" *ngIf="!AuthJWT.isLogged()">Registrati</a>
          <a ngbDropdownItem [routerLink]="['']" *ngIf="AuthJWT.isLogged()" (click)="logout()">Logout
            {{ AuthJWT.loggedNomeUsers() }}</a>
        </div>
      </li> -->
      <li class="nav-item">
        <a ngbDropdownItem [routerLink]="['/utente/area-utente']" *ngIf="AuthJWT.isLogged()" class="text-success"
          (click)="isMenuCollapsed = true">Area utente</a>
        <a ngbDropdownItem [routerLink]="['/login']" *ngIf="!AuthJWT.isLogged()" class="text-success"
          (click)="isMenuCollapsed = true">Accedi</a>
        <!-- <a ngbDropdownItem [routerLink]="['/registrati']" *ngIf="!AuthJWT.isLogged()" class="text-success"
          (click)="isMenuCollapsed = true">Registrati</a> -->
        <a ngbDropdownItem [routerLink]="['']" *ngIf="AuthJWT.isLogged()" (click)="logout()" class="text-success"
          (click)="isMenuCollapsed = true">Logout
          {{ AuthJWT.loggedNomeUsers() }}</a>

      </li>
    </ul>
    <div class="row  p-2 mx-5">
      <div class="col-lg-10">
        <form *ngIf="f" [formGroup]="f">
          <mat-form-field class="example-form-field">
            <mat-label>
              <mat-icon>search</mat-icon>
              Cerca
            </mat-label>
            <input matInput id="keySearchNavBar" formControlName="keySearchNavBar"
              (keyup.enter)="cercaArticoliKeySearch()">
          </mat-form-field>
        </form>
      </div>
      <div class="col-lg-2 vai">
        <button class="btn" type="button" (click)="cercaArticoliKeySearch()" (click)="isMenuCollapsed = true"><i class="fa fa-search"></i></button>
      </div>
    </div>

  </div>
  <!-- <button class="btn btn-default border mat-elevation-z20" type="button" (click)="vaiCarrello()">
    <mat-icon aria-hidden="false" aria-label="Example home icon" class="mt-3">shopping_cart</mat-icon>
    <label *ngFor="let importo of importoCarrello" style="cursor: pointer;">
      {{importo.text | currency: 'EUR':'symbol' }}</label>
  </button> -->
</nav>