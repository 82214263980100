import { LoadingService } from './../../services/app/loading.service';
import { FunzioniLayout } from './../../modelli/shared/Funzioni/funzioniLayout';
import { ArticoliDataService } from './../../services/data/articoli-data.service';
import { Articoli } from './../../modelli/shared/articoli';
import { Causale } from './../../componentAdmin/crud-movimento-magazzino/crud-movimento-magazzino.component';
import { FiltroMagazzinoMovimento } from 'src/app/modelli/shared/Classi/Filtri/filtroMagazzinoMovimento';
import { MagazzinoMovimentoDataService } from './../../services/data/magazzino-movimento-data.service';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MagazzinoMovimento } from 'src/app/modelli/shared/magazzinoMovimento';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { CrudMovimentoMagazzinoComponent } from 'src/app/componentAdmin/crud-movimento-magazzino/crud-movimento-magazzino.component';
import { DialogClass } from 'src/app/modelli/shared/Classi/Componenti/dialogClass';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/component/dialog/dialog.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-magazzino-movimenti',
  templateUrl: './magazzino-movimenti.component.html',
  styleUrls: ['./magazzino-movimenti.component.css']
})
export class MagazzinoMovimentiComponent implements OnInit {
  displayedColumns: string[] = ['data', 'codArt', 'articolo.titolo', 'causale', 'qta', 'visualizza', 'modifica', 'elimina'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('vc', { read: ViewContainerRef }) vcr: ViewContainerRef;

  movimenti: MagazzinoMovimento[];
  fFiltro: FormGroup;
  filtroMovimenti:FiltroMagazzinoMovimento;

  causali:Causale[];
  fCausali;
  articoli:Articoli[];
  fArticoli;
  apiMsg: ApiMsg;


  constructor(private movimentiService: MagazzinoMovimentoDataService, private snackBar: MatSnackBar, private cfr: ComponentFactoryResolver, 
    private dialog: MatDialog, private articoliService:ArticoliDataService) { }

  ngOnInit(): void {
    this.popolaCombo();
    let tmpAl: Date = new Date();
    let tmpDal: Date = new Date(tmpAl.getFullYear(), tmpAl.getMonth(), 1);
    this.fFiltro = new FormGroup({
      'dataDal': new FormControl(tmpDal),
      'dataAl': new FormControl(tmpAl),
      'causale': new FormControl(""),
      'codArt': new FormControl("")
    })
    this.filtroMovimenti = new FiltroMagazzinoMovimento(0,"",tmpDal,tmpAl,"");
    this.popolaTabella();
  }

  get dataDal() {
    return this.fFiltro.get('dataDal');
  }
  get dataAl() {
    return this.fFiltro.get('dataAl');
  }
  get causale() {
    return this.fFiltro.get('causale');
  }
  get codArt() {
    return this.fFiltro.get('codArt');
  }

  popolaTabella() {
    this.movimentiService.listaFiltrata(this.filtroMovimenti).subscribe(
      response => {
        this.movimenti = [];
        this.movimenti = response;
        this.dataSource = null;
        this.dataSource = new MatTableDataSource(this.movimenti);

        this.dataSource.filterPredicate = (data: MagazzinoMovimento, filter: string) => {
          return data.articolo.titolo.toLocaleLowerCase().includes(filter)
        }

        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
          return this.getPropertyByPath(data, sortHeaderId);
        };

        this.dataSource.paginator = this.paginator;
      }, error => {

      }, () => {

      }
    )
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  popolaCombo(){
    
     //Popolo la tendina causali
     this.causali = [];
     this.causali.push(new Causale("C", "Carico"))
     this.causali.push(new Causale("S", "Scarico"))
     this.causali.push(new Causale("V", "Vendita"))
     this.fCausali = this.causali.slice();
 
     //Popolo la tendina articoli
     this.articoli = [];
     this.articoliService.elencoArticoliCompleto().subscribe(
       response => {
         this.articoli = response;
         this.fArticoli = this.articoli.slice();    
       }
     )
  }

  filtra(){
    if (isNaN(Date.parse(this.dataDal.value)) || isNaN(Date.parse(this.dataAl.value))) {
      return null;
    }

    let tmpDal = new Date(this.dataDal.value);
    let tmpAl = new Date(this.dataAl.value);
    this.filtroMovimenti.dataDal = tmpDal;
    this.filtroMovimenti.dataAl = tmpAl;

    this.filtroMovimenti.causale=this.causale.value;
    this.filtroMovimenti.codArt=this.codArt.value;

    this.popolaTabella();
  }


  eliminaMovimento(mov: MagazzinoMovimento) {
    if (this.bloccoApriElimnaMovimento(mov)==false) {
      this.movimentiService.elimina(mov.id).subscribe(
        response => {
          this.apiMsg = response;
          let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
          let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
          this.popolaTabella();
        }
      )
    }

  }

  apriMovimento(mov: MagazzinoMovimento) {
    if (this.bloccoApriElimnaMovimento(mov)==false) {
      let componentFactory = this.cfr.resolveComponentFactory(CrudMovimentoMagazzinoComponent);
      this.vcr.clear();
      let component = this.vcr.createComponent(componentFactory);
      if(mov==null){
        component.instance.id = 0;
      }else{
        component.instance.id = mov.id;
      }
      
      //Rimango in ascolto della variabile finito del figlio, quando questa diventa true distruggo il figlio
      component.instance.finito.subscribe(
        response => {
          if (response == true) {
            this.vcr.clear();
            this.popolaTabella();
          }
        }
      )
    }

  }

  bloccoApriElimnaMovimento(mov: MagazzinoMovimento) {
    //Se mi arriva il mov a null vuol dire che sto inserendo un movimento nuovo e ritorno false
    if(mov==null){
      return false
    }

    //Se il movimento è di vendita gli blocco le operazioni
    if (mov.causale == "V") {
      let snackBarData: SnackBarClass = new SnackBarClass("Impossibile modificare o eliminare il movimento di magazzino perchè deriva da un ordine.", false)
      let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 4000 });
      return true;
    }
    return false;
  }

  visualizza(mov: MagazzinoMovimento) {
    let contenuto: string = "id: " + mov.id + "<br>";
    contenuto += "codArt: " + mov.codArt + "<br>";
    contenuto += "qta: " + mov.qta + "<br>";
    contenuto += "data: " + mov.data + "<br>";
    contenuto += "TimeStamp: " + mov.timeStamp + "<br>";
    contenuto += "userName: " + mov.userName + "<br>";
    contenuto += "causale: " + mov.causale + "<br>";
    contenuto += "nota: " + mov.nota + "<br>";
    contenuto += "disponibilità: " + mov.articolo.disponibilita.qta + "<br>"
    let dialogData: DialogClass = new DialogClass("Visualizzazione dati", contenuto, true, false, false)
    let dialogRef = this.dialog.open(DialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(
      response => {
      }
    )
  }

  applicaFiltro(filtro: string) {
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

}
