import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Articoli } from 'src/app/modelli/shared/articoli';

@Component({
  selector: 'app-articoli-carosello',
  templateUrl: './articoli-carosello.component.html',
  styleUrls: ['./articoli-carosello.component.css']
})
export class ArticoliCaroselloComponent implements OnInit {
  @Input ('articoli') articoli: Articoli[];
  @Input ('visualizzazione') visualizzazione:number;

  constructor(private router: Router) { }

  ngOnInit(): void {        
  }

  vaiArticolo(nomeUrlCategoria: string, nomeUrlArticolo: string) {
    this.router.navigate(['spesa-online', nomeUrlCategoria, nomeUrlArticolo]);
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: true,
    //In base alla larghezza vengono decisi quanti riquadri visualizzare
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      }
    },    
  }

}
