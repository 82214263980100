import { ApiMsg } from './../../modelli/shared/apiMsg';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { ArticoliFoto } from 'src/app/modelli/shared/articoliFoto';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { ArticoliFotoDataService } from 'src/app/services/data/articoli-foto-data.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-articolo-foto',
  templateUrl: './articolo-foto.component.html',
  styleUrls: ['./articolo-foto.component.css']
})
export class ArticoloFotoComponent implements OnInit {
  articoliFoto: ArticoliFoto[];
  fileSelezionato = null;
  chkPrimario: boolean = false;
  primarioInserito:boolean=false;
  apiMsg:ApiMsg;

  @Input ('articolo') articolo: Articoli;

  constructor(private fotoService: ArticoliFotoDataService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {      
    this.caricaFoto();
    
  }

  caricaFoto() {    
    this.articoliFoto = []
    this.fotoService.cercaByCodart(this.articolo.codArt).subscribe(
      response => {
        this.articoliFoto = response;
        //Verifico se il primario è già stato inserito e nel caso non visualizzo il checkbox relativo
        if(this.articoliFoto[0].posizione==1){
          this.primarioInserito=true;
        }else{
          this.primarioInserito=false;
        }        

      },error=>{
        this.primarioInserito=false;
      }
    )
    this.chkPrimario = false;
  }

  onFileSelected(event) {
    this.fileSelezionato = <File>event.target.files[0]
  }

  carica() {
    if (this.fileSelezionato != null) {
      let posizione: number = 0; if (this.chkPrimario) { posizione = 1 };
      let articoloFoto: ArticoliFoto = new ArticoliFoto(0, this.articolo.codArt, this.fileSelezionato, posizione,"")
      this.fotoService.inserisciFoto(articoloFoto).subscribe(
        response => {
          this.caricaFoto();
          this.fileSelezionato=null;
          this.apiMsg=response;
          this.visualizzaSnackBar();
        },error=>{
          this.apiMsg=error.error;
          
          
          this.visualizzaSnackBar();
        }
      )
    }
  }

  visualizzaSnackBar(){
    let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }

  eliminaFoto(id: number) {
    this.fotoService.eliminaFoto(id).subscribe(
      response => {
        this.caricaFoto();
      }
    )

  }
}
