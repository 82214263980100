import { ApiMsg } from './../../modelli/shared/apiMsg';
import { PatchMethod } from './../../modelli/shared/patchMethod';
import { FiltroOrdini } from './../../modelli/shared/Classi/Filtri/filtroOrdini';
import { backServerUrlOrdineTestata } from './../../modelli/app.contstant';
import { OrdiniTestata } from './../../modelli/shared/ordiniTestata';
import { TrasformaCarrelloInOrdine } from './../../modelli/shared/modelsMetodi/trasformaCarrelloInOrdine';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrdinitestataDataService {

  constructor(private http:HttpClient) { }

  cercaByID(id:number){
    return this.http.get<OrdiniTestata>(backServerUrlOrdineTestata + "/cerca/id/" + id)
  }

  listaFiltrata(filtro:FiltroOrdini){
    return this.http.post<OrdiniTestata[]>(backServerUrlOrdineTestata + "/listafiltrata",filtro);
  } 

  creaOrdineDaCarrello(trasformaCarrelloInOrdine: TrasformaCarrelloInOrdine){
    return this.http.post<OrdiniTestata>(backServerUrlOrdineTestata + "/inseriscidacarrello",trasformaCarrelloInOrdine);
  }  

  impostaStatoOrdine(id:number,campi:PatchMethod[]){
    return this.http.patch<OrdiniTestata>(backServerUrlOrdineTestata + "/impostaStato/" + id,campi);
  }

  impostaEliminato(id:number){
    return this.http.delete<ApiMsg>(backServerUrlOrdineTestata + "/elimina/" + id)
  }
    
}
