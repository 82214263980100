import { SnackbarComponent } from './../snackbar/snackbar.component';
import { SnackBarClass } from './../../modelli/shared/Classi/Componenti/snackbarClass';
import { FunzioniLocalStorage } from './../../modelli/shared/Funzioni/funzioniLocalStorage';
import { CarrelliDettaglio } from './../../modelli/shared/carrelliDettaglio';
import { AppCarrelloService } from './../../services/app/app-carrello.service';
import { ArticoliDataService } from '../../services/data/articoli-data.service';
import { ArticoliCategorie } from 'src/app/modelli/shared/articoliCategorie';
import { ArticoliCategorieDataService } from 'src/app/services/data/articoli-categorie-data.service';

import { Articoli } from 'src/app/modelli/shared/articoli';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-articoli-card',
  templateUrl: './articoli-card.component.html',
  styleUrls: ['./articoli-card.component.css']
})
export class ArticoliCardComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    
    
   
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },

   
  
  }
  @Input('articolo') articolo: Articoli
  @Input('visualizzazione') visualizzazione:number //1-Piccola 2-Grande
  quantita: number;

  constructor(private router: Router,  private categorieService: ArticoliCategorieDataService, private articoliService: ArticoliDataService, private appCarrello:AppCarrelloService,
    private FunzioniLS:FunzioniLocalStorage,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.quantita = 1;         
  }

  vaiCategoria(nomeUrlCategoria: string) {
    //Dal singolo url rilevo gli altri del padre
    this.categorieService.cercaByDicituraUrl(nomeUrlCategoria).subscribe(
      response => {
        let categoria: ArticoliCategorie = response;
        let url3: string = ""; let url2: string = ""; let url1: string = ""
        url1 = categoria.dicituraUrl 
        if (categoria.idPadre > 0) {
          url2 = categoria.padre.dicituraUrl
          if (categoria.padre.idPadre > 0) {
            url3 = categoria.padre.padre.dicituraUrl
          }
        }
        if (url3 != "") {
          this.router.navigate(['spesa-online/categoria', url3, url2, url1]);
        }
        else if (url2 != "") {
          this.router.navigate(['spesa-online/categoria', url2, url1]);
        }
        else if (url1 != "") {
          this.router.navigate(['spesa-online/categoria', url1]);
        }
      }
    )
  }

  vaiArticolo(nomeUrlArticolo: string) {    
    
    let url3: string = ""; let url2: string = ""; let url1: string = ""
    this.articoliService.cercaArticoloByDicituraUrl(nomeUrlArticolo).subscribe(
      response => {
        let articolo: Articoli = response;
        this.categorieService.cercaByDicituraUrl(articolo.categoria.dicituraUrl).subscribe(
          response => {
            let categoria: ArticoliCategorie = response;
            url1 = categoria.dicituraUrl
            if (categoria.idPadre > 0) {
              url2 = categoria.padre.dicituraUrl
              if (categoria.padre.idPadre > 0) {
                url3 = categoria.padre.padre.dicituraUrl
              }              
            }
            if (url3 != "") {
              this.router.navigate(['spesa-online/prodotto', url3, url2, url1,nomeUrlArticolo]);
            }
            else if (url2 != "") {
              this.router.navigate(['spesa-online/prodotto', url2, url1,nomeUrlArticolo]);
            }
            else if (url1 != "") {
              this.router.navigate(['spesa-online/prodotto', url1,nomeUrlArticolo]);
            }        
          }
        )

      }
    )

  }

  aggiungiAlCarrello(articolo: Articoli) {    
    let dettaglio:CarrelliDettaglio = new CarrelliDettaglio(0,this.FunzioniLS.getIdCart(),articolo.codArt,this.quantita,null);
    this.appCarrello.aggiungiRigaCarrello(dettaglio);        
    this.openSnackbar(articolo.titolo,this.quantita);
  }

  openSnackbar(titoloArticolo:string,qta:number){    
    let snackBarData: SnackBarClass = new SnackBarClass(titoloArticolo + " per " + qta + " aggiunto al carrello!",false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent,{data:snackBarData,duration:2000});
  }



  aggiungi1() {
    this.quantita += 1;
  }

  rimuovi1() {
    if (this.quantita > 1) {
      this.quantita -= 1;
    }
  }

}
