import { ApiMsg } from './../../modelli/shared/apiMsg';
import { FiltroMagazzinoDisponibilita } from './../../modelli/shared/Classi/Filtri/filtroMagazzinoDisponibilita';
import { backServerUrlMagazzinoDisponibilita } from './../../modelli/app.contstant';
import { MagazzinoDisponibilita } from './../../modelli/shared/magazzinoDisponibilita';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MagazzinoDisponibilitaDataService {

  constructor(private http:HttpClient) { }

  listaFiltrata(filtro:FiltroMagazzinoDisponibilita){
    return this.http.post<MagazzinoDisponibilita[]>(backServerUrlMagazzinoDisponibilita + "/lista",filtro);
  }

  rialiineaDisponibilita(){
    return this.http.get<ApiMsg>(backServerUrlMagazzinoDisponibilita + "/riallinea");
  }

  riallineaDisponibilitaCodArt(codart:string){
    return this.http.get<number>(backServerUrlMagazzinoDisponibilita + "/riallinea/" + codart)
  }
}
