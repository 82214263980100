import { Component, OnInit } from '@angular/core';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { FiltroArticoli } from 'src/app/modelli/shared/Classi/Filtri/filtroArticoli';
import { ArticoliDataService } from 'src/app/services/data/articoli-data.service';

@Component({
  selector: 'app-sport-dei-colombi',
  templateUrl: './sport-dei-colombi.component.html',
  styleUrls: ['./sport-dei-colombi.component.css']
})
export class SportDeiColombiComponent implements OnInit {
  articoli:Articoli[];
  constructor(private articoliService: ArticoliDataService) { }

  ngOnInit(): void {
    let filtro:FiltroArticoli= new FiltroArticoli(88,0,null,0,0,0,'',false)

    this.articoliService.cercaArticoliConFiltri(filtro).subscribe(
      response=>{
        this.articoli=response;
       
        
      }
    )
  }

}
