<section class="container" *ngIf="visualizzaHtml">
    <h1>Checkout</h1>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z20 text-center">
        <ng-container matColumnDef="titolo" class=" p-2">
            <th class="p-2 col-4 text-center" *matHeaderCellDef>TITOLO</th>
            <td class="p-2 col-4" *matCellDef="let row"> {{row.articolo.titolo}} </td>
        </ng-container>

        <ng-container matColumnDef="prezzo" class=" p-2">
            <th class="p-2 col-2 text-center" *matHeaderCellDef>€</th>
            <td class="p-2 col-2" *matCellDef="let row"> {{row.articolo.prezzo | currency: 'EUR':'symbol'}} </td>
        </ng-container>

        <ng-container matColumnDef="qta" class=" p-2">
            <th class="p-2 col-2 text-center" *matHeaderCellDef>QTA</th>
            <td class="p-2 col-2" *matCellDef="let row">{{row.qta}}</td>
        </ng-container>

        <ng-container matColumnDef="totale" class=" p-2">
            <th class="p-2 col-3 text-center" *matHeaderCellDef>TOT.</th>
            <td class="p-2 col-3" *matCellDef="let row"> {{row.articolo.prezzo * row.qta | currency: 'EUR':'symbol' }}
            </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </table>

    <div class="alert  border border-success mat-elevation-z20 text-center bg-white bold" role="alert">
        Totale carrello: {{ totaleCarrello | currency: 'EUR':'symbol' }}
    </div>
    <div class="alert border border-success mat-elevation-z20 text-center bg-white text-center" role="alert"
        *ngIf="totaleCarrello< appImpostazioni.importoPerSpedizioneGratuita && !ritiroInNegozio">
        <b> Totale spedizioni: {{ totaleSpedeSpedizione | currency: 'EUR':'symbol' }} </b> <br>Per la spedizione
        gratuita l'ordine deve essere di almeno {{ appImpostazioni.importoPerSpedizioneGratuita | currency:
        'EUR':'symbol' }}
    </div>
    <div class="alert alert-success mat-elevation-z20 text-center" role="alert"
        *ngIf="totaleCarrello>= appImpostazioni.importoPerSpedizioneGratuita && !ritiroInNegozio">
        Spedizione gratuita avendo raggiunto l'importo di {{ appImpostazioni.importoPerSpedizioneGratuita | currency:
        'EUR':'symbol' }}
    </div>
    <div class="alert bg-success text-center  text-uppercase text-white mat-elevation-z20" role="alert">
        Totale finale: {{ totaleFinale | currency: 'EUR':'symbol' }}
    </div>

    <form [formGroup]="f" style="margin-top: 20px;">
        <p>
            <mat-form-field>
                <mat-label>Messaggio per lo staff</mat-label>
                <textarea matInput rows="3" id="noteOrdine" formControlName="noteOrdine"></textarea>
                <mat-hint align="end">{{noteOrdine.value.length}} / 1000</mat-hint>
                <mat-hint *ngIf="noteOrdine.errors?.maxlength" style="color: red;">Il nominativo può essere massimo di
                    {{ noteOrdine.errors.maxlength.requiredLength }} caratteri</mat-hint>
            </mat-form-field>
        </p>
    </form>
    <p>
        <ng-template #vc></ng-template>
    </p>

    <div class="row" style="margin-bottom: 10px;">

        <div class="col-lg-6 border  ">
            <p class="w-100 text-uppercase px-4 mt-5 bold">Selezionare indirizzo di spedizione</p>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="tmpIdSpedizione">
                <mat-radio-button (click)="visualizzaMetodoPagamento(false)" class="example-radio-button  ng-star-inserted" [value]=0>
                    Ritiro in negozio
                </mat-radio-button>
                <div  *ngIf="!articoloSoloRitiroPresente">
                    <mat-radio-button (click)="visualizzaMetodoPagamento(true)" class="example-radio-button border border-success  ng-star-inserted"
                        *ngFor="let spedizione of indirizziSpedizione" [value]=spedizione.id
                        style="border-width: 1px 0px 1px 0px !important; width: 100%;">
                        {{ spedizione.nominativo }}<br>
                        {{ spedizione.indirizzo }} - {{ spedizione.cap }} {{ spedizione.comune }} ({{
                        spedizione.provincia }}) {{ spedizione.stato }}
                        <br>
                        Telefono: {{ spedizione.telefono }}
                    </mat-radio-button>
                </div>

            </mat-radio-group>
            <p class="text-right m-5" *ngIf="!articoloSoloRitiroPresente">
                <button type="button" class="btn btn-light text-white btn-sm viola " (click)="apriIndirizzo('S')"> +
                    Nuovo indirizzo spedizione</button>
            </p>
        </div>
        <div class="col-lg-6 border">
            <p class="m-3 ">
                <mat-checkbox class="example-margin" [(ngModel)]="voglioLaFattura">Voglio la fattura</mat-checkbox>
            </p>
            <div *ngIf="voglioLaFattura==true">
                <p class="w-100 text-uppercase px-4 mt-5 bold">Selezionare indirizzo di fatturazione</p>
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    [(ngModel)]="tmpIdFatturazione">
                    <mat-radio-button class="example-radio-button border border-success rounded ng-star-inserted"
                        *ngFor="let fatturazione of indirizziFatturazione" [value]=fatturazione.id
                        style="border-width: 2px 0px 2px 0px !important;">
                        {{ fatturazione.nominativo }}<br>
                        {{ fatturazione.indirizzo }} - {{ fatturazione.cap }} {{ fatturazione.comune }} ({{
                        fatturazione.provincia }}) {{ fatturazione.stato }}
                        <br>
                        Telefono: {{ fatturazione.telefono }}
                        <br>
                        Partita Iva: {{ fatturazione.fatturaPIVA }} - CF: {{ fatturazione.fatturaCodFisc }}
                        <br>
                        Pec: {{ fatturazione.fatturaPec }} - Codice Sdi: {{ fatturazione.fatturaCodiceDestinatario
                        }}
                    </mat-radio-button>
                </mat-radio-group>
                <p class="text-right m-5">
                    <button type="button" class="btn btn-light text-white btn-sm viola" (click)="apriIndirizzo('F')"> +
                        Nuovo indirizzo fatturazione</button>
                </p>
            </div>



        </div>
        <div class="col-lg-12">
            <ng-template #vc></ng-template>
        </div>
    </div>

    <hr>
    
    <div *ngIf="visualizzazioneMetodoDiPagamento">
        <p class="w-100 text-uppercase  mt-5 bold">Selezionare un metodo di pagamento</p>
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="tmpIDMetodoPagamento">
            <mat-radio-button class="example-radio-button" *ngFor="let metodo of tmpMetodiPagamento" [value]=metodo.id
                (click)="impostaRitiroInNegozio(metodo)">
                {{metodo.descrizione}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <hr>

    <p class="pb-3">
        <button mat-flat-button class="pull-left viola text-white" (click)="tornaAlCarrello()">
            Torna al carrello <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <button mat-flat-button color="primary" class="pull-right" type="button" (click)="concludiOrdine(f)">
            Concludi ordine <i class="fa fa-check-circle" aria-hidden="true"></i>
        </button>
    </p>


</section>