export class OrdiniDettaglio{
    constructor(
        public id:number,
        public idTestata:number,
        public prezzoSingolo:number,
        public quantita:number,
        public prezzoTotale:number,
        public descrizioneArticolo:string,
        public codArt:string
    ){}
}