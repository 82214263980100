<div class=" bg-success mt-5">
    <div class="">
        <div class="row">
            <div class="col-lg-2 text-center">
                <a class="navbar-brand m-5" [routerLink]="'/'">
                    <img class="img-fluid w-75" src="assets/img/molino-lucconi-logo.jfif">
                </a>
                <p class="text-white">Molino Lucconi Stefano</p>
                <p class="text-white">Sede legale: Via Parco Vecchio 4, 27010 Pavia (PV)</p>
                <p class="text-white">info@molinolucconi.it</p>
                <p class="text-white">P.IVA 01886910189</p>
                <p class="text-white">Codice destinatario: TULURSB</p>
            </div>
            <div class="col-lg-1 text-center mt-5">
                <h4 class="text-white text-uppercase mt-5">Link utili</h4>
                <ul class="list-unstyled">
                    <li><a class="text-white" [routerLink]="'/chi-siamo'">Chi Siamo</a></li>
                    <li><a class="text-white" [routerLink]="'/blog-e-news'">Blog e News</a></li>
                    <li><a class="text-white" [routerLink]="'/contattaci'">Contattaci</a></li>
                    <li> <a href="https://www.facebook.com/Molino-Lucconi-Stefano-357419854667559" target="_blank"
                            class="aria-label mt-4 ">
                            <mat-icon><span class="text-white mt-3">facebook</span></mat-icon>
                        </a></li>

                </ul>
            </div>
            <div class="col-lg-6 text-center mt-5 ">
                <mat-card class="mat-elevation-z20 p-3 m-3">
                    <mat-card-title class="text-center">Iscriviti alla newsletter</mat-card-title>
                    <mat-card-content>
                        <form *ngIf="fNews" [formGroup]="fNews"
                            (ngSubmit)="fNews.valid && salvaIscrizioneNewsletter(fNews)">
                            <mat-form-field>
                                <mat-label>Nome</mat-label>
                                <input matInput id="nomeNews" formControlName="nomeNews">
                                <mat-hint align="end">{{nomeNews?.value?.length}} / 100</mat-hint>
                                <mat-hint *ngIf="nomeNews.touched && nomeNews.errors?.required" style="color: red;">Il
                                    nome è obbligatorio</mat-hint>
                                <mat-hint *ngIf="nomeNews.touched && nomeNews.errors?.maxlenght" style="color: red;">
                                    Massimo 100 caratteri.</mat-hint>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Cognome</mat-label>
                                <input matInput id="cognomeNews" formControlName="cognomeNews">
                                <mat-hint align="end">{{cognomeNews?.value?.length}} / 100</mat-hint>
                                <mat-hint *ngIf="cognomeNews.touched && cognomeNews.errors?.required"
                                    style="color: red;">Il cognome è obbligatorio</mat-hint>
                                <mat-hint *ngIf="cognomeNews.touched && cognomeNews.errors?.maxlenght"
                                    style="color: red;">Massimo 100 caratteri.</mat-hint>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Email</mat-label>
                                <input matInput id="emailNews" formControlName="emailNews">
                                <mat-hint align="end">{{emailNews?.value?.length}} / 100</mat-hint>
                                <mat-hint *ngIf="emailNews.touched && emailNews.errors?.required" style="color: red;">
                                    L'indirizzo mail è obbligatorio</mat-hint>
                                <mat-hint *ngIf="emailNews.touched && emailNews.errors?.email" style="color: red;">
                                    Inserire una mail corretta</mat-hint>
                                <mat-hint *ngIf="emailNews.touched && emailNews.errors?.maxlenght" style="color: red;">
                                    Massimo 100 caratteri.</mat-hint>
                                <mat-hint *ngIf="emailNews.errors?.pattern" style="color: red;">Inserire un indirizzo
                                    mail corretto.</mat-hint>
                            </mat-form-field>
                            <div class="col-1 my-4">
                                <mat-checkbox class="text-left" id="privacyAccettata"
                                    formControlName="privacyAccettataNews">Ho preso visione dell'informativa<br> e
                                    presto il consenso all'invio<br> della newsletter.</mat-checkbox>
                            </div>
                            <div id="recaptchaContainer" style="transform:scale(0.8);transform-origin:0 0">
                                <re-captcha formControlName="recaptcha" name="recaptcha" required siteKey="{{keyR}}">
                                </re-captcha>
                            </div>

                            <button mat-flat-button color="primary" id=btnIscrizione [disabled]="!fNews.valid"
                                type="submit">
                                <i class="fa fa-floppy-o" aria-hidden="true"></i> Iscriviti
                            </button>

                        </form>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-lg-1 text-center mb-5">
                <h4 class="text-white text-uppercase mt-5">Informazioni</h4>
                <button class="mx-2 mb-2" mat-raised-button (click)="openIubendaDialog(1)">Privacy policy</button>
                <button mat-raised-button (click)="openIubendaDialog(2)">Cookie policy</button>
                <a class="text-white btn btn-light text-success mt-2" mat-raised-button
                    [routerLink]="'/condizioni-di-vendita'">Condizioni di vendita</a>
                <a class="text-white btn btn-light text-success mt-2" mat-raised-button
                    [routerLink]="'/termini-e-condizioni'">Termini e condizioni</a>
                <a class="text-white btn btn-light text-success mt-2" mat-raised-button
                    [routerLink]="'/faq-spedizione'">FAQ Spedizione</a>
            </div>
        </div>
    </div>
</div>