import { AfterContentInit, Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudMovimentoMagazzinoComponent } from 'src/app/componentAdmin/crud-movimento-magazzino/crud-movimento-magazzino.component';
import { MagazzinoDisponibilitaComponent } from '../magazzino-disponibilita/magazzino-disponibilita.component';

@Component({
  selector: 'app-dialog-mov-magazzino',
  templateUrl: './dialog-mov-magazzino.component.html',
  styleUrls: ['./dialog-mov-magazzino.component.css']
})
export class DialogMovMagazzinoComponent implements OnInit,AfterContentInit {

  // @ViewChild('vc', { read: ViewContainerRef }) vcr: ViewContainerRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private vcr:ViewContainerRef,private cfr: ComponentFactoryResolver,
              private dialogRef: MatDialogRef<MagazzinoDisponibilitaComponent>) { }
  
  ngOnInit(): void {
    
  }

  ngAfterContentInit(): void {   
    let componentFactory = this.cfr.resolveComponentFactory(CrudMovimentoMagazzinoComponent);
    this.vcr.clear();
    let component = this.vcr.createComponent(componentFactory);
    component.instance.codArticolo=this.data;
    component.instance.finito.subscribe(
      response => {
        this.dialogRef.close({ event: "OK" });
      }
    )
  }

  click(){
    
  }

}
