<section class="container">
    <mat-card class="mat-elevation-z20">
        <mat-card-title class="text-center">Effettua la registrazione</mat-card-title>
        <mat-card-content>
            <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salva(f)">
                <p>
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput id="username" formControlName="username">
                        <mat-hint align="end">{{username?.value?.length}} / 200</mat-hint>
                        <mat-hint *ngIf="username.pending">Controllo mail...</mat-hint>
                        <mat-hint *ngIf="username.touched && username.errors?.required" style="color: red;">L'indirizzo mail è obbligatorio</mat-hint>                        
                        <mat-hint *ngIf="username.touched && username.errors?.email" style="color: red;">Inserire una mail corretta</mat-hint>
                        <mat-hint *ngIf="username.touched && username.errors?.stringaConSpazi" style="color: red;">La mail non può contenere spazi</mat-hint>
                        <mat-hint *ngIf="username.touched && username.errors?.userNameExists" style="color: red;">La mail è già presente a sistema</mat-hint>                        
                        <mat-hint *ngIf="username.touched && username.errors?.minlength" style="color: red;">L'indirizzo mail deve essere minimo di 5 caratteri.</mat-hint>
                        <mat-hint *ngIf="username.touched && username.errors?.maxlenght" style="color: red;">L'indirizzo mail deve essere massimo di 200 caratteri.</mat-hint>
                        <mat-hint *ngIf="username.errors?.pattern" style="color: red;">Inserire un indirizzo mail corretto.</mat-hint>
                      </mat-form-field>
                </p>
                <p>
                    <mat-form-field>
                        <mat-label>Password</mat-label>
                        <input matInput type="password" required formControlName="password" [type]="hidePassword1 ? 'password' : 'text'">
                        <mat-icon class="puntatore-mano" matSuffix (click)="hidePassword1 = !hidePassword1">{{hidePassword1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-hint align="end">{{password?.value?.length}} / 100</mat-hint>
                        <mat-hint *ngIf="password.touched && password.errors?.required" style="color: red;">La password è obbligatoria</mat-hint>
                        <mat-hint *ngIf="password.touched && password.errors?.minlength" style="color: red;">La password deve essere minimo di 8 caratteri.</mat-hint>
                        <mat-hint *ngIf="password.touched && password.errors?.maxlenght" style="color: red;">La password deve essere massimo di 100 caratteri.</mat-hint>
                      </mat-form-field>
                </p>
                <p>
                    <mat-form-field>
                        <mat-label>Conferma password</mat-label>
                        <input matInput type="password" id="ripetiPassword" formControlName="ripetiPassword" [type]="hidePassword2 ? 'password' : 'text'">
                        <mat-icon class="puntatore-mano" matSuffix (click)="hidePassword2 = !hidePassword2">{{hidePassword2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-hint align="end">{{ripetiPassword?.value?.length}} / 100</mat-hint>
                        <mat-hint *ngIf="ripetiPassword.touched && ripetiPassword.errors?.required" style="color: red;">La conferma password è obbligatoria</mat-hint>
                        <mat-hint *ngIf="ripetiPassword.touched && ripetiPassword.valid && ripetiPassword.value != password.value" style="color: red;">La password e la conferma password devono corrispondere.</mat-hint>                        
                      </mat-form-field>
                </p>
                <p>
                    <mat-form-field>
                        <mat-label>Nome</mat-label>
                        <input matInput id="nome"formControlName="nome">
                        <mat-hint *ngIf="nome.touched && nome.errors?.required" style="color: red;">Il nome è obbligatorio</mat-hint>
                      </mat-form-field>
                </p>
                <p>
                    <mat-form-field>
                        <mat-label>Cognome</mat-label>
                        <input matInput id="cognome"formControlName="cognome">
                        <mat-hint *ngIf="cognome.touched && cognome.errors?.required" style="color: red;">Il cognome è obbligatorio</mat-hint>
                      </mat-form-field>
                </p>
                <p class="m-3">
                    <mat-checkbox class="example-margin" id="accettazionePrivacy" formControlName="accettazionePrivacy">Accettazione privacy</mat-checkbox>
                </p>
                <p class="m-3">
                    <mat-checkbox class="example-margin" id="accettazioneNewsletter" formControlName="accettazioneNewsletter">Iscrivimi alla newsletter</mat-checkbox>
                </p>
                <div id="recaptchaContainer" style="transform:scale(0.8);transform-origin:0 0">
                    <re-captcha formControlName="recaptcha" name="recaptcha" required
                        siteKey="{{keyR}}">
                    </re-captcha>
                </div>
                <p>
                    <button mat-flat-button color="primary" id=btnRegistrazione [disabled]="!f.valid" type="submit">
                        <i class="fa fa-floppy-o" aria-hidden="true"></i> Registrati
                    </button>
                </p>
            </form>
        </mat-card-content>
    </mat-card>   
    <div class="alert alert-success" *ngIf="Conferma">
        {{Conferma}}
    </div>
    <div class="alert alert-danger" *ngIf="Errore">
        {{Errore}}
    </div>
</section>