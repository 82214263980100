import { FiltroArticoliCategorie } from './../../modelli/shared/Classi/Filtri/filtroArticoliCategorie';
import { ArrayListIdDescr } from './../../modelli/shared/Classi/arrayListIdDescr';
import { ArticoliCategorie } from './../../modelli/shared/articoliCategorie';
import { backServerUrlArticoliCategorie } from './../../modelli/app.contstant';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';

@Injectable({
  providedIn: 'root'
})
export class ArticoliCategorieDataService {

  constructor(private http: HttpClient) { }

  elencoCompleto() {
    return this.http.get<ArticoliCategorie[]>(backServerUrlArticoliCategorie + "/lista");
  }

  elencoSmall(){
    return this.http.get<ArticoliCategorie[]>(backServerUrlArticoliCategorie + "/listasmall")
  }

  elencoUltime(){
    return this.http.get<ArrayListIdDescr[]>(backServerUrlArticoliCategorie + "/cerca/elencoultime");
  }
  
  cercaCategorieConFiltri(filtro:FiltroArticoliCategorie){
    return this.http.post<ArticoliCategorie[]>(backServerUrlArticoliCategorie + "/listafiltrata",filtro)
  }

  cercaByID(id:number){
    return this.http.get<ArticoliCategorie>(backServerUrlArticoliCategorie + "/cerca/id/" + id)
  }
  
  cercaByDicituraUrl(dicituraUrl:string){
    return this.http.get<ArticoliCategorie>(backServerUrlArticoliCategorie + "/cerca/dicituraurl/" + dicituraUrl)
  }
  
  modifica(categoria:ArticoliCategorie){
    return this.http.put<ApiMsg>(backServerUrlArticoliCategorie + "/modifica",categoria)
  }
  
  inserisci(categoria:ArticoliCategorie){
    return this.http.post<ApiMsg>(backServerUrlArticoliCategorie + "/inserisci",categoria)
  }
    
  elimina(id:number){
    return this.http.delete<ApiMsg>(backServerUrlArticoliCategorie + "/elimina/" + id);
  }
    
}
