import { Component, OnInit } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { FiltroOrdini } from 'src/app/modelli/shared/Classi/Filtri/filtroOrdini';
import { OrdiniTestata } from 'src/app/modelli/shared/ordiniTestata';
import { LoadingService } from 'src/app/services/app/loading.service';
import { AuthJWTService } from 'src/app/services/auth-jwt.service';
import { OrdinitestataDataService } from 'src/app/services/data/ordinitestata-data.service';
import { PagamentoDataService } from 'src/app/services/data/pagamento-data.service';

@Component({
  selector: 'app-elenco-ordini-utente',
  templateUrl: './elenco-ordini-utente.component.html',
  styleUrls: ['./elenco-ordini-utente.component.css']
})
export class ElencoOrdiniUtenteComponent implements OnInit {
  ordini:OrdiniTestata[];
  username: string;
  apiMsg: ApiMsg;

  constructor(private ordiniTestataService:OrdinitestataDataService,public AuthJWT: AuthJWTService,private pagamentoService:PagamentoDataService, private appLoading: LoadingService) { }

  ngOnInit(): void {
    this.getOrdini();
  }

  getOrdini(){
    this.appLoading.avvia();
    this.username = this.AuthJWT.loggedUser();
    let tmpDal:Date=new Date('2010/01/01');
    let tmpAl:Date=new Date('2050/12/31');
    let filtro = new FiltroOrdini(this.username,tmpDal,tmpAl,0,0);
    this.ordiniTestataService.listaFiltrata(filtro).subscribe(
      response=>{
        this.ordini=[];
        this.ordini=response;       
        this.appLoading.stoppa();                                        
      }
    )
  }

  pagaOrdine(idOrdine:number){    
    this.pagamentoService.PagamentoOrdine(idOrdine).subscribe(
      response=>{            
        let tmpInfo:ApiMsg;
        tmpInfo=response;            
        window.location.href=tmpInfo.messaggio;
      }
    )
  }

  

}
