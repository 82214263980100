import { backServerUrlFornitori } from './../../modelli/app.contstant';
import { Fornitori } from './../../modelli/shared/fornitori';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';

@Injectable({
  providedIn: 'root'
})
export class FornitoriDataService {

  constructor(private http:HttpClient) { }

  elencoFornitoriCompleto(){
    return this.http.get<Fornitori[]>(backServerUrlFornitori + "/lista");
  }

  elencoProduttori(){
    return this.http.get<Fornitori[]>(backServerUrlFornitori+ "/listaproduttori")
  }

  cercaFornitoreByID(id:number){
    return this.http.get<Fornitori>(backServerUrlFornitori + "/cerca/id/" + id)
  }

  modificaFornitore(fornitore:Fornitori){
    return this.http.put<ApiMsg>(backServerUrlFornitori + "/modifica",fornitore)
  }

  inserisciFornitore(fornitore:Fornitori){
    return this.http.post<ApiMsg>(backServerUrlFornitori + "/inserisci",fornitore)
  }

  eliminaFornitore(id:number){
    return this.http.delete<ApiMsg>(backServerUrlFornitori + "/elimina/" + id);
  }
}
