
<div  class="loading" *ngIf="loadingVisible">
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" class="p-4">
        <img src="/assets/img/molino-lucconi-loading.gif" alt="molino lucconi loading" width=200  />
    </div>
</div>
<app-topbar></app-topbar>
<app-navbar></app-navbar>
<router-outlet></router-outlet>
<button id="button-top" *ngIf="isShow" (click)="gotoTop()" class="px-2"><i class="fa fa-arrow-up"></i></button>
<app-footer></app-footer>

