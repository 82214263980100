<div class="text-center mt-5">
  <h1 class="titoli-blog text-uppercase text-success mb-5">Riso carnaroli di Villareggio</h1>
  <h3 class=" text-uppercase sottotitoli-blog text-muted">Proprietà e origini</h3>
</div>
<!-- <div class="text-center">
  <iframe src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.molinolucconi.it%2Friso-carnaroli-villareggio&layout=button_count&size=large&width=111&height=28&appId" width="111" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</div> -->
<div class="row m-0 mb-5 ">
  <div class="col-lg-2 d-none d-lg-flex">
    <img class="img-fluid" src="/assets/img/blog/molino-lucconi-riso-carnaroli-di-villareggio-004.png">
  </div>
  <div class=" col-lg-8  mat-elevation-z20 p-4 mt-5  ">
    <div class="container">
      <p class="testo-blog lead">Il CARNAROLI è uno dei migliori risi Italiani.<br> Nato nel <b>1945</b>, grazie alla
        sua eccellente tenuta in cottura e consistenza è considerati <b>il “Re” dei risi</b>.<br>La sua versatilità lo
        rende ideale per ogni tipo di ricetta!</p>
      <div class="row m-0 ">

        <div class="col-lg-4">
          <img class="img-fluid" src="/assets/img/blog/molino-lucconi-riso-carnaroli-di-villareggio-001.JPG">
        </div>
        <div class="col-lg-4">
          <img class="img-fluid" src="/assets/img/blog/molino-lucconi-riso-carnaroli-di-villareggio-002.jpg">
        </div>
        <div class="col-lg-4">
          <img class="img-fluid" src="/assets/img/blog/molino-lucconi-riso-carnaroli-di-villareggio-003.jpg">
        </div>

      </div>
    </div>
  </div>
  <div class="col-lg-2 d-none d-lg-flex">
    <img class="img-fluid" src="/assets/img/blog/molino-lucconi-riso-carnaroli-di-villareggio-004.png">
  </div>
</div>

<section class="container mat-elevation-z20 p-4 mt-5">
  <p class="testo-blog lead">Il Riso Carnaroli di Villareggio è coltivato nell’omonima località, frazione di Zeccone
    (PV), zona tipica per la coltivazione del riso, dove si trova la sede del Molino Lucconi Stefano.<br> La
    <b>fertilità del terreno e la cura nella coltivazione</b> permettono di ottenere un riso uniforme e di qualità, con
    il quale si può effettuare una lavorazione più delicata per avere un prodotto <b>meno “raffinato”</b> e più
    resistente alla cottura.<br><br>Il marchio <b>“DNA controllato”</b> certifica mediante l’analisi del DNA che il riso
    contenuto nella confezione corrisponde alla sola varietà in etichetta: RISO CARNAROLI. <br>Le analisi per la
    certificazione “DNA controllato” vengono effettuate dalla Piattaforma Genomica del Parco Tecnologico Padano, Via
    Einstein, Loc. Cascina Codazza – 26900 Lodi. </p>
</section>

<div style="text-align: -webkit-center;">
  <div class="col-sm-12 col-md-6 col-lg-2 align-self-stretch">
    <app-articoli-card [articolo]="articolo" [visualizzazione]="2"></app-articoli-card>
  </div>
</div>

<div class="text-center mt-5">

  <h3 class=" text-uppercase sottotitoli-blog text-muted">Caratteristiche del riso</h3>
</div>

<section class="container mt-5">
  <div class="row mx-0">
    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover  text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
            src="/assets/img/blog/molino-lucconi-riso-carnaroli-proprietà-digestione.png" alt="Image Description">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead"><b>Sempre digeribile</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Grazie a queste caratteristica il riso, per sua natura, regola al meglio la
            flora intestinale. Non solo: ha le proteine con il più alto valore biologico, in quanto è dotato del
            migliore contenuto di amminoacidi.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>

    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover  text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
            src="/assets/img/blog/molino-lucconi-riso-carnaroli-proprietà-ipertensione.png" alt="Image Description">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead"><b>Ideale per l’ipertensione</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Un’ ottima pratica contro l’ipertensione consiste nel cenare, una o due volte
            alla settimana, con riso e mele, cotti insieme e dolcificati con miele.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>

    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
            src="/assets/img/blog/molino-lucconi-riso-carnaroli-proprietà-cardiovascolari.png" alt="Image Description">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead"><b>Previene malattie cardiovascolari</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Il riso contiene un composto naturale che sembra tenere sotto controllo la
            pressione alta e i rischi di malattie cardiache.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>
  </div>
  <div class="row mx-0">
    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover  text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
            src="/assets/img/blog/molino-lucconi-riso-carnaroli-proprietà-senza-glutine.png" alt="Image Description">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead"><b>Zero glutine</b></p>
          </h3>
          <p class="p-3 lead testo-blog">I chicchi di riso contengono soprattutto amido che, similmente al glutine,
            produce un effetto collante, senza però sviluppare alcun tipo di intolleranza.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>

    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover  text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
            src="/assets/img/blog/molino-lucconi-riso-carnaroli-proprietà-aumenta-senso-di-sazietà.png"
            alt="Image Description">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead"><b>Aumenta il senso di sazietà</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Grazie al suo alto quantitativo di fibra, aumenta il senso di sazietà ed è
            indicato anche per chi desidera perdere peso e in caso di stitichezza.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>

    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
            src="/assets/img/blog/molino-lucconi-riso-carnaroli-proprietà-no-controindicazioni.png"
            alt="Image Description">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead "> <b>Non ha controindicazioni</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Le caratteristiche delle sue proteine verdi fanno sì che il riso possa essere
            consumato anche 4-5 volte alla settimana senza controindicazioni anche da chi soffre di alcune malattie
            renali.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>
  </div>
</section>