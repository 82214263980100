<div style="text-align: -webkit-center;">
  <div class="col-lg-6 text-center ">
    <img class="img-fluid mat-elevation-z20 w-75" src="/assets/img/blog/birra-lamberale-la-bruciata.png">
  </div>
</div>
<div class="container">
  <div class="text-center mt-5">
    <h1 class="titoli-blog text-uppercase text-success mb-5 interlineaAlta">Birra "Lamberale" La Bruciata</h1>
    <h3 class=" text-uppercase sottotitoli-blog text-muted interlineaAlta">La nuova birra artigianale del sud di
      Milano<br><br> dedicata al fiume Lambro<br><br></h3>
  </div>
</div>
<!-- <div class="text-center">
  <iframe src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.molinolucconi.it%2Fbirra-amberale-la-bruciata&layout=button_count&size=large&width=111&height=28&appId" width="111" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</div> -->




<div class="row mat-elevation-z20 px-3 pb-5 mx-3 my-4" style="text-align: -webkit-center;">
  <div class="col-lg-6 py-5">
    <h3 class=" text-uppercase sottotitoli-blog text-success interlineaAlta text-right my-5">La storia</h3>
    <p class="testo-blog lead text-right ">
      La nuova birra artigianale del sud di Milano: "Lamberale".<br> Un percorso iniziato nel 2019 con la creazione del
      luppoleto coltivato direttamente dai produttori, passando attraverso la progettazione e realizzazione del
      birrificio, arrivando fino alla nascita di questo nuova birra.<br><br> Lamberale è la birra dedicata al fiume
      Lambro che attraversa le terre del Sud-Est Milano.
    </p>

    <h3 class=" text-uppercase sottotitoli-blog text-success interlineaAlta text-right my-5">Descrizione</h3>
    <p class="testo-blog lead text-right ">
      E’ una birra ad alta fermentazione di colore ambrato, con 5,5%vol. e 35 ibu.<br> Una Ale che regala un sapore
      erbaceo e leggermente tostato.<br> Le note maltate sono equilibrate dall'amaro del Nostro luppolo Comet, luppolo
      al 100% di Cascina Bruciata.<br> Buona, Beverina, Nostra... e anche Vostra...
    </p>

  </div>
  <div class="col-lg-6 mt-5 ">
    <img class="img-fluid mat-elevation-z20" src="/assets/img/blog/birra-lamberale-la-bruciata-1.png">
  </div>
</div>
<div class="row mat-elevation-z20 px-3 pb-5 mx-3 my-4" style="text-align: -webkit-center;">
  <div class="col-lg-6 mt-5 ">
    <img class="img-fluid mat-elevation-z20" src="/assets/img/blog/birra-lamberale-la-bruciata-3.png">
  </div>

  <div class="col-lg-6 py-5">
    <h3 class=" text-uppercase sottotitoli-blog text-success interlineaAlta text-left my-5">Temperatura di esercizio
      ottimale</h3>
    <p class="testo-blog lead text-left ">
      Questa tipologia di birra <b>va servita fresca ma non ghiacciata</b>, per fare in modo di far emergere al meglio
      le note maltate e consentire al ventaglio di aromi di diffondersi.<br><b>La temperatura di esercizio ottimale è
        compresa tra 6 e 8°C.</b>
    </p>

    <h3 class=" text-uppercase sottotitoli-blog text-success interlineaAlta text-left my-5">Abbinamenti consigliati</h3>
    <p class="testo-blog lead text-left ">
      La sua secchezza è in grado di ripulire il palato, e questo la rende perfetta per <b>accompagnare la cucina
        etnica, speziata e piccante</b> (ad esempio l’indiana, la thailandese e messicana), ma anche come abbinamento a
      primi piatti strutturati, <b>secondi di carni rosse al forno e grigliate</b>, barbecue, salsicce alla griglia,
      pollo alla griglia e agnello arrosto.<br> Valido anche l’accostamento a <b>formaggi erborinati, formaggi freschi,
        o a pasta dura</b> (Cheddar) ma anche a taglieri di salumi.
    </p>

  </div>
</div>

<div style="text-align: -webkit-center;">
  <div class="col-sm-12 col-md-6 col-lg-2 align-self-stretch">
    <app-articoli-card [articolo]="articolo" [visualizzazione]="2"></app-articoli-card>
  </div>
</div>

<section class="container mt-5 text-center">
  <div class=" mt-5 mb-5">
    <h3 class=" text-uppercase sottotitoli-blog text-success interlineaAlta text-center my-5">benefici della birra</h3>
  </div>
  <div class="row mx-0">
    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover  text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1" src="/assets/img/blog/ossa.png"
            alt="Proprietà birra Molino Lucconi">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead"><b>Ossa più forti</b></p>
          </h3>
          <p class="p-3 lead testo-blog">La birra contiene alti livelli di silicio, che esercita ottimi effetti sulla
            salute delle ossa. In effetti, uno studio realizzato nel 2009 dall’Università di Tufts e da altri centri ha
            dimostrato che gli uomini e le donne di una certa età che bevevano una o due birre al giorno avevano una
            densità ossea maggiore.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>

    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover  text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1" src="/assets/img/blog/cuore.png"
            alt="Proprietà birra Molino Lucconi">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead"><b>Un cuore più sano</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Più di 100 studi hanno dimostrato che un consumo moderato di birra diminuisce
            dal 25% al 40% il rischio di attacchi cardiaci e di morte per malattie cardiovascolari.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>

    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1" src="/assets/img/blog/reni.png"
            alt="Proprietà birra Molino Lucconi">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead"><b>Reni piu sani</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Uno studio realizzato in Finlandia ha rivelato che gli uomini che bevono una
            bottiglia di birra al giorno sono meno vulnerabili ai calcoli renali del 40%. In effetti, una delle teorie
            che spiegherebbe questi dati è che l’alto contenuto di acqua della birra aiuta a far lavorare continuamente
            i reni, oppure che il luppolo della birra aiuta a evitare la formazione di pietre.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>
  </div>
  <div class="row mx-0">
    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover  text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1" src="/assets/img/blog/cervello.png"
            alt="Proprietà birra Molino Lucconi">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead"><b>Un cervello più attivo</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Secondo i ricercatori, una birra al giorno può aiutare a mantenere lontane
            malattie come l’Alzheimer e, inoltre, a scongiurare altre patologie che colpiscono il cervello.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>

    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover  text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
            src="/assets/img/blog/molino-lucconi-proprieta-miele-002.png" alt="Proprietà birra Molino Lucconi">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead"><b>Riduzione del rischio di cancro</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Uno studio ha dimostrato che marinare la carne con la birra elimina quasi il
            70% degli agenti cancerogeni, chiamati ammine eterocicliche (HCA), che vengono prodotti quando si frigge la
            carne.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>

    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1" src="/assets/img/blog/vitamine.png"
            alt="Proprietà birra Molino Lucconi">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead "> <b>Aumento dei livelli di vitamine</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Uno studio olandese ha scoperto che chi beve regolarmente birra ha dei livelli
            di vitamina B6 del 30% più alti rispetto a chi non la consuma mai. Inoltre, la birra contiene anche vitamina
            B12 e acido folico.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>
    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1" src="/assets/img/blog/sangue.png"
            alt="Proprietà birra Molino Lucconi">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead "> <b>Protezione contro i problemi cerebrovascolari</b></p>
          </h3>
          <p class="p-3 lead testo-blog">La birra aiuta a prevenire i coaguli di sangue che bloccano il flusso sanguigno
            verso il cuore, il collo e il cervello.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>
    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1"
            src="/assets/img/blog/molino-lucconi-proprieta-miele-007.png" alt="Proprietà birra Molino Lucconi">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead "> <b>minor rischio di diabete</b></p>
          </h3>
          <p class="p-3 lead testo-blog">I ricercatori hanno scoperto che l’alcol aumenta la sensibilità dell’insulina,
            il che aiuta a proteggerci contro il diabete.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>
    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1" src="/assets/img/blog/pressione.png"
            alt="Proprietà birra Molino Lucconi">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead "> <b>Pressione arteriosa bassa</b></p>
          </h3>
          <p class="p-3 lead testo-blog">I consumatori moderati di birra hanno una minor probabilità di soffrire di
            problemi di pressione arteriosa alta, un fattore che è spesso la causa di infarti.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>
    <div class="col-lg-4 px-0 g-mb-30">
      <!-- Article -->
      <article class="u-block-hover text-center">
        <figure class="g-overflow-hidden">
          <img class="img-fluid w-50 u-block-hover__main--zoom-v1" src="/assets/img/blog/tempo.png"
            alt="Proprietà birra Molino Lucconi">
        </figure>

        <div class="text-center">
          <h3 class=" text-uppercase">
            <p class="lead "> <b>Vita più lunga</b></p>
          </h3>
          <p class="p-3 lead testo-blog">Il consumo moderato di birra previene circa 26.000 morti all’anno, grazie alla
            protezione da malattie cardiovascolari, emorragie cerebrali e diabeti.</p>

        </div>
      </article>
      <!-- End Article -->
    </div>
  </div>
</section>