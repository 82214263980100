import { backServerUrlStatoOrdine } from './../../modelli/app.contstant';
import { StatoOrdine } from './../../modelli/shared/statoOrdine';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatoOrdineDataService {

  constructor(private http:HttpClient) { }

  elenco(){
    return this.http.get<StatoOrdine[]>(backServerUrlStatoOrdine + "/elenco");
  }

  
}
