import { EmailNewsletterDataService } from './../../services/data/email-newsletter-data.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';

@Component({
  selector: 'app-rimozione-newsletter',
  templateUrl: './rimozione-newsletter.component.html',
  styleUrls: ['./rimozione-newsletter.component.css']
})
export class RimozioneNewsletterComponent implements OnInit {
  email:string;
  removeCode:string;
  apiMsg:ApiMsg
  msgConferma:string="";

  constructor(private route:ActivatedRoute, private newsletterDataService:EmailNewsletterDataService) { }

  ngOnInit(): void {
    this.email = this.route.snapshot.params['email'];
    this.removeCode= this.route.snapshot.params['removecode']
    this.newsletterDataService.rimuoviIscrizione(this.email,this.removeCode).subscribe(
      response=>{
        this.apiMsg=response;
        this.msgConferma=this.apiMsg.messaggio;
      },
      error=>{
        this.apiMsg=error.error;
        this.msgConferma=this.apiMsg.messaggio;
      }
    )

  }

}
