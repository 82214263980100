<section class="container">
    <h1>Modifica impostazioni</h1>
    <form *ngIf="fDati" [formGroup]="fDati" (ngSubmit)="fDati.valid && salvaDati(fDati)">
        <p>
            <mat-checkbox class="example-margin" id="accettazioneNewsletter" formControlName="accettazioneNewsletter">
                Accettazione newsletter</mat-checkbox>
        </p>
        <p>
            <button mat-flat-button color="primary" id=btnSalvaDati [disabled]="!fDati.valid" type="submit">
                <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva dati
            </button>
        </p>
    </form>
    <div class="row mt-4">
        <div class="card col-lg-4 mx-3" style="width: 18rem;">            
            <div class="card-body">
              <h2 class="card-title">Elenco ordini</h2>
              <p class="card-text">Visualizza lo storico dei tuoi ordini</p>
              <button mat-raised-button class="btn-success my-4" type="button" (click)="vaiElencoOrdini()">Vai</button>
            </div>
          </div>
          <div class="card col-lg-4" style="width: 18rem;">            
            <div class="card-body">
              <h2 class="card-title">Indirizzi di spedizione/fatturazione</h2>
              <p class="card-text">Gestisci i tuoi indirizzi</p>
              <button mat-raised-button class="btn-success m-4" type="button" (click)="vaiUtenteIndirizzi()">Vai</button>
            </div>
          </div>
        <div class="col-lg-4"></div>
    </div>    
</section>