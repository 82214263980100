import { LoadingService } from './../../services/app/loading.service';
import { OrdiniTestata } from './../../modelli/shared/ordiniTestata';
import { PagamentoDataService } from './../../services/data/pagamento-data.service';
import { MetodoPagamento } from './../../modelli/shared/metodoPagamento';
import { MetodiPagamentoDataService } from './../../services/data/metodi-pagamento-data.service';
import { UtentiIndirizzo } from './../../modelli/shared/utentiIndirizzo';
import { UtentiIndirizziDataService } from './../../services/data/utenti-indirizzi-data.service';
import { CrudUtentiIndirizziComponent } from './../../component/crud-utenti-indirizzi/crud-utenti-indirizzi.component';
import { FunzioniLocalStorage } from './../../modelli/shared/Funzioni/funzioniLocalStorage';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TrasformaCarrelloInOrdine } from './../../modelli/shared/modelsMetodi/trasformaCarrelloInOrdine';
import { CarrelliDettaglio } from './../../modelli/shared/carrelliDettaglio';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { CarrellidettaglioDataService } from 'src/app/services/data/carrellidettaglio-data.service';
import { Router } from '@angular/router';
import { AppCarrelloService } from 'src/app/services/app/app-carrello.service';
import { OrdinitestataDataService } from 'src/app/services/data/ordinitestata-data.service';
import { AuthJWTService } from 'src/app/services/auth-jwt.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { AppImpostazioniDataService } from 'src/app/services/data/app-impostazioni-data.service';
import { AppImpostazioni } from 'src/app/modelli/shared/appImpostazioni';
import { CarrellitestataDataService } from 'src/app/services/data/carrellitestata-data.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogClass } from 'src/app/modelli/shared/Classi/Componenti/dialogClass';
import { DialogComponent } from 'src/app/component/dialog/dialog.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  @ViewChild('vc', { read: ViewContainerRef }) vcr: ViewContainerRef;

  dettagli: CarrelliDettaglio[];
  indirizziFatturazione: UtentiIndirizzo[];
  indirizziSpedizione: UtentiIndirizzo[];
  metodiPagamento: MetodoPagamento[];
  tmpMetodiPagamento: MetodoPagamento[] = [];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['titolo', 'prezzo', 'qta', 'totale'];
  apiMsg: ApiMsg;
  f: FormGroup;
  trasforma: TrasformaCarrelloInOrdine;
  tmpIdFatturazione: number;
  tmpIdSpedizione: number;
  tmpIDMetodoPagamento: number;
  tmpIDStatoOrdine: number;

  voglioLaFattura: boolean = false;
  articoloSoloRitiroPresente: boolean = false;
  visualizzazioneMetodoDiPagamento: boolean = false;

  appImpostazioni: AppImpostazioni;
  totaleCarrello: number = 0;
  totaleSpedeSpedizione: number = 0;
  totaleFinale: number = 0;

  visualizzaHtml: boolean = false;

  ritiroInNegozio: boolean = true;

  constructor(private carrelloDettagliService: CarrellidettaglioDataService, private ordiniTestataService: OrdinitestataDataService, private router: Router,
    private appCarrello: AppCarrelloService, private funzioniLocalStorage: FunzioniLocalStorage, private cfr: ComponentFactoryResolver, private indirizziService: UtentiIndirizziDataService,
    public AuthJWT: AuthJWTService, private metodiPagamentoService: MetodiPagamentoDataService, private pagamentoService: PagamentoDataService,
    private snackBar: MatSnackBar, private appImpostazioniService: AppImpostazioniDataService, private carrelloTestataService: CarrellitestataDataService,
    private dialog: MatDialog, private appLoading: LoadingService) { }

  ngOnInit(): void {
    this.f = new FormGroup({
      'noteOrdine': new FormControl('', [Validators.maxLength(1000)])
    })

    this.appImpostazioni = new AppImpostazioni(0, 0, 0, 0);
    //Prima di caricare tutto controllo che i quantitativi a maggazzini se siano congrui al carrello
    this.carrelloTestataService.controlloQuantitativiCarrello(this.funzioniLocalStorage.getIdCart()).subscribe(
      response => {
        let result = response;
        if (result == false) {
          this.visualizzaHtml = true;
          this.getDettagliCarrello();
          this.getIndirizzo();
          this.getMetodiPagamento();
        } else {
          let dialogData: DialogClass = new DialogClass("Carrello modificato", "Il carrello è stato modificato in base alle disponibilità degli articoli, verrai indirizzato alla pagina del carrello.", true, true, false)
          let dialogRef = this.dialog.open(DialogComponent, { data: dialogData })
          dialogRef.afterClosed().subscribe(
            response => {
              window.location.replace('spesa-online/carrello');
            }, error => {
              // window.location.reload();
            }
          )
        }
      }
    )

  }

  get noteOrdine() {
    return this.f.get('noteOrdine');
  }

  apriIndirizzo(tipo: string) {
    let componentFactory = this.cfr.resolveComponentFactory(CrudUtentiIndirizziComponent);
    this.vcr.clear();
    this.vcr.clear();
    let component = this.vcr.createComponent(componentFactory);
    component.instance.tipoIndirizzo = tipo;
    //Rimango in ascolto della variabile finito del figlio, quando questa diventa true distruggo il figlio
    component.instance.finito.subscribe(
      response => {
        if (response == true) {
          this.vcr.clear();
          this.getIndirizzo();
        }
      }
    )
  }

  getDettagliCarrello() {
    this.carrelloDettagliService.elencoCarrelloDettagliByIdTestata(this.funzioniLocalStorage.getIdCart()).subscribe(
      response => {
        this.dettagli = [];
        this.dettagli = response;
        //Se non ci sono dettagli nel carrello ritorno alla pagina del carrello
        if (this.dettagli == null) {
          this.router.navigate(['spesa-online']);
        }
        this.dataSource = new MatTableDataSource(this.dettagli);

        this.appImpostazioniService.lista().subscribe(
          response => {
            this.appImpostazioni = response;
            //Calcolo il totale del carrello ed inserisco eventualmente la dicitura e la cifra delle spedizioni
            let totaleDettagliCarrello: number = 0;
            let tmpSoloRitiro: boolean = false;
            if (this.dettagli != null) {
              this.dettagli.forEach(function (x) {
                totaleDettagliCarrello += x.articolo.prezzo * x.qta;
                if (x.articolo.soloRitiro) {
                  tmpSoloRitiro = true;
                }
              })
              this.totaleCarrello = totaleDettagliCarrello;
              this.calcolaTotaleSpedizioneEFinale();
              this.articoloSoloRitiroPresente = tmpSoloRitiro;

              //Se l'importo del carrello non raggiunge l'importo di minimo dell'ordine ritorno al carrello
              if (this.totaleCarrello < this.appImpostazioni.importoOrdineMinimo) {
                this.router.navigate(['spesa-online/carrello']);
              }
            }
          }
        )



      }
    )
  }

  getMetodiPagamento() {
    this.metodiPagamentoService.lista().subscribe(
      response => {
        this.metodiPagamento = response;
      }
    )
  }

  tornaAlCarrello() {
    this.router.navigate(['spesa-online/carrello']);
  }


  concludiOrdine(dati: FormGroup) {
    this.appLoading.avvia();   
    
    //Verifico che sia stato indicato un indirizzo di spedizione e che sia stato indicato un indirizzo di fatturazione se è spuntato voglio la fattura
    if (this.tmpIdSpedizione < 0 || this.tmpIdSpedizione == null) {
      this.openSnackbar("Indicare un indirizzo per la spedizione");
      this.appLoading.stoppa();
      return;
    }
    if ((this.tmpIdFatturazione <= 0 || this.tmpIdFatturazione == null) && this.voglioLaFattura == true) {
      this.openSnackbar("Se si richiede la fattura è obbligatorio indicare un indirizzo di fatturazione.");
      this.appLoading.stoppa();
      return;
    }

    //Verifico se è stato indicato il metdoso di pagamento
    if (this.tmpIDMetodoPagamento <= 0 || this.tmpIDMetodoPagamento == null) {
      this.openSnackbar("Indicare il metodo di pagamento");
      this.appLoading.stoppa();
      return;
    }




    let param: TrasformaCarrelloInOrdine = new TrasformaCarrelloInOrdine(this.funzioniLocalStorage.getIdCart(), this.noteOrdine.value, this.tmpIdSpedizione, this.tmpIdFatturazione, this.tmpIDMetodoPagamento, 1)
    this.ordiniTestataService.creaOrdineDaCarrello(param).subscribe(
      response => {
        this.appCarrello.eliminaCarrello();
        let tmpOrdine: OrdiniTestata = response;
        //Qui verifico se il metodo di pagamento è esterno, se è esterno allora urlo a nexi, altrimenti mando a pagina di conclusione ordine
        let pagamentoSelezionato: MetodoPagamento = this.findMetodoPagamento(this.tmpIDMetodoPagamento);
        if (pagamentoSelezionato.pagamentoEsterno) {

          this.pagamentoService.PagamentoOrdine(tmpOrdine.id).subscribe(
            response => {
              let tmpInfo: ApiMsg;
              tmpInfo = response;
              window.location.href = tmpInfo.messaggio;
              this.appLoading.stoppa();
            }
          )
        } else {
          this.router.navigate(['conclusione-ordine/' + tmpOrdine.id]);
          this.appLoading.stoppa();
        }

      }
    )

  }

  findMetodoPagamento(id: number) {
    return this.metodiPagamento.find(f => f.id == id);
  }

  openSnackbar(titolo: string) {
    let snackBarData: SnackBarClass = new SnackBarClass(titolo, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }

  getIndirizzo() {
    let username: string = this.AuthJWT.loggedUser();
    this.indirizziService.cercaByUsername(username).subscribe(
      response => {
        let indirizzi: UtentiIndirizzo[] = response;
        this.indirizziFatturazione = indirizzi.filter(t => t.tipo == 'F')
        this.indirizziSpedizione = indirizzi.filter(t => t.tipo == 'S')
      }, error => {
      }, () => {
      }
    )
  }

  impostaRitiroInNegozio(pag: MetodoPagamento) {
    this.ritiroInNegozio = pag.ritiroInNegozio;
    this.calcolaTotaleSpedizioneEFinale();
  }

  calcolaTotaleSpedizioneEFinale() {
    if (this.totaleCarrello < this.appImpostazioni.importoPerSpedizioneGratuita && !this.ritiroInNegozio) {
      this.totaleSpedeSpedizione = this.appImpostazioni.spesaSpedizione;
    } else {
      this.totaleSpedeSpedizione = 0;
    }
    this.totaleFinale = this.totaleCarrello + this.totaleSpedeSpedizione;
  }

  visualizzaMetodoPagamento(nonVisualizzareContanti: boolean) {
    this.tmpIDMetodoPagamento=null;
    this.visualizzazioneMetodoDiPagamento = true;
    this.tmpMetodiPagamento = [];
    if (nonVisualizzareContanti) {
      // non faccio vedere contanti
      this.tmpMetodiPagamento = this.metodiPagamento.filter(x => !x.ritiroInNegozio);
    }
    else {
      this.tmpMetodiPagamento = this.metodiPagamento;
    }
    
    
  }


}
