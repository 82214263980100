import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ArticoliCategorieDataService } from './../../services/data/articoli-categorie-data.service';
import { Component, Input, OnInit } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticoliCategorie } from 'src/app/modelli/shared/articoliCategorie';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css']
})
export class CategoriaComponent implements OnInit {

  f: FormGroup;
  tmpid: number = 0;
  categoria: ArticoliCategorie;


  categoriePadre: ArticoliCategorie[];
  fCategoriePadre;


  apiMsg: ApiMsg;
  erroreMsg: string = "";
  confermaMsg: string = "";
  IsModifica: boolean = false;
  idPadrePerDdl:number=0;

  constructor(private route: ActivatedRoute, private categorieService: ArticoliCategorieDataService, private router: Router) { }

  ngOnInit(): void {
    this.categoria = new ArticoliCategorie(0, "", "", "",0, false,0,null,null);


    this.tmpid = this.route.snapshot.params['id'];
    let tmpParam = this.route.queryParams.subscribe(
      params=>{        
        this.idPadrePerDdl= Number(params['idPadreDdl']);
      }
    )
    
    



    this.f = new FormGroup({
      'titolo': new FormControl('', [Validators.minLength(2), Validators.maxLength(250)]),
      'descrizione': new FormControl('', [Validators.maxLength(1000)]),
      // 'dicituraUrl': new FormControl('', [Validators.required]),
      'idPadre': new FormControl(0),      
      'ultima': new FormControl(false)
    })

    //Disabilito la tendina del padre se la categoria è di livello uno
    if (this.IsModifica) {
      this.f.get('idPadre').disable();
    }

    this.popolaCombo();

    if (this.tmpid > 0) {
      this.IsModifica = true;
      
        this.categorieService.cercaByID(this.tmpid).subscribe(
          response => {
            this.categoria = response;
            this.f.controls['titolo'].setValue(this.categoria.titolo);
            this.f.controls['descrizione'].setValue(this.categoria.descrizione);
            // this.f.controls['dicituraUrl'].setValue(this.categoria.dicituraUrl);
            this.f.controls['idPadre'].setValue(this.categoria.idPadre);            
            this.f.controls['ultima'].setValue(this.categoria.ultima)
          },
          error => {
            this.erroreMsg = error.error;
          }
        )      
    } else {
      if(this.idPadrePerDdl>0){   
        
        this.f.controls['idPadre'].setValue(this.idPadrePerDdl);                     
      }else{
        this.categoria.id = 0;
      }
      this.IsModifica = false;
      
    }
  }  

  get titolo() {
    return this.f.get('titolo');
  }

  get descrizione() {
    return this.f.get('descrizione');
  }

  // get dicituraUrl() {
  //   return this.f.get('dicituraUrl');
  // }

  get idPadre() {
    return this.f.get('idPadre');
  }

  get ultima() {
    return this.f.get("ultima");
  }

  popolaCombo() {
    
      this.categorieService.elencoCompleto().subscribe(
        response => {
          this.categoriePadre = response;
          this.fCategoriePadre = this.categoriePadre.slice();
          
        }
      )
  }

  messaggioResponse(responseOrError){
    this.apiMsg = responseOrError;
    this.confermaMsg = this.apiMsg.messaggio;
  }

  salva(dati: FormGroup) {
    this.confermaMsg = "";
    this.erroreMsg = "";

    if (this.IsModifica) {
      
        
          this.categoria = dati.value;
          this.categoria.id=this.tmpid;
          this.categorieService.modifica(this.categoria).subscribe(
            response=>{
              this.messaggioResponse(response);
            },
            error=>{
              this.messaggioResponse( error.error)
            }
          )
        
      
    }
    else{     
      
          this.categoria = dati.value;
          this.categorieService.inserisci(this.categoria).subscribe(
            response=>{
              this.messaggioResponse(response);
            },
            error=>{
              this.messaggioResponse( error.error)
            }
          )      
      
    }
  }  

  chiudi() {
    this.router.navigate(['amm/categorie']);
  }

}
