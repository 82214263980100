import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { UtentiDataService } from 'src/app/services/data/utenti-data.service';
import { Injectable } from "@angular/core";
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class UtenteValidators {
    constructor(private utentiService: UtentiDataService) { }

    timeoutRicerca(testoRicerca: string) {
        return timer(1000).pipe(
            switchMap(() => {
                return this.utentiService.verificaSePresente(testoRicerca)
            })
        );
    }

    utentePresente(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            return this.timeoutRicerca(control.value)
                .pipe(
                    map(res => {
                        return (res.valore) ? { 'userNameExists': true } : null;                        
                    })
                );
        };
    }
}