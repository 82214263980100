import { MatDialog } from '@angular/material/dialog';
import { Fornitori } from './../../modelli/shared/fornitori';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { FornitoriDataService } from 'src/app/services/data/fornitori-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CrudFornitoreComponent } from 'src/app/componentAdmin/crud-fornitore/crud-fornitore.component';

@Component({
  selector: 'app-fornitori',
  templateUrl: './fornitori.component.html',
  styleUrls: ['./fornitori.component.css']
})
export class FornitoriComponent implements OnInit {

  displayedColumns: string[] = ['id','ragioneSociale','modifica','elimina'];  
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('vc', {read: ViewContainerRef}) vcr: ViewContainerRef;

  fornitori:Fornitori[];
  numeroFornitori:number;

  apiMsg:ApiMsg;

  constructor(private fornitoriService:FornitoriDataService, private router:Router,private snackBar: MatSnackBar, private cfr:ComponentFactoryResolver) { }

  ngOnInit(): void {
    this.getFornitori();
  }

  getFornitori(){
    this.fornitoriService.elencoFornitoriCompleto().subscribe(
      response=>{
        this.fornitori=[];
        this.fornitori=response;
        this.dataSource = new MatTableDataSource(this.fornitori);      
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator; 
        this.numeroFornitori=this.fornitori.length;
      }
    )
  }

  eliminaFornitore(id:number){
    this.fornitoriService.eliminaFornitore(id).subscribe(
      response=>{
        this.apiMsg=response;
        this.visualizzaSnackBar();
        this.getFornitori();
      },error=>{
        this.apiMsg=error.error;
        this.visualizzaSnackBar();
      }
    )
  }

  apriFornitore(id:number){
    // this.router.navigate(['amm/fornitore',id]);
    let componentFactory =  this.cfr.resolveComponentFactory(CrudFornitoreComponent);
    this.vcr.clear();
    let component = this.vcr.createComponent(componentFactory);
    component.instance.id=id;
    //Rimango in ascolto della variabile finito del figlio, quando questa diventa true distruggo il figlio
    component.instance.finito.subscribe(
      response=>{
        if(response==true){
          this.vcr.clear();
          this.getFornitori();
        }
      }
    )
  }

  applicaFiltro(filtro:string){
    this.dataSource.filter= filtro.trim().toLowerCase();
  }

  visualizzaSnackBar(){
    let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }

}
