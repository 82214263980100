import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PatchMethod } from './../../modelli/shared/patchMethod';
import { UtentiDataService } from './../../services/data/utenti-data.service';
import { Utenti } from './../../modelli/shared/utenti';
import { Component, OnInit } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-utente',
  templateUrl: './utente.component.html',
  styleUrls: ['./utente.component.css']
})
export class UtenteComponent implements OnInit {
  f:FormGroup;
  username:string="";
  utente:Utenti;
  
  apiMsg: ApiMsg;
  erroreMsg:string = "";
  confermaMsg: string = "";
  

  constructor(private route:ActivatedRoute,private utentiService:UtentiDataService,private router:Router) { }

  ngOnInit(): void {    
    
    this.username=this.route.snapshot.params['username'];
    //Son sempre in modifica perchè non esiste per adesso l'inserimento
    this.utentiService.cercaUtente(this.username).subscribe(
      response=>{
        this.utente=response;
        this.f = new FormGroup({
          'nome': new FormControl(this.utente.nome,[Validators.required]),
          'cognome': new FormControl(this.utente.cognome,[Validators.required])
        })
      },
      error=>{
        this.erroreMsg=error.error;
      }
    )
  }

  get nome(){
    return this.f.get('nome');
  }

  get cognome(){
    return this.f.get('cognome');
  }

  salva(dati:FormGroup){    
    this.confermaMsg="";
    this.erroreMsg="";
    
    //Creo l'array di campi che passo poi per la patch
    let paramNome = new PatchMethod(this.nome.value,'/Nome','replace');
    let paramCognome = new PatchMethod(this.cognome.value,'/Cognome','replace');

    this.utentiService.modificaUtente(this.utente.username,[paramNome,paramCognome]).subscribe(
      response=>{
        this.apiMsg=response;
        this.confermaMsg=this.apiMsg.messaggio;          
      },
      error=>{
        this.apiMsg = error.error;
        this.erroreMsg = this.apiMsg.messaggio;
      }
    )
  }

  chiudi(){
    this.router.navigate(['amm/utenti']);
  }

}
