import { UtentiIndirizziDataService } from './../../services/data/utenti-indirizzi-data.service';
import { ApiMsg } from './../../modelli/shared/apiMsg';
import { UtentiIndirizzo } from './../../modelli/shared/utentiIndirizzo';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { AuthJWTService } from 'src/app/services/auth-jwt.service';

@Component({
  selector: 'app-crud-utenti-indirizzi',
  templateUrl: './crud-utenti-indirizzi.component.html',
  styleUrls: ['./crud-utenti-indirizzi.component.css']
})
export class CrudUtentiIndirizziComponent implements OnInit {

  @Input('tipo') tipoIndirizzo: string;
  @Output() finito = new EventEmitter<boolean>();

  f: FormGroup;
  utenteIndirizzo: UtentiIndirizzo;
  indirizziSpedizionePerFatturazione: UtentiIndirizzo[];
  idIndirizzoSpedizionePerFatturazione: number = 0;

  apiMsg: ApiMsg;

  constructor(private indirizziService: UtentiIndirizziDataService, private snackBar: MatSnackBar, public AuthJWT: AuthJWTService, private indirizziServiceSelezione: UtentiIndirizziDataService) { }

  ngOnInit(): void {
    this.PopolaDati();
    if (this.tipoIndirizzo == "F") {
      this.indirizziServiceSelezione.cercaByUsername(this.AuthJWT.loggedUser()).subscribe(
        response => {
          this.indirizziSpedizionePerFatturazione = response;
          this.indirizziSpedizionePerFatturazione = this.indirizziSpedizionePerFatturazione.filter(f => f.tipo == "S");
        }
      )
    }
  }

  PopolaDati() {
    this.utenteIndirizzo = new UtentiIndirizzo(0, "", "", "", "", "", "", "", false, "", "", "", "", "", false, "", "", null, null)
    this.f = new FormGroup({
      'indirizzo': new FormControl('', [Validators.minLength(2), Validators.maxLength(100)]),
      'cap': new FormControl('', [Validators.minLength(5), Validators.maxLength(5)]),
      'comune': new FormControl('', [Validators.minLength(2), Validators.maxLength(100)]),
      'provincia': new FormControl('', [Validators.minLength(2), Validators.maxLength(2)]),
      'stato': new FormControl('', [Validators.minLength(3), Validators.maxLength(50)]),
      'telefono': new FormControl('', [Validators.required, Validators.maxLength(50)]),
      'fatturaPIVA': new FormControl('', Validators.maxLength(11)),
      'fatturaCodFisc': new FormControl('', Validators.maxLength(16)),
      'fatturaCodiceDestinatario': new FormControl('', Validators.maxLength(7)),
      'fatturaPec': new FormControl('', Validators.email),
      'nominativo': new FormControl('', [Validators.minLength(2), Validators.maxLength(100)]),
      'ulterioriInformazioni': new FormControl('', Validators.maxLength(200))
    })
  }


  get indirizzo() {
    return this.f.get('indirizzo');
  }

  get cap() {
    return this.f.get('cap');
  }

  get comune() {
    return this.f.get('comune');
  }

  get provincia() {
    return this.f.get('provincia')
  }

  get stato() {
    return this.f.get('stato');
  }

  get tipo() {
    return this.f.get('tipo');
  }

  get telefono() {
    return this.f.get('telefono');
  }

  get fatturaPIVA() {
    return this.f.get('fatturaPIVA');
  }

  get fatturaCodFisc() {
    return this.f.get('fatturaCodFisc');
  }

  get fatturaCodiceDestinatario() {
    return this.f.get('fatturaCodiceDestinatario');
  }

  get fatturaPec() {
    return this.f.get('fatturaPec');
  }

  get nominativo() {
    return this.f.get('nominativo');
  }

  get ulterioriInformazioni() {
    return this.f.get('ulterioriInformazioni');
  }

  salvaIndirizzo(dati: FormGroup) {
    this.utenteIndirizzo = dati.value;
    this.utenteIndirizzo.tipo = this.tipoIndirizzo;
    this.utenteIndirizzo.userName = this.AuthJWT.loggedUser();

    this.indirizziService.inserisciIndirizzo(this.utenteIndirizzo).subscribe(
      response => {
        this.apiMsg = response;
        this.visualizzaSnackBar();
      }, error => {
        this.apiMsg = error;
      }, () => {
        this.annulla();
      }
    )

  }

  visualizzaSnackBar() {
    let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }

  //Con questa funzione chiudo il componente
  annulla() {
    this.finito.emit(true);
  }

  inserisciDaIndirizzoSpedizione(indirizzo: UtentiIndirizzo) {    
    this.nominativo.setValue(indirizzo.nominativo);
    this.indirizzo.setValue(indirizzo.indirizzo);
    this.cap.setValue(indirizzo.cap)
    this.comune.setValue(indirizzo.comune);
    this.provincia.setValue(indirizzo.provincia);
    this.stato.setValue(indirizzo.stato);
    this.telefono.setValue(indirizzo.telefono);
  }

}
