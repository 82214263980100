import { EmailNewsletter } from './../../modelli/shared/emailNewsletter';
import { EmailNewsletterDataService } from './../../services/data/email-newsletter-data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newlsetters',
  templateUrl: './newlsetters.component.html',
  styleUrls: ['./newlsetters.component.css']
})
export class NewlsettersComponent implements OnInit {
  newsletters:EmailNewsletter[];
  indirizziMail:string;

  constructor(private newslettersService:EmailNewsletterDataService) { }

  ngOnInit(): void {
    this.newslettersService.elencoEmailNewsletter().subscribe(
      response=>{
        this.newsletters=response;
        let tmpIndirizzi:string="";
        this.newsletters.forEach(function(mail){
          if(mail.privacyAccettata){
            tmpIndirizzi += mail.email + "; "
          }         
        })
        this.indirizziMail=tmpIndirizzi;
        
      }
    )
    
  }  

}
