import { ArticoliCaratteristiche } from 'src/app/modelli/shared/articoliCaratteristiche';
import { FiltroArticoli } from 'src/app/modelli/shared/Classi/Filtri/filtroArticoli';
import { ArticoliDataService } from 'src/app/services/data/articoli-data.service';
import { Component, OnInit } from '@angular/core';
import { OggettoInput } from 'src/app/component/griglia-layout/griglia-layout.component';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { FunzioniLocalStorage } from 'src/app/modelli/shared/Funzioni/funzioniLocalStorage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  oggetti: string[];
  oggettiInput: OggettoInput[];
  articoliUltimi: Articoli[]=[];
  articoliBio: Articoli[]=[];
  articoliIta: Articoli[]=[];
  idCaratteristicaUno: number =6;
  idCaratteristicaDue:number=3;


  constructor(private articoliService: ArticoliDataService, private funzioniLS: FunzioniLocalStorage, private route: Router) { }

  ngOnInit(): void {
    this.oggetti = ["assets/img/molino-lucconi-homepage.jpg", "assets/img/molino-lucconi-homepage-2.png", "assets/img/molino-lucconi-farina-macinata-a-pietra.png"];

    let oggetto1: OggettoInput = new OggettoInput(1,"assets/img/molino-lucconi-alimentari.png","Alimentari");
    let oggetto2: OggettoInput = new OggettoInput(12,"assets/img/molino-lucconi-pet-food.png","Pet Food");
    let oggetto3: OggettoInput = new OggettoInput(9,"assets/img/molino-lucconi-zootecnia.png","Zootecnia");
    let oggetto4: OggettoInput = new OggettoInput(16,"assets/img/molino-lucconi-orto-e-giardino.png", "Orto e giardino");
    let oggetto5: OggettoInput = new OggettoInput(21,"assets/img/molino-luconi-prodotti-per-la-casa.png","Prodotti per la Casa");

    this.oggettiInput = [oggetto1, oggetto2, oggetto3, oggetto4, oggetto5];

    this.popolaArticoli();
  }

  popolaArticoli() {
    let filtro: FiltroArticoli = new FiltroArticoli(0, 0, null, 0, 0, 0, "", true);
    this.articoliService.cercaArticoliConFiltri(filtro).subscribe(
      response => {
        let tmpArticoliUltimi: Articoli[] = response;
        tmpArticoliUltimi = tmpArticoliUltimi.filter(f=>f.timeStamp_Ins != null);
        tmpArticoliUltimi = tmpArticoliUltimi.sort((a, b) => (a.timeStamp_Ins > b.timeStamp_Ins ? -1 : 1));
        
        this.articoliUltimi = [];
        if (tmpArticoliUltimi.length>0){
          this.articoliUltimi.push(tmpArticoliUltimi[0])
        }
        if (tmpArticoliUltimi.length>1){
          this.articoliUltimi.push(tmpArticoliUltimi[1])
        }
        if (tmpArticoliUltimi.length>2){
          this.articoliUltimi.push(tmpArticoliUltimi[2])
        }
        if (tmpArticoliUltimi.length>3){
          this.articoliUltimi.push(tmpArticoliUltimi[3])
        }
        if (tmpArticoliUltimi.length>4){
          this.articoliUltimi.push(tmpArticoliUltimi[4])  
        }              
      }
    )

    let articoloCaratteristicheBio: ArticoliCaratteristiche = new ArticoliCaratteristiche(this.idCaratteristicaUno, "", null);
    let filtroBio: FiltroArticoli = new FiltroArticoli(0, 0, [articoloCaratteristicheBio], 0, 0, 0, "", true);
    this.articoliService.cercaArticoliConFiltri(filtroBio).subscribe(
      response => {
        this.articoliBio = response;
      },error=>{
        
        
      }
    )

    let articoloCaratteristicheIta: ArticoliCaratteristiche = new ArticoliCaratteristiche(this.idCaratteristicaDue, "", null);
    let filtroIta: FiltroArticoli = new FiltroArticoli(0, 0, [articoloCaratteristicheIta], 0, 0, 0, "", true);
    this.articoliService.cercaArticoliConFiltri(filtroIta).subscribe(
      response => {
        this.articoliIta = response;       
      }
    )
  }

  filtra(idCaratteristica:number) {
    if (idCaratteristica == 0) {
      this.funzioniLS.removeFiltroArticoli();
    } else {
      let caratteristica:ArticoliCaratteristiche = new ArticoliCaratteristiche(idCaratteristica,"",null);
      let filtro: FiltroArticoli = new FiltroArticoli(0, 0, [caratteristica], 0, 0, 0, "", false);
      this.funzioniLS.setFiltroArticoli(filtro);
     
    }
    this.route.navigate(['spesa-online']);
  }
}
