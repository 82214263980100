<section class="container">
    <div class="row">
        <div class="col-lg-12">
            <mat-card class="mat-elevation-z20">
                <mat-card-title class="text-center">
                    <label *ngIf="tmpcodArt=='-1'">Nuovo articolo</label>
                    <label *ngIf="tmpcodArt!='-1'">Articolo: {{ articolo.codArt }}</label>
                </mat-card-title>
                <mat-card-content>
                    <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salva(f)">
                        <p>
                            <mat-form-field>
                                <mat-label>Codice articolo</mat-label>
                                <input matInput id="codArt" formControlName="codArt">
                                <mat-hint align="end">{{codArt?.value?.length}} / 100</mat-hint>
                                <mat-hint *ngIf="codArt.touched && codArt.errors?.required" style="color: red;">Il
                                    codice
                                    articolo è obbligatorio</mat-hint>
                                <mat-hint *ngIf="codArt.touched && codArt.errors?.minlength" style="color: red;">Il
                                    codice
                                    articolo deve essere minimo di 5 caratteri.</mat-hint>
                                <mat-hint *ngIf="codArt.touched && codArt.errors?.maxlenght" style="color: red;">Il
                                    codice
                                    articolo deve essere massimo di 200 caratteri.</mat-hint>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field>
                                <mat-label>Titolo</mat-label>
                                <input matInput id="titolo" formControlName="titolo">
                                <mat-hint align="end">{{titolo?.value?.length}} / 200</mat-hint>
                                <mat-hint *ngIf="titolo.touched && titolo.errors?.required" style="color: red;">Il
                                    titolo è
                                    obbligatorio</mat-hint>
                                <mat-hint *ngIf="titolo.touched && titolo.errors?.minlength" style="color: red;">Il
                                    titolo
                                    deve
                                    essere minimo di 5 caratteri.</mat-hint>
                                <mat-hint *ngIf="titolo.touched && titolo.errors?.maxlenght" style="color: red;">Il
                                    titolo
                                    deve
                                    essere massimo di 200 caratteri.</mat-hint>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field>
                                <mat-label>Descrizione</mat-label>
                                <textarea matInput id="descrizione" rows="1" formControlName="descrizione"></textarea>
                                <mat-hint align="end">{{descrizione?.value?.length}} / 1000</mat-hint>
                                <mat-hint *ngIf="descrizione.touched && descrizione.errors?.required"
                                    style="color: red;">La
                                    descrizione è obbligatoria</mat-hint>
                                <mat-hint *ngIf="descrizione.touched && descrizione.errors?.minlength"
                                    style="color: red;">
                                    La
                                    descrizione deve essere minimo di 10 caratteri.</mat-hint>
                                <mat-hint *ngIf="descrizione.touched && descrizione.errors?.maxlenght"
                                    style="color: red;">
                                    La
                                    descrizione deve essere massimo di 1000 caratteri.</mat-hint>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field>
                                <mat-label>Prezzo</mat-label>
                                <input matInput type="number" id="prezzo" formControlName="prezzo">
                                <mat-hint *ngIf="prezzo.touched && prezzo.errors?.required" style="color: red;">Il
                                    prezzo è obbligatorio</mat-hint>
                                <mat-hint *ngIf="prezzo.touched && prezzo.errors?.min" style="color: red;">Il
                                    prezzo deve essere maggiore di 0</mat-hint>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field>
                                <mat-label>Ingredienti</mat-label>
                                <textarea matInput id="ingredienti" rows="1" formControlName="ingredienti"></textarea>
                            </mat-form-field>
                        </p>
                        <p>
                            <button type="button" class="btn btn-primary btn-sm" (click)="apriFornitore()">Nuovo
                                fornitore/produttore</button>
                            <ng-template #vc></ng-template>
                        </p>
                        <p>
                            <mat-form-field>
                                <mat-label>Produttore</mat-label>
                                <mat-select name="idProduttore" formControlName="idProduttore" #sProduttori>
                                    <mat-select-filter [placeholder]="'Cerca'" *ngIf="sProduttori.focused"
                                        [displayMember]="'ragioneSociale'" [array]="produttori"
                                        (filteredReturn)="fProduttori =$event"></mat-select-filter>
                                    <mat-option value="0">Nessun produttore selezionato</mat-option>
                                    <mat-option [value]="produttore.id" *ngFor="let produttore of fProduttori">
                                        {{produttore.ragioneSociale}}</mat-option>
                                </mat-select>
                                <mat-hint *ngIf="idProduttore.touched && idProduttore.errors?.min" style="color: red;">
                                    Il
                                    produttore è obbligatorio</mat-hint>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field>
                                <mat-label>Fornitore</mat-label>
                                <mat-select name="idFornitore" formControlName="idFornitore" #sFornitori>
                                    <mat-select-filter [placeholder]="'Cerca'" *ngIf="sFornitori.focused"
                                        [displayMember]="'ragioneSociale'" [array]="fornitori"
                                        (filteredReturn)="fFornitori =$event"></mat-select-filter>
                                    <mat-option value="0">Nessun fornitore selezionato</mat-option>
                                    <mat-option [value]="fornitore.id" *ngFor="let fornitore of fFornitori">
                                        {{fornitore.ragioneSociale}}</mat-option>
                                </mat-select>
                                <mat-hint *ngIf="idFornitore.touched && idFornitore.errors?.min" style="color: red;">Il
                                    fornitore è obbligatorio</mat-hint>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field>
                                <mat-label>Categoria</mat-label>
                                <mat-select name="idCategoria" formControlName="idCategoria" #sCategorie>
                                    <mat-select-filter [placeholder]="'Cerca'" *ngIf="sCategorie.focused"
                                        [displayMember]="'descrizione'" [array]="categorie"
                                        (filteredReturn)="fCategorie =$event">
                                    </mat-select-filter>
                                    <mat-option value="0">Nessuna categoria selezionata</mat-option>
                                    <mat-option [value]="categoria.id" *ngFor="let categoria of fCategorie">
                                        {{categoria.descrizione}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint *ngIf="idCategoria.touched && idCategoria.errors?.min" style="color: red;">La
                                    categoria è obbligatoria</mat-hint>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field>
                                <mat-label>Caratteristiche</mat-label>
                                <mat-select name="ddlCaratteristiche" formControlName="ddlCaratteristiche"
                                    #sDdlCaratteristiche multiple>
                                    <mat-select-trigger>
                                        <mat-chip-list>
                                            <mat-chip *ngFor="let topping of f.get('ddlCaratteristiche').value">
                                                {{ topping }}
                                            </mat-chip>
                                        </mat-chip-list>
                                    </mat-select-trigger>
                                    <mat-option #matOption *ngFor="let car of fcaratteristiche" [value]="car.titolo"
                                        (click)="clickSel(matOption.selected,car);sDdlCaratteristiche.close()">
                                        {{car.titolo}}</mat-option>
                                </mat-select>

                            </mat-form-field>
                        </p>
                        <p>
                            <mat-checkbox id="noVisualizzaOnline" formControlName="noVisualizzaOnline" style="margin-right: 20px;">Non visualizzare online</mat-checkbox>
                            <mat-checkbox id="soloRitiro" formControlName="soloRitiro">Disponibile solo per il ritiro</mat-checkbox>
                        </p>
                        <p>
                            <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit"
                                style="margin: 8px 8px 8px 0;">
                                <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
                            </button>
                            <button mat-flat-button color="warn" id=btnChiudi type="submit" class="pull-right"
                                style="margin: 8px 8px 8px 0;" (click)="chiudi()">
                                <i class="fa fa-times" aria-hidden="true"></i> Chiudi
                            </button>
                        </p>
                        <p class="alert alert-danger" *ngIf="erroreMsg">
                            {{erroreMsg}}
                        </p>
                    </form>
                    <hr>
                    <button *ngIf="tmpcodArt !='-1'" mat-flat-button id=btnNuovo type="button"
                        style="margin: 8px 8px 8px 0;background-color:#ffc107" (click)="duplicaArticolo()">
                        <i class="fa fa-copy" aria-hidden="true"></i> Duplica
                    </button>
                </mat-card-content>
            </mat-card>
        </div>
        <div *ngIf="IsModifica && articolo.codArt !=''" class="col-lg-12">
            <app-articolo-foto [articolo]="articolo"></app-articolo-foto>
        </div>
        <div class="col-lg-12">
            <button mat-flat-button color="warn" type="button" class="pull-right"
            style="margin: 8px 8px 8px 0;" (click)="chiudi()">
            <i class="fa fa-times" aria-hidden="true"></i> Chiudi
        </button>
        </div>
        

    </div>



</section>