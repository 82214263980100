export class ArticoliCategorie {
    constructor(
        public id:number,
        public titolo:string,
        public descrizione:string,
        public dicituraUrl:string,        
        public idPadre:number,
        public ultima:boolean,
        public livello:number,
        public padre:ArticoliCategorie,
        public figli:ArticoliCategorie[]
    ) { }
  }
