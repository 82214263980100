import { OrdinidettaglioDataService } from './../../services/data/ordinidettaglio-data.service';
import { CrudOrdineDettaglioComponent } from './../../componentAdmin/crud-ordine-dettaglio/crud-ordine-dettaglio.component';
import { OrdinitestataDataService } from 'src/app/services/data/ordinitestata-data.service';
import { OrdiniTestata } from 'src/app/modelli/shared/ordiniTestata';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { OrdiniDettaglio } from 'src/app/modelli/shared/ordiniDettaglio';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';

@Component({
  selector: 'app-ordine',
  templateUrl: './ordine.component.html',
  styleUrls: ['./ordine.component.css']
})
export class OrdineComponent implements OnInit {
  displayedColumns: string[] = ['codart', 'descrizione', 'qta', 'prezzo', 'totale', 'modifica', 'elimina'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('vc', { read: ViewContainerRef }) vcr: ViewContainerRef;

  tmpid: number = 0;
  ordine: OrdiniTestata;
  apiMsg: ApiMsg;

  dettagli: OrdiniDettaglio[];

  constructor(private route: ActivatedRoute, private ordiniService: OrdinitestataDataService, private router: Router, private cfr: ComponentFactoryResolver,
    private ordiniDettaglioService:OrdinidettaglioDataService,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.popolaOrdine();
  }

  popolaOrdine() {
    this.ordine = new OrdiniTestata(0, "", null, "", null, 0,0,0,0,0,0,0,false,null,null,null,null,null,null);
    this.tmpid = this.route.snapshot.params['id'];
    if (this.tmpid > 0) {
      this.ordiniService.cercaByID(this.tmpid).subscribe(
        response => {
          this.ordine = response;
          this.dettagli = [];
          this.dettagli = this.ordine.ordineDettagli;
          this.dataSource = null;
          this.dataSource = new MatTableDataSource(this.dettagli);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      )
    } else {
      this.ordine.id = 0;
    }
  }

  eliminaOrdine(idDettaglio: number) {
    
      this.ordiniDettaglioService.elimina(idDettaglio).subscribe(
        response => {
          this.apiMsg = response;
          let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
          let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
          this.popolaOrdine();
        }
      )
    

  }

  apriDettaglio(id:number) {
    
      let componentFactory = this.cfr.resolveComponentFactory(CrudOrdineDettaglioComponent);
      this.vcr.clear();
      let component = this.vcr.createComponent(componentFactory);      
      component.instance.idDettaglio = id;      
      component.instance.idTestata = this.ordine.id;
      //Rimango in ascolto della variabile finito del figlio, quando questa diventa true distruggo il figlio
      component.instance.finito.subscribe(
        response => {
          if (response == true) {
            this.vcr.clear();
            this.popolaOrdine();
          }
        }
      )

  }

  chiudi() {
    this.router.navigate(['amm/ordini']);
  }



}
