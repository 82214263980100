import { ApiMsg } from './../../modelli/shared/apiMsg';
import { OrdiniTestata } from './../../modelli/shared/ordiniTestata';
import { OrdinitestataDataService } from 'src/app/services/data/ordinitestata-data.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-conclusione-ordine',
  templateUrl: './conclusione-ordine.component.html',
  styleUrls: ['./conclusione-ordine.component.css']
})
export class ConclusioneOrdineComponent implements OnInit {
  utenteCorretto:number=0; //0=>Non elaborato 1=>Utente proprietario -1=>utente non proprietario
  idOrdine:number=0;
  ordine:OrdiniTestata;

  constructor(private route: ActivatedRoute,private router:Router,private ordiniService:OrdinitestataDataService) { }

  ngOnInit(): void {
    this.idOrdine=this.route.snapshot.params['idOrdine'];
    if (this.idOrdine>0){
      this.ordiniService.cercaByID(this.idOrdine).subscribe(
        response=>{
          this.ordine=response;
          if (this.ordine.id>1){
            this.utenteCorretto=1;
          }else{
            this.utenteCorretto=-1;
          }
          
        },
        error=>{
          this.utenteCorretto=-1;    
        }
      )
    }else{
      this.utenteCorretto=-1;
    }
  }
  

}
