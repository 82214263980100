<mat-sidenav-container opened class="  bg-light">
  <mat-sidenav class="bg-light  d-none d-lg-flex w-25" #sidenav mode="side" opened
    style="text-align: -webkit-center; margin-right: 0px!important;">
    <div class="col-lg-8  text-center">
      <p class="my-4">CATEGORIE</p>
      <button *ngFor="let catUno of categoriePadri" value="{{catUno.id}}" mat-button color="basic"
        class="w-100 text-success text-uppercase border mt-1 bg-white" (click)="filtraCategoria(1,catUno.id)">
        {{catUno.titolo}}</button>
      <p class="my-4">SOTTOCATEGORIE</p>
      <button *ngFor="let sottoCatUno of categorieLivelloDue" value="{{sottoCatUno.id}}" mat-button color="basic"
        class="w-100 text-success text-uppercase border mt-1 bg-white" (click)="filtraCategoria(2,sottoCatUno.id)">
        {{sottoCatUno.titolo}}</button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="filtri-attivi py-3  mat-elevation-z4 row m-0" style="justify-content: center;">
      <p class="testo-troncato text-white bg-grey p-2 " *ngFor="let chips of tmpChipsFiltri">{{chips}}</p>
    </div>
    <div class="container mt-4">
      <mat-expansion-panel hideToggle [expanded]="pannelloFiltroAperto === true"
        (opened)="chiudiApriPannelloFIltro(true)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="mt-2 mr-2 titoli">FILTRA GLI ARTICOLI</p>
            <mat-icon class="mt-1">keyboard_arrow_down</mat-icon>
          </mat-panel-title>

        </mat-expansion-panel-header>
        <div class=" container bg-white text-viola">
          <form *ngIf="f" [formGroup]="f" (ngSubmit)="filtra(f)">
            <div class="row col-lg-10 p-2 " style="justify-content: center;">
              <div class="col-lg-10">
                <mat-form-field class="example-form-field">
                  <mat-label>
                    <mat-icon>search</mat-icon>
                    Cerca
                  </mat-label>
                  <input matInput id="keySearch" formControlName="keySearch">
                </mat-form-field>
              </div>
              <div class="col-lg-2 vai">
                <button class="btn" type="submit"><i class="fa fa-search"></i></button>
              </div>
            </div>
            <hr>
            <p class="text-uppercase mx-3 my-1">Filtra per Categoria</p>
            <div class="row p-4">
              <mat-button-toggle-group class="row" id="idCategoriaPadri" formControlName="idCategoriaPadri"
                aria-label="Font Style" (change)='resettaDatoFiltro("idCategoriaPadri")' (click)="cancellaKeySearch()">
                <mat-button-toggle class="m-2" value=0>
                  Tutti
                </mat-button-toggle>
                <mat-button-toggle class="m-2" *ngFor="let catUno of categoriePadri" value="{{catUno.id}}">
                  {{catUno.titolo}}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <mat-form-field appearance="fill" class="mt-3 ">
              <mat-label class="text-viola">Filtra per sottocategoria</mat-label>
              <mat-select id="idCategoriaLivelloDue" formControlName="idCategoriaLivelloDue"
                (selectionChange)='resettaDatoFiltro("idCategoriaLivelloDue")'>
                <mat-option value="0">Tutte</mat-option>
                <mat-option class="text-viola" *ngFor="let sottoCatUno of categorieLivelloDue"
                  value="{{sottoCatUno.id}}">
                  {{sottoCatUno.titolo}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="mt-3 ">
              <mat-label class="text-viola">Filtra per produttore</mat-label>
              <mat-select id="idProduttore" formControlName="idProduttore">
                <mat-option value="0"></mat-option>
                <mat-option class="text-viola" *ngFor="let prodUno of produttori" value="{{prodUno.id}}">
                  {{prodUno.ragioneSociale}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="container">
              <div class="row">
                <mat-button-toggle-group class="row" multiple=true id="caratteristicheGroup"
                  formControlName="caratteristicheGroup">
                  <mat-button-toggle class="text-viola col-lg-2" *ngFor="let carUno of caratteristiche"
                    value="{{carUno.id}}">
                    {{carUno.titolo}}
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <hr>
            <div class="text-left">
              <div class="row">
                <div class="col-lg-3">
                  <p class="text-uppercase m-3">Prezzo max</p>
                  <mat-form-field class="example-full-width">
                    <!-- <mat-label>Prezzo max</mat-label> -->
                    <span matPrefix>€ &nbsp;</span>
                    <input type="number" matInput id="prezzoMax" formControlName="prezzoMax">
                  </mat-form-field>
                </div>
                <div class="col-lg-2">
                  <mat-checkbox id="soloDisponibili" formControlName="soloDisponibili">Solo disponibili</mat-checkbox>
                </div>
              </div>
            </div>
            <div class="text-center mt-4 mb-5">
              <button mat-raised-button class="viola text-white" type="submit">FILTRA</button>
            </div>
            <div class="text-center mx-3 mt-4 mb-3">
              <button mat-raised-button class="bg-danger text-white" (click)="resettaFiltro()" type="button">RESET
                FILTRI</button>
            </div>
          </form>
        </div>
      </mat-expansion-panel>
      <div class="row mx-1 text-center">
        <div class="col-lg-4">
          <div class="  w-100 my-4 ">
            <button mat-raised-button class="viola text-white" (click)="resettaFiltro()">RESET FILTRI</button>
          </div>
        </div>
        <div class="col-lg-4 mt-4">
          <mat-checkbox #chkDisponibilita class="example-margin"
            (change)="filtraPerDisponibilita(chkDisponibilita.checked)" [(ngModel)]="visualizzaDisponibili">Solo
            disponibili</mat-checkbox>
        </div>
        <div class="col-lg-3 " style="text-align: -webkit-right;">
          <div class="  w-100 my-4 ">

            <mat-button-toggle-group class="row" aria-label="Font Style">
              <mat-button-toggle (click)="ordinaArticoli(0)">
                <mat-icon>trending_up</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle (click)="ordinaArticoli(1)">
                <mat-icon>trending_down</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle (click)="ordinaArticoli(2)">
                <mat-icon>fiber_new</mat-icon>
              </mat-button-toggle>

            </mat-button-toggle-group>

          </div>
        </div>
      </div>
      <div class="row centrare">
        <div class="col-lg-12 text-center alert alert-dark text-uppercase" *ngIf="numeroArticoli==0" role="alert">
          <br><br>Nessun articolo da
          visualizzare con il filtro selezionato<div style="height: 1100px;"></div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4 align-self-stretch"
          *ngFor="let articolo of articoli | slice: (currentPage-1) * 6 : (currentPage-1) * 6 + 6">
          <app-articoli-card [articolo]="articolo" [visualizzazione]="2"></app-articoli-card>
        </div>
        <div class="row centrare col-sm-12" *ngIf="sizeArticoli>0">
          <!-- <pagination-controls (pageChange)="cambioPagina($event)">
          </pagination-controls> -->
          <ngb-pagination [collectionSize]="sizeArticoli" [boundaryLinks]="true" [rotate]="true" [pageSize]="6"
            [maxSize]="3" [(page)]="currentPage" (pageChange)="cambioPagina($event,sizeArticoli)"
            class="d-flex justify-content-end pagination-rounded-flat pagination-success">
            
          </ngb-pagination>
        </div>
        <div style="height: 20rem;"></div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>