import { ApiMsg } from './../../modelli/shared/apiMsg';
import { EmailNewsletter } from './../../modelli/shared/emailNewsletter';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { authServerUrlEmailNewsletter } from 'src/app/modelli/app.contstant';

@Injectable({
    providedIn: 'root'
  })
  export class EmailNewsletterDataService {
  
    constructor(private http: HttpClient) { }   
  
    elencoEmailNewsletter() {
      return this.http.get<EmailNewsletter[]>(authServerUrlEmailNewsletter + "/elenco");
    }

    inserisciIscrizione(emailNewsletter:EmailNewsletter){
        return this.http.post<ApiMsg>(authServerUrlEmailNewsletter + "/inserisci",emailNewsletter);
    }

    rimuoviIscrizione(email:string,removecode:string){
        
        
        return this.http.delete<ApiMsg>(authServerUrlEmailNewsletter + "/rimuovi/" + email + "/" + removecode);
    }
      
  }