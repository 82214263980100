import { AppImpostazioni } from './../../modelli/shared/appImpostazioni';
import { AppImpostazioniDataService } from './../../services/data/app-impostazioni-data.service';
import { AppCarrelloService } from 'src/app/services/app/app-carrello.service';
import { FunzioniLocalStorage } from './../../modelli/shared/Funzioni/funzioniLocalStorage';
import { CarrelliDettaglio } from './../../modelli/shared/carrelliDettaglio';
import { Component, OnInit } from '@angular/core';
import { CarrellidettaglioDataService } from 'src/app/services/data/carrellidettaglio-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carrello',
  templateUrl: './carrello.component.html',
  styleUrls: ['./carrello.component.css']
})
export class CarrelloComponent implements OnInit {
  dettagli: CarrelliDettaglio[];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [ 'titolo', 'prezzo', 'qta', 'totale', 'elimina'];
  apiMsg: ApiMsg;

  appImpostazioni:AppImpostazioni;
  totaleCarrello:number=0;
  visualizzaMessaggioOrdineMinimo:boolean=false;
  articoloSoloRitiroPresente:boolean=false;

  constructor(private carrelloDettagliService: CarrellidettaglioDataService, private appCarrello:AppCarrelloService ,private router:Router,
    private funzioniLocalStorage:FunzioniLocalStorage, private appImpostazioniService:AppImpostazioniDataService) { }

  ngOnInit(): void {
    this.getDettagliCarrello();
  }

  getDettagliCarrello() {
    this.carrelloDettagliService.elencoCarrelloDettagliByIdTestata(this.funzioniLocalStorage.getIdCart()).subscribe(
      response => {
        this.dettagli = [];
        this.dettagli = response;
        this.dataSource = new MatTableDataSource(this.dettagli);

        //Calcolo il totale del carrello ed inserisco eventualmente la dicitura e la cifra delle spedizioni
        let totaleDettagliCarrello:number=0;
        let tmpSoloRitiro:boolean=false;
        if(this.dettagli != null){
          this.dettagli.forEach(function (x){
            totaleDettagliCarrello += x.articolo.prezzo * x.qta;
            if(x.articolo.soloRitiro){
              tmpSoloRitiro=true;
            }
          })
          this.totaleCarrello=totaleDettagliCarrello;
          this.articoloSoloRitiroPresente=tmpSoloRitiro;
          
          //Recupero la cifra minima d'ordine
          this.appImpostazioniService.lista().subscribe(
            response=>{
              this.appImpostazioni=response;
              if (this.totaleCarrello < this.appImpostazioni.importoOrdineMinimo){
                this.visualizzaMessaggioOrdineMinimo=true;
              }else{
                this.visualizzaMessaggioOrdineMinimo=false;
              }
            }
          )
        }                
      }
    )
  }




  variaQuantita(idCarrelloDettaglio:number,qta:number){
    if(qta>0){
      this.appCarrello.aggiornaQtaDettaglio(idCarrelloDettaglio,qta).subscribe(
      response=>{
        this.getDettagliCarrello();
      }
    )
    }  
  }

  eliminaRiga(id: number) {
    this.appCarrello.eliminaDettaglio(id).subscribe(
      response=>{
        this.getDettagliCarrello();
      }
    );    
  }

  vaiAlCheckout(){
    this.router.navigate(['checkout']);
  }



}
