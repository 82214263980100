import { backServerUrlCarrelliDettaglio } from './../../modelli/app.contstant';
import { CarrelliDettaglio } from './../../modelli/shared/carrelliDettaglio';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';

@Injectable({
  providedIn: 'root'
})
export class CarrellidettaglioDataService {

  constructor(private http:HttpClient) { }

  inserisciCarrelloDettaglio(carrelloDettaglio:CarrelliDettaglio){
    return this.http.post<CarrelliDettaglio>(backServerUrlCarrelliDettaglio + "/inserisci",carrelloDettaglio);
  }

  aggiornaQuantitaRiga(idRiga:number,qta:number){
    return this.http.post<ApiMsg>(backServerUrlCarrelliDettaglio + "/aggiornaqta/" + idRiga + "/" + qta, idRiga)
  }

  elencoCarrelloDettagliByIdTestata(idTestata:string){
    return this.http.get<CarrelliDettaglio[]>(backServerUrlCarrelliDettaglio + "/cerca/dettaglicarrello/" + idTestata);
  }

  eliminaCarrelloDettaglio(id:number){
    return this.http.delete<ApiMsg>(backServerUrlCarrelliDettaglio + "/elimina/id/" + id);
  }
}
