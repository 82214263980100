import { Component, OnInit } from '@angular/core';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { ArticoliDataService } from 'src/app/services/data/articoli-data.service';

@Component({
  selector: 'app-birra-amberale-la-bruciata',
  templateUrl: './birra-amberale-la-bruciata.component.html',
  styleUrls: ['./birra-amberale-la-bruciata.component.css']
})
export class BirraAmberaleLaBruciataComponent implements OnInit {
  articolo: Articoli;  

  constructor(private articoliService: ArticoliDataService) { }

  ngOnInit(): void {
    this.articoliService.cercaArticoloByDicituraUrl('birra-artigianale-lamberale-33cl').subscribe(
      response=>{
        this.articolo=response;
       
        
      }
    )
  }

}