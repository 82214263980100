import { FiltroArticoli } from '../../modelli/shared/Classi/Filtri/filtroArticoli';
import { Articoli } from 'src/app/modelli/shared/articoli';
import { ApiMsg } from './../../modelli/shared/apiMsg';
import { backServerUrlArticoli } from './../../modelli/app.contstant';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArticoliDataService {

  constructor(private http:HttpClient) { }

  elencoArticoliCompleto(){
    return this.http.get<Articoli[]>(backServerUrlArticoli + "/lista");
  }

  cercaArticoliConFiltri(filtro:FiltroArticoli){     
    return this.http.post<Articoli[]>(backServerUrlArticoli + "/listafiltrata",filtro)    
  }

  cercaArticoloByCodart(codart:string){
    return this.http.get<Articoli>(backServerUrlArticoli + "/cerca/codice/" + codart)
  }

  cercaArticoloByDicituraUrl(dicituraUrl:string){
    return this.http.get<Articoli>(backServerUrlArticoli + "/cerca/dicituraurl/" + dicituraUrl)
  }

  eliminaArticolo(codart:string){
    return this.http.delete<ApiMsg>(backServerUrlArticoli + "/elimina/" + codart);
  }

  modificaArticolo(articolo:Articoli){
    return this.http.put<ApiMsg>(backServerUrlArticoli + "/modifica",articolo)
  }

  inserisciArticolo(articolo:Articoli){
    return this.http.post<ApiMsg>(backServerUrlArticoli + "/inserisci",articolo)
  }
}
