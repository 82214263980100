import { ArticoliCategorie } from './../../modelli/shared/articoliCategorie';
import { ArticoliCategorieDataService } from './../../services/data/articoli-categorie-data.service';


import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';

import { MatTableDataSource } from '@angular/material/table';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { Router } from '@angular/router';
import { SnackBarClass } from 'src/app/modelli/shared/Classi/Componenti/snackbarClass';
import { SnackbarComponent } from 'src/app/component/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.css']
})
export class CategorieComponent implements OnInit {

  displayedColumns: string[] = ['id', 'livello','titolo', 'descrizione', 'aggiungi','modifica', 'elimina'];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  categorie: ArticoliCategorie[];

  numeroCategorie: number;

  apiMsg: ApiMsg;
  
  livelloVisualizzato: number;

  constructor(private articoliCategorieService: ArticoliCategorieDataService, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    // this.livelloVisualizzato = 1;
    // this.getCategorie();
  }

  getCategorie() {

    this.articoliCategorieService.elencoCompleto().subscribe(
      response => {
        this.categorie = [];
        this.categorie = response;
        this.categorie = this.categorie.filter(a => a.livello == this.livelloVisualizzato);
        this.dataSource = new MatTableDataSource(this.categorie);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.numeroCategorie = this.categorie.length;
      },
      error => {
        this.categorie = [];
        this.dataSource = new MatTableDataSource(this.categorie);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.numeroCategorie = this.categorie.length;
      }
    )


  }

  eliminaCategoria(id: number) {
    this.articoliCategorieService.elimina(id).subscribe(
      response => {
        this.apiMsg = response;
        this.visualizzaSnackBar();
        this.getCategorie();
      },error=>{
        this.apiMsg = error.error;
        this.visualizzaSnackBar();
      }
    )
  }

  apriCategoria(id: number) {
    if (this.livelloVisualizzato > 0) {
      this.router.navigate(['amm/categoria', id]);
    }
  }

  nuovaConIdPadre(id:number){
    if (this.livelloVisualizzato < 3) {
      this.router.navigate(['amm/categoria/0'], { queryParams:{idPadreDdl: id }});
    }
  }

  applicaFiltro(filtro: string) {
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

  selectionChanged(livello) {
    this.livelloVisualizzato = livello.value;
    this.getCategorie();

  }

  visualizzaSnackBar(){
    let snackBarData: SnackBarClass = new SnackBarClass(this.apiMsg.messaggio, false)
    let snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, { data: snackBarData, duration: 2000 });
  }
}
