import { SnackbarComponent } from './../../component/snackbar/snackbar.component';
import { DialogComponent } from './../../component/dialog/dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-prova',
  templateUrl: './prova.component.html',
  styleUrls: ['./prova.component.css']
})
export class ProvaComponent implements OnInit {

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  openDialog() {
    //Creo una variabile osservabile del dialog
    let dialogRef = this.dialog.open(DialogComponent, {data: { nome: "Domenico" }});
 
  //Qui eseguo un azione quando viene chiuso il dialog
  dialogRef.afterClosed().subscribe(
    response=> {
      
        
      }
    )
  }

openSnackbar(messaggio: string, azione: string){
  //Creo una variabile con la funzione open della snackbar, questa variabile è un osservabile, quindi posso eseguire successivamente qualcosa su di esso
  //Viene impostata anche una durata di apparizione di 2000ms
  //La snackbar rimane in sovraimpressione anche quando cambio pagina!
  let snackBarRef = this.snackBar.open(messaggio, azione, { duration: 2000 });

  //Qui eseguo un azione quando la snackbar scompare (esempio come sopra dopo 2 secondi)
  snackBarRef.afterDismissed().subscribe(
    response => {
      

    }
  )

  //Qui eseguo un azione quando è stato cliccato il bottone chiudi della snackbar
  snackBarRef.onAction().subscribe(
    response => {
      

    }
  )
}
//Questa visualizza una snackbar creata in un componente custom, si può pensare di creare un servizio conuna customsnackbar observeble che può essere lavorata da tutta l'app
openCustomSnackbar(){
  this.snackBar.openFromComponent(SnackbarComponent, { duration: 2000 })
}
  

}
