<section class="container mb-5 pb-5">
  <p class="lead mb-4 mt-4">Riepilogo carrello</p>
  <div class="bg-warning p-5 text-uppercase text-white text-center lead h3" *ngIf="articoloSoloRitiroPresente">
    <mat-icon class="text-white mt-2"> store_mall_directory</mat-icon><br>
   <b>Nel carrello sono inseriti alcuni prodotti disponibili SOLO per il RITIRO IN NEGOZIO. Questo vuol dire che non sarà disonibile la spedizione per l'intero carrello.</b>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z20 text-center">
    <ng-container matColumnDef="titolo" class=" p-2">
      <th *matHeaderCellDef class="p-2 col-3 text-center"> ART. </th>
      <td *matCellDef="let row" class="p-2 col-3"><mat-icon  *ngIf="row.articolo.soloRitiro"  class="text-warning m-2">store_mall_directory</mat-icon> {{row.articolo.titolo}} </td>
    </ng-container>
    <ng-container matColumnDef="prezzo" class=" p-2">
      <th *matHeaderCellDef class="p-2 col-2 text-center"> € </th>
      <td *matCellDef="let row" class="p-2 col-2"> {{row.articolo.prezzo | currency: 'EUR':'symbol'}} </td>
    </ng-container>
    <ng-container matColumnDef="qta" class=" p-2">
      <th *matHeaderCellDef class="p-2 col-3 text-center"> QTA </th>
      <td *matCellDef="let row" class="p-2 col-3 ">
        <span class="input-group-btn m-3">
          <button type="button" class="bg-white border" (click)="variaQuantita(row.id,row.qta-1)">
            <mat-icon color="text-success">remove</mat-icon>
          </button>
        </span>
        {{row.qta}}
        <span class="input-group-btn m-3">
          <button type="button" class=" bg-white border" (click)="variaQuantita(row.id,row.qta+1)">
            <mat-icon color="text-success">add</mat-icon>
          </button>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="totale" class=" p-2">
      <th *matHeaderCellDef class="p-2 col-2 text-center"> TOT. </th>
      <td *matCellDef="let row" class="p-2 col-2"> {{row.articolo.prezzo * row.qta | currency: 'EUR':'symbol' }} </td>
    </ng-container>
    <ng-container matColumnDef="elimina" class=" p-2">
      <th *matHeaderCellDef class="p-2 col-1 text-center"></th>
      <td *matCellDef="let row" class="p-2 col-1"><i class="material-icons puntatore-mano" (click)="eliminaRiga(row.id)"
          style="color: red;">delete</i></td>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </table>
  <div class="alert alert-primary text-center" role="alert">
      Totale carrello: <b>{{ totaleCarrello | currency: 'EUR':'symbol' }}</b>
  </div>
  <div class="alert alert-danger text-center" role="alert" *ngIf="visualizzaMessaggioOrdineMinimo">
    L'importo minimo di evasione ordine è di {{ appImpostazioni.importoOrdineMinimo | currency: 'EUR':'symbol'  }}
</div>
  <!-- {{ dettagli?.length }} -->
  <button *ngIf="dettagli?.length" mat-flat-button color="primary" class="pull-right" (click)="vaiAlCheckout()">
    Vai al checkout <i class="fa fa-arrow-right" aria-hidden="true"></i>
  </button>
  <button  mat-flat-button class="pull-left viola text-white" [routerLink]="'/spesa-online'" >
    <i class="fa fa-arrow-left" ></i> Torna alla spesa 
  </button>
</section>