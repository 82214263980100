import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

//Questo componente di funzioni viene usato per qualsiasi operazione nella localstorage che non riguarda l'utente, presente solo una funione di lettura
export class FunzioniLayout {

    constructor() { }

    gotoTopPage() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
}