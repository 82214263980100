<section class="container">
  <div class="text-center mt-5">
    <h1 class="titoli-blog text-uppercase text-success my-5">Blog e News</h1>
    <h3 class=" text-success sottotitoli-blog mb-5">Tutte le novità e gli approfondimenti dal Molino Lucconi</h3>
  </div>
  <div class="row m-0">
    <div class="col-lg-4 text-center mb-2">
      <div class="card">
        <div class="col-lg-12">
          <img src="/assets/img/blog/come-fare-orto-in-casa-semenze-online-molino-lucconi-2.png" class="card-img-top img-fluid"
            alt="birra lamberale la bruciata">
        </div>
        <div class="card-body">
          <h3 class="card-title text-uppercase font-weight-bold text-dark-green">Orto in casa!
           </h3>
          <p class="card-text">In campagna e in città, un ritorno alle origini sempre maggiore.</p>
          <a [routerLink]="'/orto-in-casa'" class="btn btn-success m-3">Leggi di più </a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 text-center mb-2">
      <div class="card">
        <div class="col-lg-12">
          <img src="/assets/img/blog/consigli-orto-mese-marzo-1.png" class="card-img-top img-fluid"
            alt="birra lamberale la bruciata">
        </div>
        <div class="card-body">
          <h3 class="card-title text-uppercase font-weight-bold text-dark-green">Marzo in Orto! | Consigli e accorgimenti
           </h3>
          <p class="card-text">Marzo è un mese che ci permette di aumentare il tempo dedicato al nostro orto!</p>
          <a [routerLink]="'/marzo-in-orto'" class="btn btn-success m-3">Leggi di più </a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 text-center mb-2">
      <div class="card">
        <div class="col-lg-12">
          <img src="/assets/img/blog/birra-lamberale-la-bruciata-2.jpg" class="card-img-top img-fluid w-75"
            alt="birra lamberale la bruciata">
        </div>
        <div class="card-body">
          <h3 class="card-title text-uppercase font-weight-bold text-dark-green">Birra "Lamberale" La Bruciata | Molino
            Lucconi</h3>
          <p class="card-text">La nuova birra artigianale del sud di Milano: "Lamberale". dedicata al fiume Lambro.</p>
          <a [routerLink]="'/birra-amberale-la-bruciata'" class="btn btn-success m-3">Leggi di più </a>
        </div>
      </div>
    </div>
    <!-- <div class="col-lg-4 text-center">
      <div class="card">
        <div class="col-lg-12">
          <img src="/assets/img/blog/molino-lucconi-miele-0050.JPG" class="card-img-top img-fluid"
            alt="miele molino lucconi">
        </div>
        <div class="card-body">
          <h3 class="card-title text-uppercase font-weight-bold text-dark-green">Miele | Molino Lucconi</h3>
          <p class="card-text">Scopri le proprietà curative del Miele!</p>
          <a [routerLink]="'/miele'" class="btn btn-success m-3">Leggi di più </a>
        </div>
      </div>
    </div> -->
    <div class="col-lg-4 text-center">
      <div class="card">
        <div class="col-lg-12">
          <img src="/assets/img/blog/molino-lucconi-riso-carnaroli-di-villareggio-001-01.JPG"
            class="card-img-top img-fluid" alt="riso carnaroli villareggio molino lucconi">
        </div>
        <div class="card-body">
          <h3 class="card-title text-uppercase font-weight-bold text-dark-green">Riso Carnaroli Villareggio</h3>
          <p class="card-text">Scopri le proprietà, le caratteristiche e le origini del "Re" dei Risi.</p>
          <a [routerLink]="'/riso-carnaroli-villareggio'" class="btn btn-success m-3">Leggi di più </a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 text-center">
      <div class="card">
        <div class="col-lg-12">
          <img src="/assets/img/blog/molino-lucconi-mangime-colombi-2.png" class="card-img-top img-fluid"
            alt="miele molino lucconi">
        </div>
        <div class="card-body">
          <h3 class="card-title text-uppercase font-weight-bold text-dark-green">Mangimi Colombi | Molino Lucconi</h3>
          <p class="card-text">Il moderno sport dei colombi va oltre il semplice allevamento di alcuni colombi. </p>
          <a [routerLink]="'/sport-dei-colombi'" class="btn btn-success m-3">Leggi di più </a>
          <!-- <p> <a [routerLink]="'/riso-carnaroli-villareggio'" class="text-dark-green">Vedi tutti gli articoli</a></p> -->
        </div>
      </div>
    </div>
  </div>
</section>