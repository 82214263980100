import { OrdiniTestata } from 'src/app/modelli/shared/ordiniTestata';
export class OrdiniTestataPagamento{
    constructor(
        public id:number,
        public idOrdineTestata:number,
        public importo:number,
        public divisa:string,
        public codTrans:string,
        public brand:string,
        public mac:string,
        public esito:string,
        public data:string,
        public orario:string,
        public codiceEsito:string,
        public codAut:string,
        public pan:string,
        public scadenzaPan:string,
        public nazionalita:string,
        public languageId:string,
        public tipoTransazione:string,
        public messaggio:string,
        public regione:string,
        public descrizione:string,
        public tipoProdotto:string,
        public nome:string,
        public cognome:string,
        public mail:string,
        public hash:string,
        public infoc:string,
        public infob:string,
        public codiceConvenzione:string,
        public note1:string,
        public note2:string,
        public note3:string,
        public tcontab:string,
        public ordineTestata:OrdiniTestata
    ){}
}