import { OrdiniTestataPagamento } from './../../modelli/shared/ordiniTestataPagamento';
import { backServerUrlPagamento } from './../../modelli/app.contstant';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';

@Injectable({
  providedIn: 'root'
})
export class PagamentoDataService {

  constructor(private http:HttpClient) { }

  PagamentoOrdine(idOrdine:number){
    return this.http.get<ApiMsg>(backServerUrlPagamento + "/ordine/" + idOrdine);
  }

  EsitoPagamentoOrdine(pagamento:OrdiniTestataPagamento){
    return this.http.post<ApiMsg>(backServerUrlPagamento+ "/esitopagamentoordine",pagamento);
  }

  
}
