import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anteprima-carrello',
  templateUrl: './anteprima-carrello.component.html',
  styleUrls: ['./anteprima-carrello.component.css']
})
export class AnteprimaCarrelloComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}