<div class="text-center bg-white row mat-elevation-z20 ">
	<div class="col-lg-4 my-1">
		<a class="d-block text-success  font-size-18 "  title="molino lucconi orari"><i class="fa fa-clock-o mx-2"></i>
		Lun 8.00-12.30 Mar-Sab 8.00-12.30 / 14.00-18.00
		</a>
	</div>
	<div class="col-lg-2 my-1 d-none d-lg-flex">
		<a class="d-block text-success  font-size-18 "  title="molino lucconi orari"><i class="fa fa-phone mx-2"></i>
			0382 95.50.27
		</a>
	</div>
	<div class="col-lg-3 my-1 d-none d-lg-flex">
		<a class="d-block text-success  font-size-18 "  title="molino lucconi orari"><i class="fa fa-map-marker mx-2"></i>
			Via parco visconteo 4, 27010 Zeccone (PV)
		</a>
	</div>
	<div class="col-lg-3 my-1 d-none d-lg-flex">
		<a class="d-block text-success  font-size-18 "  title="molino lucconi orari"><i class="fa fa-envelope-o mx-2"></i>
			info@molinolucconi.it
		</a>
	</div>
</div>
<!-- d-none d-lg-flex -->