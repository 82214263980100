<section>
    <div class="text-center ">
        <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let oggetto of oggetti">
                <ng-template carouselSlide>
                    <img src="{{ oggetto }}">
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>