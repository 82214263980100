<mat-card class="mat-elevation-z20">
    <mat-card-title class="text-center">
        <label *ngIf="tipoIndirizzo=='F'">Nuovo Indirizzo di fatturazione</label>
        <label *ngIf="tipoIndirizzo=='S'">Nuovo Indirizzo di spedizione</label>        
        <mat-form-field appearance="fill" *ngIf="tipoIndirizzo=='F'">
            <mat-label>Inserisci dati da indirizzo di spedizione</mat-label>
            <mat-select>
                <mat-option [value]=0> </mat-option>
                <mat-option *ngFor="let indirizzo of indirizziSpedizionePerFatturazione" [value]="indirizzo" (click)="inserisciDaIndirizzoSpedizione(indirizzo)">
                    {{indirizzo.nominativo}} - {{ indirizzo.indirizzo }} {{ indirizzo.CAP }} {{ indirizzo.comune }} {{ indirizzo.provincia }} {{ indirizzo.stato }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-title>
    <mat-card-content>
        <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salvaIndirizzo(f)">
            <p>
                <mat-form-field>
                    <mat-label>Nominativo</mat-label>
                    <input matInput id="nominativo" formControlName="nominativo">
                    <mat-hint align="end">{{nominativo?.value?.length}} / 100</mat-hint>
                    <mat-hint *ngIf="nominativo.touched && nominativo.errors?.minlength" style="color: red;">Il nominativo
                        deve essere minimo di 2 caratteri</mat-hint>
                    <mat-hint *ngIf="nominativo.touched && nominativo.errors?.maxlenght" style="color: red;">Il nominativo
                        deve essere massimo di 100 caratteri.</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>Indirizzo</mat-label>
                    <input matInput id="indirizzo" formControlName="indirizzo">
                    <mat-hint align="end">{{indirizzo?.value?.length}} / 100</mat-hint>
                    <mat-hint *ngIf="indirizzo.touched && indirizzo.errors?.minlength" style="color: red;">L'indirizzo
                        deve essere minimo di 2 caratteri</mat-hint>
                    <mat-hint *ngIf="indirizzo.touched && indirizzo.errors?.maxlenght" style="color: red;">L'indirizzo
                        deve essere massimo di 100 caratteri.</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>CAP</mat-label>
                    <input matInput id="cap" formControlName="cap">
                    <mat-hint align="end">{{cap?.value?.length}} / 5</mat-hint>
                    <mat-hint *ngIf="cap.touched && cap.errors?.minlength" style="color: red;">Il campo deve essere
                        minimo di 5 caratteri</mat-hint>
                    <mat-hint *ngIf="cap.touched && cap.errors?.maxlenght" style="color: red;">Il campo deve essere
                        massimo di 5 caratteri.</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>Comune</mat-label>
                    <input matInput id="comune" formControlName="comune">
                    <mat-hint align="end">{{comune?.value?.length}} / 100</mat-hint>
                    <mat-hint *ngIf="comune.touched && comune.errors?.minlength" style="color: red;">Il campo deve
                        essere minimo di 2 caratteri</mat-hint>
                    <mat-hint *ngIf="comune.touched && comune.errors?.maxlenght" style="color: red;">Il campo deve
                        essere massimo di 100 caratteri.</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>Provincia</mat-label>
                    <input matInput id="provincia" formControlName="provincia">
                    <mat-hint align="end">{{provincia?.value?.length}} / 2</mat-hint>
                    <mat-hint *ngIf="provincia.touched && provincia.errors?.minlength" style="color: red;">Il campo deve
                        essere minimo di 2 caratteri</mat-hint>
                    <mat-hint *ngIf="provincia.touched && provincia.errors?.maxlenght" style="color: red;">Il campo deve
                        essere massimo di 2 caratteri.</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>Stato</mat-label>
                    <input matInput id="stato" formControlName="stato">
                    <mat-hint align="end">{{stato?.value?.length}} / 50</mat-hint>
                    <mat-hint *ngIf="stato.touched && stato.errors?.minlength" style="color: red;">Il campo deve essere
                        minimo di 3 caratteri</mat-hint>
                    <mat-hint *ngIf="stato.touched && stato.errors?.maxlenght" style="color: red;">Il campo deve essere
                        massimo di 50 caratteri.</mat-hint>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>Telefono</mat-label>
                    <input matInput id="telefono" formControlName="telefono">
                    <mat-hint align="end">{{telefono?.value?.length}} / 50</mat-hint>
                    <mat-hint *ngIf="telefono.touched && telefono.errors?.required" style="color: red;">Il campo è
                        obbligatorio</mat-hint>
                    <mat-hint *ngIf="telefono.touched && telefono.errors?.maxlenght" style="color: red;">Il campo deve
                        essere massimo di 50 caratteri.</mat-hint>
                </mat-form-field>
            </p>

            <p *ngIf="tipoIndirizzo=='F'">
                <mat-form-field>
                    <mat-label>Partita iva</mat-label>
                    <input matInput id="fatturaPIVA" formControlName="fatturaPIVA">
                    <mat-hint align="end">{{fatturaPIVA?.value?.length}} / 11</mat-hint>
                    <mat-hint *ngIf="fatturaPIVA.touched && fatturaPIVA.errors?.maxlenght" style="color: red;">Il campo
                        deve essere massimo di 11 caratteri.</mat-hint>
                </mat-form-field>
            </p>
            <p *ngIf="tipoIndirizzo=='F'">
                <mat-form-field>
                    <mat-label>Codice fiscale</mat-label>
                    <input matInput id="fatturaCodFisc" formControlName="fatturaCodFisc">
                    <mat-hint align="end">{{fatturaCodFisc?.value?.length}} / 16</mat-hint>
                    <mat-hint *ngIf="fatturaCodFisc.touched && fatturaCodFisc.errors?.maxlenght" style="color: red;">Il
                        campo deve essere massimo di 16 caratteri.</mat-hint>
                </mat-form-field>
            </p>
            <p *ngIf="tipoIndirizzo=='F'">
                <mat-form-field>
                    <mat-label>Codice destinatario</mat-label>
                    <input matInput id="fatturaCodiceDestinatario" formControlName="fatturaCodiceDestinatario">
                    <mat-hint align="end">{{fatturaCodiceDestinatario?.value?.length}} / 7</mat-hint>
                    <mat-hint *ngIf="fatturaCodiceDestinatario.touched && fatturaCodiceDestinatario.errors?.maxlenght"
                        style="color: red;">Il campo deve essere massimo di 7 caratteri.</mat-hint>
                </mat-form-field>
            </p>
            <p *ngIf="tipoIndirizzo=='F'">
                <mat-form-field>
                    <mat-label>Pec</mat-label>
                    <input matInput id="fatturaPec" formControlName="fatturaPec">
                    <mat-hint align="end">{{fatturaPec?.value?.length}} / 7</mat-hint>
                    <mat-hint *ngIf="fatturaPec.touched && fatturaPec.errors?.email" style="color: red;">Inserire una
                        mail corretta</mat-hint>
                </mat-form-field>
            </p>
            <p *ngIf="tipoIndirizzo=='S'">
                <mat-form-field>
                    <mat-label>Ulteriori informazioni</mat-label>
                    <input matInput id="ulterioriInformazioni" formControlName="ulterioriInformazioni">
                    <mat-hint align="end">{{ulterioriInformazioni?.value?.length}} / 200</mat-hint>
                    <mat-hint *ngIf="ulterioriInformazioni.touched && ulterioriInformazioni.errors?.maxlenght"
                        style="color: red;">Il campo deve essere massimo di 200 caratteri.</mat-hint>
                </mat-form-field>
            </p>


            <p>
                <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit">
                    <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
                </button>
                <button mat-flat-button color="warn" id=btnChiudi type="submit" class="pull-right" (click)="annulla()">
                    <i class="fa fa-times" aria-hidden="true"></i> Chiudi
                </button>
            </p>
        </form>
    </mat-card-content>
</mat-card>