
    <mat-card class="mat-elevation-z20">
        <mat-card-title class="text-center">
            <label *ngIf="id==0">Nuovo fornitore</label>
            <label *ngIf="id>0">Fornitore: {{ fornitore.ragioneSociale }}</label>
        </mat-card-title>
        <mat-card-content>
            <form *ngIf="f" [formGroup]="f" (ngSubmit)="f.valid && salvaFornitore(f)">
                <p>
                    <mat-form-field>
                        <mat-label>Ragione sociale</mat-label>
                        <input matInput  id="ragioneSociale" formControlName="ragioneSociale">
                        <mat-hint align="end">{{ragioneSociale?.value?.length}} / 200</mat-hint>
                        <mat-hint *ngIf="ragioneSociale.touched && ragioneSociale.errors?.required" style="color: red;">La ragione sociale del fornitore è obbligatoria</mat-hint>                        
                        <mat-hint *ngIf="ragioneSociale.touched && ragioneSociale.errors?.maxlenght" style="color: red;">La ragione sociale del fornitore deve essere massimo di 200 caratteri.</mat-hint>
                    </mat-form-field>
                </p>
                <p>
                    <button mat-flat-button color="primary" id=btnSalva [disabled]="!f.valid" type="submit">
                        <i class="fa fa-floppy-o" aria-hidden="true"></i> Salva
                    </button>
                    <button mat-flat-button color="warn" id=btnChiudi type="submit" class="pull-right"
                        (click)="annulla()">
                        <i class="fa fa-times" aria-hidden="true"></i> Chiudi
                    </button>
                </p>                
            </form>
        </mat-card-content>
    </mat-card>

