import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { authServerUrlAccount } from 'src/app/modelli/app.contstant';
import { map } from 'rxjs/operators'

export class AuthData {
  constructor(
    public codice: string,
    public messaggio: string
  ) { }

}

@Injectable({
  providedIn: 'root'
})
export class AuthJWTService {

  constructor(private http: HttpClient) { }

  autenticaService(Username: string, Password: string) {
    return this.http.post<any>
      (authServerUrlAccount + "/auth",
        { Username, Password }).pipe( //Quello dopo il .pipe si attua quando l'autenticazione ha avuto successo
          map(
            data => {
              localStorage.setItem("Utente", Username)
              localStorage.setItem("AuthToken", "Bearer " + data.token);              
              const helper = new JwtHelperService();
              const decodedToken = helper.decodeToken(data.token);
              let nome:string=decodedToken['unique_name']
              // let items: any = decodedToken['role'];
              localStorage.setItem("Nome",nome);
              return data;
            }
          )
        );
  }

  loggedUser(){
    // Ritorna il nome utente loggato
    let utente = localStorage.getItem("Utente");
    return (utente != null) ? utente : "";
  }

  //Ritorna il nome dell'utente loggato
  loggedNomeUsers(){
    let utente = localStorage.getItem("Nome");
    return (utente != null) ? utente : "";
  }

  getAuthToken(){
    if (this.loggedUser){
      return localStorage.getItem("AuthToken");
    }
    else{
      return "";
    }

  }

  isLogged(){
    //Se il token è scaduto elimino la localstorage riferita all'utente
     let token = this.getAuthToken();
     const helper = new JwtHelperService();     
     
    if (helper.isTokenExpired(token)) {
      this.logout();
    }
    // Verifica se l'utente è loggato
    let utente = localStorage.getItem("Utente");
    return (utente != null) ? true : false;
  }

  logout(){
    localStorage.removeItem("Utente");
    localStorage.removeItem("AuthToken");
    localStorage.removeItem("Nome")
  }

  get CurrentRole(){
    //Recupero il token
    let token: string  = localStorage.getItem("AuthToken");
    //Se non c'è ritorno null
    if (!token) return null;
    
    //Verifico se è un ADMIN    
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token); //Decodifico il token per ricavarne delle informazioni
    let items: any = decodedToken['role']; //Inserisco nella variabile items il valore che otteniamo dal gruppo dei ruoli
    let ruoli: string[] = new Array();
    if (!Array.isArray(items)) {       
      ruoli.push(items);
    }
    else{ 
      ruoli=items;
    }
    let admin: boolean=ruoli.includes("ADMIN");

    return admin;       
  }  

}
