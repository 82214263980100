import { ArticoliCaratteristicheDataService } from './../../services/data/articoli-caratteristiche-data.service';
import { ArticoliCaratteristiche } from './../../modelli/shared/articoliCaratteristiche';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiMsg } from 'src/app/modelli/shared/apiMsg';
import { Router } from '@angular/router';

@Component({
  selector: 'app-articoli-caratteristiche',
  templateUrl: './articoli-caratteristiche.component.html',
  styleUrls: ['./articoli-caratteristiche.component.css']
})
export class ArticoliCaratteristicheComponent implements OnInit {

  displayedColumns: string[] = ['id','titolo','modifica','elimina'];  
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  caratteristiche:ArticoliCaratteristiche[];
  numeroCaratteristiche:number;
  apiMsg:ApiMsg;
  messaggio:string;
  
  constructor(private caratteristicheService:ArticoliCaratteristicheDataService, private router:Router) { }

  ngOnInit(): void {
    this.getCaratteristiche();
  }

  getCaratteristiche(){
    this.caratteristicheService.elencoCaratteristiche().subscribe(
      response=>{
        this.caratteristiche=[];
        this.caratteristiche=response;
        this.dataSource = new MatTableDataSource(this.caratteristiche);      
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator; 
        this.numeroCaratteristiche=this.caratteristiche.length;
      }
    )
  }

  eliminaCaratteristica(id:number){
    this.caratteristicheService.eliminaCaratteristica(id).subscribe(
      response=>{
        this.apiMsg=response;
        this.messaggio=this.apiMsg.messaggio;
        this.getCaratteristiche();
      }
    )
  }

  apriCaratteristica(id:number){
    this.router.navigate(['amm/caratteristica',id]);
  }

  applicaFiltro(filtro:string){
    this.dataSource.filter= filtro.trim().toLowerCase();
  }

}
