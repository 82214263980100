<section>
    <div class="container" >
        <div class="col-lg-12 bg-light text-center mt-5 ">
            <mat-icon class="font-alto-icone bold mt-5">gavel</mat-icon>
            <p class="text-success font-alto pt-3 pb-5">
            TERMINI E CONDIZIONI
            </p>
        </div>
        
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">AMBITO DI APPLICAZIONE DELLE CONDIZIONI DI VENDITA</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                <b>A)</b> Tutti gli ordini a Molino Lucconi Stefano effettuati tramite internet, posta, fax o telefono, sono disciplinati dalle presenti Condizioni di Vendita, formulate in conformità alla vigente normativa sui contratti conclusi al di fuori dei locali commerciali. Con la trasmissione di un ordine si dichiara di avere letto, compreso ed accettato le presenti Condizioni di Vendita. <br><br>
                <b>B)</b> Molino Lucconi Stefano si riserva in ogni momento il diritto di modificare le presenti Condizioni di Vendita pubblicandone una nuova versione su questo sito web. Le Condizioni di Vendita applicabili sono quelle vigenti al momento della effettuazione dell'ordine.
            </p>

        </div>

        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">REGISTRAZIONE: CODICE UTENTE E PASSWORD</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                <b>A)</b> Per effettuare gli ordini occorre essere registrati, attenzione che le informazioni obbligatorie da voi fornite siano corrette e complete. <br><br>
                <b>B)</b> In caso di cambiamenti di indirizzo o altre modifiche siete pregati di aggiornare tempestivamente i vostri dati personali<br><br>
                <b>C)</b> Al momento della registrazione su questo sito vi sarà chiesto di inserire una password. Questa password è strettamente personale e confidenziale e non dovrà pertanto essere divulgata a terzi. <br><br>
                <b>D)</b> Voi vi assumente la completa responsabilità per l’utilizzazione della vostra password e per tutti gli ordini trasmessi con la medesima password, anche se a vostra insaputa. <br><br>
                <b>E)</b> Se voi siete a conoscenza o sospettate che qualcun altro conosca o stia utilizzando la vostra password vi chiediamo di informare immediatamente Molino Lucconi Stefano. <br><br>
                <b>F)</b> Al momento della registrazione il sistema vi attribuirà un codice cliente, che unitamente alla password da voi scelta vi permetterà il riconoscimento da parte del sistema e l’accesso alla vostra area personale Molino Lucconi Stefano. <br><br>
                <b>G)</b> Con la registrazione al sistema Molino Lucconi Stefano acconsentite al trattamento dei vostri dati personali in conformità con quanto stabilito dalla legge sulla tutela dei dati personali 675/96 (e successivi aggiornamenti). (vedi informativa sulla privacy) <br><br>
                <b>H)</b> Molino Lucconi Stefano raccoglie ed adopera i vostri dati al solo scopo di migliorare e completare il servizio a voi offerto. Ad esempio per promuovere e fornirvi i nostri prodotti e servizi, per l’invio e la fatturazione dei prodotti e servizi richiesti, per valutare l’efficienza della nostra attività così per definire meglio le nostre strategie di sviluppo, o per comunicare con voi per altri motivi non prevedibili.
            </p>

        </div>
       
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">CANCELLAZIONE ACCOUNT E CHIUSURA DEGLI ACCOUNT UTENTE</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                Gli Utenti registrati possono disattivare i propri account, richiederne la cancellazione oppure interrompere l'utilizzo del Servizio in ogni momento, contattando direttamente il Titolare. Il Titolare, in caso di violazione dei presenti Termini, si riserva il diritto di sospendere o chiudere l'account dell'Utente in ogni momento e senza preavviso. Il Titolare si riserva il diritto di sospendere o cancellare l’account dell’Utente in qualsiasi momento e senza preavviso se ritiene che:<br><br>
                <b>A)</b> l’Utente abbia violato il Contratto; e/o<br><br>
                <b>B)</b> l’accesso o l’uso del Servizio potrebbero causare danni al Titolare, ad altri Utenti o a terze parti; e/o<br><br>
                <b>C)</b> l’uso di Molino Lucconi Stefano da parte dell’Utente possa risultare in una violazione della legge o dei regolamenti applicabili; e/o<br><br>
                <b>D)</b> in caso di investigazioni condotte in esito ad azioni legali o per coinvolgimento della pubblica autorità; e/o<br><br>
                <b>E)</b> l’account è ritenuto dal Titolare, a sua esclusiva discrezionalità e per qualsivoglia motivo, inappropriato o offensivo o in violazione del Contratto o non in linea con gli standard del Titolare<br><br>
               
            </p>

        </div>
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">CONTENUTI DISPONIBILI SU MOLINO LUCCONI STEFANO</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                I contenuti disponibili su Molino Lucconi Stefano sono protetti dalla legge sul diritto d’autore e dalle altre leggi e trattati internazionali posti a tutela dei diritti di proprietà intellettuale e, ove non diversamente specificato, il loro utilizzo è consentito agli Utenti esclusivamente nei limiti specificati nella presente clausola.<br> Il Titolare concede all’Utente, per tutta la durata del Contratto, una licenza personale, non trasferibile e non esclusiva, ad esclusivi fini personali e mai commerciali e limitatamente al dispositivo in uso all'Utente per l'utilizzo di tali contenuti.<br><br>
                Pertanto, è fatto espresso divieto all’Utente di copiare e/o scaricare e/o condividere (se non nei limiti di seguito illustrati), modificare, pubblicare, trasmettere, vendere, sublicenziare, elaborare, trasferire/cedere a terzi o creare lavori derivati in qualsiasi modo dai contenuti, anche di terzi, disponibili su Molino Lucconi Stefano, né consentire che terzi lo facciano tramite l’Utente o il suo dispositivo, anche a sua insaputa.<br> Ove espressamente indicato su Molino Lucconi Stefano, l'Utente, per mero uso personale, può essere autorizzato a scaricare e/o copiare e/o condividere alcuni contenuti resi disponibili su Molino Lucconi Stefano, a condizione che riporti fedelmente tutte le indicazioni di copyright e le altre indicazioni fornite dal Titolare.
            </p>

        </div>

        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">UTILIZZO NON CONSENTITO</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                Il Servizio dovrà essere utilizzato secondo quanto stabilito nei Termini. Gli Utenti non possono:<br><br>

                <b>A)</b> effettuare reverse engineering, decompilare, disassemblare, modificare o creare lavori derivati basati su Molino Lucconi Stefano o su qualunque porzione di essa;<br><br>
                <b>B)</b> aggirare i sistemi informatici usati da Molino Lucconi Stefano o dai suoi licenziatari per proteggere il contenuto accessibile tramite di essa;<br><br>
                <b>C)</b> copiare, conservare, modificare, cambiare, preparare lavori derivati o alterare in qualunque modo qualunque dei contenuti forniti da Molino Lucconi Stefano; <br><br>
                <b>D)</b> utilizzare qualunque robot, spider, applicazione di ricerca e/o di reperimento di siti, ovvero qualunque altro dispositivo, processo o mezzo automatico per accedere, recuperare, effettuare scraping o indicizzare qualunque porzione di Molino Lucconi Stefano o dei suoi contenuti; *affittare, licenziare o sub-licenziare Molino Lucconi Stefano;<br><br>
                <b>E)</b> diffamare, offendere, molestare, mettere in atto pratiche minatorie, minacciare o in altro modo violare i diritti di altri;<br><br>
                <b>F)</b> appropriarsi illecitamente dell’account in uso presso altro Utente;<br><br>
                <b>G)</b> registrarsi o utilizzare il Servizio al fine di approcciare gli Utenti per promuovere, vendere o pubblicizzare in qualsiasi modo prodotti o servizi di qualsivoglia tipo attraverso Molino Lucconi Stefano;<br><br>
                <b>H)</b> aggirare i sistemi informatici ed i regolamenti del Titolare allo scopo di utilizzare la funzione di “invita un amico”, o altre funzioni similari, per fini fraudolenti (e.g. ricevere buoni sconto o accumulare punti);<br><br>
                <b>I)</b> utilizzare Molino Lucconi Stefano in qualunque altra modalità impropria tale da violare i Termini.
            </p>

        </div>
        
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">INTERRUZIONE DEL SERVIZIO</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                Per garantire agli Utenti la migliore fruizione possibile del Servizio, il Titolare si riserva il diritto di interrompere il Servizio per necessità di manutenzione o aggiornamento del sistema, informando gli Utenti attraverso aggiornamenti costanti in merito su Molino Lucconi Stefano.
            </p>

        </div>
        <div class="text-center mt-5">
            <p class="text-success text-uppercase font-medio pt-3 pb-3">RIVENDITA DEL SERVIZIO</p>
        </div>
        <div>
            <p class="lead text-left interlinea2 ">
                Gli Utenti non sono autorizzati a riprodurre, duplicare, copiare, vendere, rivendere o sfruttare qualunque porzione di Molino Lucconi Stefano e dei suoi Servizi senza il previo permesso scritto da parte del Titolare, garantito direttamente o tramite uno specifico programma di rivendita.
            </p>

        </div>

    </div>    
 
</section>