import { FunzioniLocalStorage } from './../../modelli/shared/Funzioni/funzioniLocalStorage';
import { CarrellitestataDataService } from './../data/carrellitestata-data.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppCarrelloImportoService {

  private subject = new Subject<any>();

  constructor(private carrelloTService: CarrellitestataDataService, private funzioniLS: FunzioniLocalStorage) { }

  impostaImporto() {
    //Con next dando un altro valore a subject è come se immettessi il valore successivo
    if (this.funzioniLS.getIdCart() != null) {
      this.carrelloTService.totaleCarrello(this.funzioniLS.getIdCart()).subscribe(
        response => {          
          this.subject.next();
          this.subject.next({ text: response });
        },error=>{
          this.funzioniLS.removeIdCart();
        }
      )
    }else {
      this.subject.next({text:0});
    }
  }

  pulisciImporto() {
    //con next imposto il valore successivo di subject a niente
    this.subject.next();
    this.subject.next({text : 0});
  }

  rilevaImporto(): Observable<any> {
    //Ritorno l'ultimo valore impostato a subject    
    return this.subject.asObservable();
  }
}
