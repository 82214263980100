<div class="">
    <div class="row">
        <div class="col-lg-12">
            <form *ngIf="fFiltro" [formGroup]="fFiltro" (ngSubmit)="filtra()">
                <mat-form-field appearance="fill" style="width: 300px;">
                    <mat-label>Inserisci un range di date</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" #dateRangeStart formControlName="dataDal">
                        <input matEndDate placeholder="End date" #dateRangeEnd formControlName="dataAl">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 300px;">
                    <mat-label>Causale</mat-label>
                    <mat-select formControlName="causale">
                        <mat-option value="">Tutti</mat-option>
                        <mat-option [value]="causale.codice" *ngFor="let causale of fCausali">
                            {{causale.titolo}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 300px;">
                    <mat-label>Articolo</mat-label>
                    <mat-select name="codArt" formControlName="codArt" #sArticoli id="codArt">
                        <mat-select-filter [placeholder]="'Cerca'" *ngIf="sArticoli.focused"
                            [displayMember]="'titolo'" [array]="articoli"
                            (filteredReturn)="fArticoli =$event"></mat-select-filter>
                        <mat-option value="">Nessun articolo selezionato</mat-option>
                        <mat-option [value]="articolo.codArt" *ngFor="let articolo of fArticoli">
                            {{articolo.codArt}} - {{articolo.titolo}}</mat-option>                                    
                    </mat-select>                    
                </mat-form-field>
                <button class="btn-success" type="submit">Filtra</button>
            </form>
            <button mat-flat-button color="primary" (click)="apriMovimento(null)">Nuovo</button>
        </div>        
        <div class="col-lg-12">
            <ng-template #vc></ng-template>
        </div>       
        <div class="col-lg-12">
            <mat-form-field>
                <input (keyup)="applicaFiltro($event.target.value)" matInput placeholder="Filtro">
            </mat-form-field>
            <div class="mat-elevation-z20">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="data">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> DATA </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.data | date: 'dd/MM/yyyy'}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="codArt">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> CODART </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.codArt}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="articolo.titolo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> TITOLO </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.articolo.titolo}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="causale">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> CAUSALE </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.causale}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="qta">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> QTA </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.qta}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="visualizza">
                        <mat-header-cell *matHeaderCellDef>VISUALIZZA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="visualizza(row)"
                                style="color: green;">visibility</i></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="modifica">
                        <mat-header-cell *matHeaderCellDef>MODIFICA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="apriMovimento(row)"
                                style="color: green;">build</i></mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="elimina">
                        <mat-header-cell *matHeaderCellDef>ELIMINA</mat-header-cell>
                        <mat-cell *matCellDef="let row"><i class="material-icons" (click)="eliminaMovimento(row)"
                                style="color: red;">delete</i></mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">Nessun dato da visualizzare con il filtro applicato</td>
                    </tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </div>
    </div>
</div>