import { UtentiDataService } from './../../services/data/utenti-data.service';
import { Component, OnInit } from '@angular/core';
import { Utenti } from 'src/app/modelli/shared/utenti';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  numeroUtenti:number;
  utenti:Utenti[];

  constructor(private utentiService:UtentiDataService) { }

  ngOnInit(): void {
    this.utentiService.elencoUtenti().subscribe(
      response=>{
        this.utenti=response;
        this.numeroUtenti=this.utenti.length;
      }
    )
  }

}
