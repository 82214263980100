import { backServerUrlMetodiPagamento } from './../../modelli/app.contstant';
import { MetodoPagamento } from './../../modelli/shared/metodoPagamento';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MetodiPagamentoDataService {

  constructor(private http:HttpClient) { }

  lista(){
    return this.http.get<MetodoPagamento[]>(backServerUrlMetodiPagamento + "/lista")
  }
}
