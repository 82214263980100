import { backServerUrlCarrelliTestata } from './../../modelli/app.contstant';
import { ApiMsg } from './../../modelli/shared/apiMsg';
import { CarrelliTestata } from './../../modelli/shared/carrelliTestata';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarrellitestataDataService {

  constructor(private http:HttpClient) { }

  cercaCarrelloTestata(id:string){
    return this.http.get<CarrelliTestata>(backServerUrlCarrelliTestata + "/cerca/id/" + id);
  }

  inserisciCarrelloTestata(carrello:CarrelliTestata){
    return this.http.post<CarrelliTestata>(backServerUrlCarrelliTestata + "/inserisci",carrello);
  }

  modificaCarrelloTestata(carrello:CarrelliTestata){
    return this.http.put<ApiMsg>(backServerUrlCarrelliTestata + "/modifica/",carrello);
  }
  
  totaleCarrello(id:string){
    return this.http.get<number>(backServerUrlCarrelliTestata + "/importototale/id/" + id)
  }

  controlloQuantitativiCarrello(id:string){
    return this.http.get<boolean>(backServerUrlCarrelliTestata + "/controlloquantitativi/id/" + id)
  }
}
